import { Redirect, useParams } from "react-router-dom";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";

const AllThreadsTabViewWithPresetSearch = () => {
  const { setInitialSearchQuery } = useInboxContext();
  const { presetInboxSearchQuery } = useParams<{ presetInboxSearchQuery: string }>();

  if (presetInboxSearchQuery) setInitialSearchQuery(presetInboxSearchQuery);

  return <Redirect to={MEDICAID_INBOX_ROUTES.allThreads} />;
};

export default AllThreadsTabViewWithPresetSearch;
