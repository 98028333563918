import { Redirect, Route, Switch } from "react-router-dom";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { Permission } from "src/generated/api_types";
import { ROUTES } from "src/MainRouter";
import AuthenticatedRoute from "src/pages/auth/AuthenticatedRoute";
import CaseWorkerRouter, {
  Medicaid_Case_Worker_Dashboard_Routes,
} from "src/pages/medicaidAccount/caseWorkerAccount/CaseWorkerRouter";
import CaseManagerRouter, {
  Medicaid_Case_Manager_Routes,
} from "src/pages/medicaidAccount/consumerAccount/CaseManagerAccount/CaseManagerRouter";
import { ConsumerAccountContextProvider } from "src/pages/medicaidAccount/consumerAccount/ConsumerAccountContext";
import ConsumerRouter, {
  Medicaid_Consumer_Dashboard_Routes,
} from "src/pages/medicaidAccount/consumerAccount/ConsumerRouter";
import { AssistiveJobsDashboard } from "src/pages/medicaidAccount/coordinatorAccount/AssistiveJobsDashboard";
import CoordinatorRouter, {
  Medicaid_Coordinator_Dashboard_Routes,
} from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import FindJobs from "src/pages/medicaidAccount/FindJobs";
import FindProviders from "src/pages/medicaidAccount/FindProviders";
import JobDetails from "src/pages/medicaidAccount/JobDetails";
import AccountPortalMain from "src/pages/medicaidAccount/portal/AccountPortalMain";
import ProviderRouter, {
  Medicaid_Provider_Dashboard_Routes,
} from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import ProviderProfileDetails from "src/pages/medicaidAccount/ProviderProfileDetails";
import ProxyProviderRouter, {
  Medicaid_Proxy_Provider_Routes,
} from "src/pages/medicaidAccount/ProxyProvider/ProxyProviderRouter";
import SavedJobs from "src/pages/medicaidAccount/SavedJobs";
import SavedProviders from "src/pages/medicaidAccount/SavedProviders";
import FindJobsWrapper from "src/pages/medicaidAccount/searchComponents/FindJobsWrapper";
import FindProvidersWrapper from "src/pages/medicaidAccount/searchComponents/FindProvidersWrapper";
import Settings from "src/pages/medicaidAccount/Settings";
import TestimonialConsent from "src/pages/medicaidAccount/TestimonialConsent";
import { SupportAdminContextProvider } from "src/pages/supportAdminAccount/SupportAdminContext";
import InboxRouter, { MEDICAID_INBOX_ROUTES } from "../inbox/InboxRouter";

export const MEDICAID_ACCOUNT_PREFIX = "/homecare/medicaid";
export const MEDICAID_ACCOUNT_ROUTES = {
  root: `${MEDICAID_ACCOUNT_PREFIX}`,
  homepage: `${MEDICAID_ACCOUNT_PREFIX}/portal`,
  jobDashboard: `${MEDICAID_ACCOUNT_PREFIX}/jobDashboard`,
  inbox: `${MEDICAID_ACCOUNT_PREFIX}/inbox`,
  browseJobs: `${MEDICAID_ACCOUNT_PREFIX}/jobs`,
  jobDetails: `${MEDICAID_ACCOUNT_PREFIX}/jobs/:id`,
  browseProviders: `${MEDICAID_ACCOUNT_PREFIX}/providers`,
  providerDetails: `${MEDICAID_ACCOUNT_PREFIX}/providers/:id`,
  accountSettings: `${MEDICAID_ACCOUNT_PREFIX}/profile/settings`,
  testimonialConsent: `${MEDICAID_ACCOUNT_PREFIX}/testimonial-consent`,
  savedProviders: `${MEDICAID_ACCOUNT_PREFIX}/saved/providers`,
  savedJobs: `${MEDICAID_ACCOUNT_PREFIX}/saved/jobs`,
};

const MedicaidAccountRouter = () => {
  const { hasPermission, isAssistiveRoleView } = useUserSession();
  const { hasMedicaidAccess, userSession, isUserType } = useUserSession();
  if (userSession && !hasMedicaidAccess()) return <Redirect to={ROUTES.login} />;
  const { isInEnv } = useConfig();
  const routeVisibileForDevelopmentOnly = isInEnv(EnvName.DEV) || isInEnv(EnvName.LOCAL);

  return (
    <>
      <ConsumerAccountContextProvider>
        <SupportAdminContextProvider
          initialState={
            isUserType(UserType.UserSupportAgent) ? { program: userSession?.funder.id } : { program: "none" }
          }
        >
          <Switch>
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.homepage}>
              <AccountPortalMain />
            </Route>
            {/* TODO: remove routeVisible flag once fully developed and tested*/}
            {isAssistiveRoleView() && routeVisibileForDevelopmentOnly && (
              <Route exact path={MEDICAID_ACCOUNT_ROUTES.jobDashboard}>
                <AssistiveJobsDashboard />
              </Route>
            )}
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.jobDetails}>
              <JobDetails />
            </Route>
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.providerDetails}>
              <ProviderProfileDetails />
            </Route>
            {hasPermission(Permission.MESSAGING_ACCESS) && (
              <Route path={MEDICAID_INBOX_ROUTES.root}>
                <InboxRouter />
              </Route>
            )}
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
              <FindJobsWrapper>
                <FindJobs />
              </FindJobsWrapper>
            </Route>
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.browseProviders}>
              <FindProvidersWrapper>
                <FindProviders />
              </FindProvidersWrapper>
            </Route>
            <AuthenticatedRoute
              path={Medicaid_Consumer_Dashboard_Routes.root}
              component={ConsumerRouter}
              userType={UserType.Consumer}
            />
            <AuthenticatedRoute
              path={Medicaid_Provider_Dashboard_Routes.root}
              component={ProviderRouter}
              userType={UserType.Provider}
            />
            <AuthenticatedRoute
              path={Medicaid_Proxy_Provider_Routes.root}
              component={ProxyProviderRouter}
              userType={UserType.ProxyProvider}
            />
            {hasPermission(Permission.SETTINGS_ACCESS) && (
              <Route path={MEDICAID_ACCOUNT_ROUTES.accountSettings}>
                <Settings />
              </Route>
            )}
            <AuthenticatedRoute
              path={Medicaid_Coordinator_Dashboard_Routes.root}
              component={CoordinatorRouter}
              userType={UserType.MedicaidReferralCoordinator}
            />
            <AuthenticatedRoute
              path={Medicaid_Case_Manager_Routes.root}
              component={CaseManagerRouter}
              userType={UserType.MedicaidCaseManager}
            />
            <AuthenticatedRoute
              path={Medicaid_Case_Worker_Dashboard_Routes.root}
              component={CaseWorkerRouter}
              userType={UserType.CaseWorker}
            />
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.testimonialConsent}>
              <TestimonialConsent />
            </Route>
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.savedProviders}>
              <SavedProviders />
            </Route>
            <Route exact path={MEDICAID_ACCOUNT_ROUTES.savedJobs}>
              <SavedJobs />
            </Route>
            <Redirect to={MEDICAID_ACCOUNT_ROUTES.homepage} />
          </Switch>
        </SupportAdminContextProvider>
      </ConsumerAccountContextProvider>
    </>
  );
};

export default MedicaidAccountRouter;
