import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { NotificationFrequency, ProviderProfileSearchDTO } from "src/generated/api_types";
import { nsCommonToasts } from "src/i18n/Namespaces";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { getMyAddress, providerSearchAndFilterClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";

const FindProvidersWrapper: React.FC = ({ children }) => {
  const {
    setProviderProfileList,
    setProviderIdList,
    providerSearchDTO,
    setProviderSearchDTO,
    setLoadingProviders,
    isProvFilterDirty,
    setIsProvFilterDirty,
    isInitialProvidersSearch,
    setIsInitialProvidersSearch,
    setTotalProviderCount,
    clearProviderFilters,
    setClearProviderFilters,
    providerFltrWkNotifEnabled,
    enableProviderFltrWkNotif,
    setProviderFilterNotificationFrequency,
    setLoadingProviderFilterNotification,
    providersPage,
    totalProviderPages,
    setTotalProviderPages,
    setIsLoadingNextProvidersPage,
    emptyProviderFilterObject,
    setProvidersPage,
    nextProviderPageWasCalled,
    setNextProviderPageWasCalled,
  } = useMedicaidContext();
  const { showSnackbar } = useNotification();
  const { program } = useSupportAdminContext();
  const { isUserType } = useUserSession();
  const [debouncer, setDebouncer] = useState<NodeJS.Timeout>();
  const { t } = useTranslation(nsCommonToasts);
  const PROVIDER_SEARCH_DTO_KEY = "provider-search-dto";

  /**********************************************************************************************************
   ****************** Next block checks defines the initial filter object ***********************************
   *********************************************************************************************************/

  useEffect(() => {
    (async () => {
      if (!providerSearchDTO.address) {
        try {
          // See if 'email me filtered providers' should be checked
          setLoadingProviderFilterNotification(true);
          const filterNotification = await providerSearchAndFilterClient.getEmailFilterNotification();
          enableProviderFltrWkNotif(!!filterNotification.data?.enabled);
          // Default to daily frequency if there is no notification config nor an enabled config
          setProviderFilterNotificationFrequency(
            filterNotification.data?.enabled ? filterNotification.data?.frequency : NotificationFrequency.DAILY,
          );

          // Check on server if there's already provider filters content saved to db. If so, use it
          const res = await providerSearchAndFilterClient.getFilterContent();
          let persistedSearchDTO: ProviderProfileSearchDTO | undefined = res.data;

          // if not, then check if there are already provider filters content saved to localStorage
          if (!persistedSearchDTO) {
            persistedSearchDTO = getLocallyStoredProviderSearchDTO();
          }

          // if not, then use the default, filling the address field with the user's address (fetched from api)
          if (!persistedSearchDTO) {
            persistedSearchDTO = emptyProviderFilterObject;
            const addressRes = await getMyAddress();
            persistedSearchDTO.address = addressRes.data;
          }

          // update filter object
          storeSearchDTO(persistedSearchDTO);
          setProviderSearchDTO(persistedSearchDTO);
        } catch (e) {
          showSnackbar(
            t(
              "error.connecting_to_server",
              "Sorry, there were problems trying to connect to server. Please try again later",
              { ns: nsCommonToasts },
            ),
            "error",
          );
        } finally {
          // flag end of filters load
          // trigger new search with the loaded filters
          setIsProvFilterDirty(true);
          setLoadingProviderFilterNotification(false);
        }
      }
    })();
  }, []);

  const getLocallyStoredProviderSearchDTO = (): ProviderProfileSearchDTO | undefined => {
    const storedDTO = localStorage.getItem(PROVIDER_SEARCH_DTO_KEY);
    if (storedDTO) return JSON.parse(storedDTO);
    return undefined;
  };

  /**********************************************************************************************************
   ***************** Next block resets filters based on clearProviderFilters state ***************************
   *********************************************************************************************************/

  useEffect(() => {
    (async () => {
      if (clearProviderFilters) {
        try {
          const resettedFilters = emptyProviderFilterObject;
          resettedFilters.address = providerSearchDTO.address;
          setProviderSearchDTO(resettedFilters);
          setClearProviderFilters(false);
          setIsProvFilterDirty(true);
        } catch (e) {
          showSnackbar(
            t("error.resetting_filters", "Sorry, there was an issue resetting the filters. Please try again later.", {
              ns: nsCommonToasts,
            }),
            "error",
          );
        }
      }
    })();
  }, [clearProviderFilters]);

  /**********************************************************************************************************
   ************ Next block requests 1st results page when user edits any filter *****************************
   *********************************************************************************************************/

  useEffect(() => {
    try {
      if (providerSearchDTO.address && (isInitialProvidersSearch || isProvFilterDirty)) executeDebouncedSearch();
    } finally {
      setIsProvFilterDirty(false);
      setClearProviderFilters(false);
    }
  }, [isProvFilterDirty]);

  const storeSearchDTO = (dto: ProviderProfileSearchDTO) => {
    const searchDTOString = JSON.stringify(dto);
    localStorage.setItem(PROVIDER_SEARCH_DTO_KEY, searchDTOString);
  };

  // more info: https://stackoverflow.com/questions/24004791/can-someone-explain-the-debounce-function-in-javascript
  function debounceFn(func: Function, delay: number, immediate?: boolean) {
    let timer;
    return (...args: any) => {
      if (debouncer) clearTimeout(debouncer);
      timer = setTimeout(() => {
        timer = null;
        if (!immediate) func(...args);
      }, delay);
      if (immediate && !debouncer) func(...args);
      setDebouncer(timer);
    };
  }

  const executeDebouncedSearch = debounceFn(
    async () => {
      try {
        setLoadingProviders(true);
        const res = await providerSearchAndFilterClient.search(providerSearchDTO, {
          selectedFunderId: isUserType(UserType.UserSupportManager) && program !== "none" ? program : "",
        });

        setProvidersPage(providerSearchDTO.pageNumber);
        setIsInitialProvidersSearch(false);
        storeSearchDTO(providerSearchDTO);
        setProviderProfileList(res.data.content);
        setProviderIdList(res.data.content.map((profile) => profile.entity.provider.id));
        setTotalProviderCount(res.data.totalElements);
        setTotalProviderPages(res.data.totalPages);
        if (providerFltrWkNotifEnabled) await providerSearchAndFilterClient.persistFilter(providerSearchDTO);
      } catch (e) {
        showSnackbar(
          t(
            "error.retrieving_providers_near_you",
            "Sorry, there was an issue retrieving providers near you. Please try again later.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } finally {
        setLoadingProviders(false);
      }
    },
    1000, // the search will be triggered 'delay' ms after the user edits a filter
    isInitialProvidersSearch,
  );

  /**********************************************************************************************************
   ************ Next block requests 2nd results page and above when triggered by infinite scrolling **********
   *********************************************************************************************************/

  useEffect(() => {
    (async () => {
      if (providersPage !== 0 && providersPage < totalProviderPages && nextProviderPageWasCalled) {
        try {
          setIsLoadingNextProvidersPage(true);
          const res = await providerSearchAndFilterClient.search(
            { ...providerSearchDTO, pageNumber: providersPage },
            { selectedFunderId: isUserType(UserType.UserSupportManager) && program !== "none" ? program : "" },
          );
          setProviderProfileList((prev) => [...prev, ...res.data.content]);
          const newIdsToInclude = res.data.content.map((profile) => profile.entity.provider.id);
          setProviderIdList((prev) => [...prev, ...newIdsToInclude]);
          setIsLoadingNextProvidersPage(false);
        } catch (err) {
          showSnackbar(
            t(
              "error.retrieving_providers_near_you",
              "Sorry, there was an issue retrieving providers near you. Please try again later.",
              { ns: nsCommonToasts },
            ),
            "error",
          );
        } finally {
          setNextProviderPageWasCalled(false);
        }
      }
    })();
  }, [providersPage]);
  /*************************************************** ***************************************************/

  return <>{children}</>;
};

export default FindProvidersWrapper;
