import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobPostSearchDTO, NotificationFrequency } from "src/generated/api_types";
import { nsCommonToasts } from "src/i18n/Namespaces";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { getMyAddress, jobSearchAndFilterClient } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";

const FindJobsWrapper: React.FC = ({ children }) => {
  const {
    setJobsList,
    jobSearchDTO,
    setJobSearchDTO,
    setLoadingJobs,
    isJobFilterDirty,
    setIsJobFilterDirty,
    isInitialJobsSearch,
    setIsInitialJobsSearch,
    setTotalJobCount,
    clearJobFilters,
    setClearJobFilters,
    jobFltrWkNotifEnabled,
    enableJobFltrWkNotif,
    setJobFilterNotificationFrequency,
    setLoadingJobFilterNotification,
    jobsPage,
    totalJobPages,
    setTotalJobPages,
    setIsLoadingNextPage,
    emptyJobFilterObject,
    setJobsPage,
    nextJobPageWasCalled,
    setNextJobPageWasCalled,
  } = useMedicaidContext();
  const { showSnackbar } = useNotification();
  const { program } = useSupportAdminContext();
  const { isUserType } = useUserSession();
  const [debouncer, setDebouncer] = useState<NodeJS.Timeout>();
  const JOB_SEARCH_DTO_KEY = "job-search-dto";
  const { t } = useTranslation(nsCommonToasts);

  /**********************************************************************************************************
   ****************** Next block checks defines the initial filter object ***********************************
   *********************************************************************************************************/

  useEffect(() => {
    (async () => {
      if (!jobSearchDTO.address) {
        try {
          // See if 'email me filtered jobs' should be checked
          setLoadingJobFilterNotification(true);
          const filterNotification = await jobSearchAndFilterClient.getEmailFilterNotification();
          enableJobFltrWkNotif(!!filterNotification.data?.enabled);
          // Default to daily frequency if there is no notification config nor an enabled config
          setJobFilterNotificationFrequency(
            filterNotification.data?.enabled ? filterNotification.data?.frequency : NotificationFrequency.DAILY,
          );

          // Check on server if there's already job filters content saved to db. If so, use it
          const res = await jobSearchAndFilterClient.getFilterContent();
          let persistedSearchDTO: JobPostSearchDTO | undefined = res.data;

          // if not, then check if there there's already job filters content saved to localStorage
          if (!persistedSearchDTO) {
            persistedSearchDTO = getLocallyStoredJobSearchDTO();
          }

          // if not, then use the default, filling the address field with the user's address (fetched from api)
          if (!persistedSearchDTO) {
            persistedSearchDTO = emptyJobFilterObject;
            const addressRes = await getMyAddress();
            persistedSearchDTO.address = addressRes.data;
          }

          // update filter object
          storeSearchDTO(persistedSearchDTO);
          setJobSearchDTO(persistedSearchDTO);
        } catch (e) {
          showSnackbar(
            t(
              "error.connecting_to_server",
              "Sorry, there were problems trying to connect to server. Please try again later",
              { ns: nsCommonToasts },
            ),
            "error",
          );
        } finally {
          // flag end of filters load
          // trigger new search with the loaded filters
          setIsJobFilterDirty(true);
          setLoadingJobFilterNotification(false);
        }
      }
    })();
  }, []);

  const getLocallyStoredJobSearchDTO = (): JobPostSearchDTO | undefined => {
    const storedDTO = localStorage.getItem(JOB_SEARCH_DTO_KEY);
    if (storedDTO) return JSON.parse(storedDTO);
    return undefined;
  };

  /**********************************************************************************************************
   ***************** Next block resets filters based on clearJobsFilters state ***************************
   *********************************************************************************************************/

  useEffect(() => {
    (async () => {
      if (clearJobFilters) {
        try {
          const resettedFilters = emptyJobFilterObject;
          resettedFilters.address = jobSearchDTO.address;
          setJobSearchDTO(resettedFilters);
          setClearJobFilters(false);
          setIsJobFilterDirty(true);
        } catch (e) {
          showSnackbar(
            t("error.resetting_filters", "Sorry, there was an issue resetting the filters. Please try again later.", {
              ns: nsCommonToasts,
            }),
            "error",
          );
        }
      }
    })();
  }, [clearJobFilters]);

  /**********************************************************************************************************
   ************ Next block requests 1st results page when user edits any filter *****************************
   *********************************************************************************************************/

  useEffect(() => {
    try {
      if (jobSearchDTO.address && (isInitialJobsSearch || isJobFilterDirty)) executeDebouncedSearch();
    } finally {
      setIsJobFilterDirty(false);
      setClearJobFilters(false);
    }
  }, [isJobFilterDirty]);

  const storeSearchDTO = (dto: JobPostSearchDTO) => {
    const searchDTOString = JSON.stringify(dto);
    localStorage.setItem(JOB_SEARCH_DTO_KEY, searchDTOString);
  };

  // more info: https://stackoverflow.com/questions/24004791/can-someone-explain-the-debounce-function-in-javascript
  function debounceFn(func: Function, delay: number, immediate?: boolean) {
    let timer;
    return (...args: any) => {
      if (debouncer) clearTimeout(debouncer);
      timer = setTimeout(() => {
        timer = null;
        if (!immediate) func(...args);
      }, delay);
      if (immediate && !debouncer) func(...args);
      setDebouncer(timer);
    };
  }

  const executeDebouncedSearch = debounceFn(
    async () => {
      try {
        setLoadingJobs(true);
        const res = await jobSearchAndFilterClient.search(jobSearchDTO, {
          selectedFunderId: isUserType(UserType.UserSupportManager) && program !== "none" ? program : "",
        });

        setJobsPage(jobSearchDTO.pageNumber);
        setIsInitialJobsSearch(false);
        storeSearchDTO(jobSearchDTO);
        setJobsList(res.data.content);
        setTotalJobCount(res.data.totalElements);
        setTotalJobPages(res.data.totalPages);
        if (jobFltrWkNotifEnabled) await jobSearchAndFilterClient.persistFilter(jobSearchDTO);
      } catch (e) {
        showSnackbar(
          t(
            "error.retrieving_jobs_near_you",
            "Sorry, there was an issue retrieving jobs near you. Please try again later.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } finally {
        setLoadingJobs(false);
      }
    },
    1000, // the search will be triggered 'delay' ms after the user edits a filter
    isInitialJobsSearch,
  );

  /**********************************************************************************************************
   ************ Next block requests 2nd results page and above when triggered by infinite scrolling **********
   *********************************************************************************************************/

  useEffect(() => {
    (async () => {
      if (jobsPage !== 0 && jobsPage < totalJobPages && nextJobPageWasCalled) {
        try {
          setIsLoadingNextPage(true);
          const res = await jobSearchAndFilterClient.search(
            { ...jobSearchDTO, pageNumber: jobsPage },
            { selectedFunderId: isUserType(UserType.UserSupportManager) && program !== "none" ? program : "" },
          );
          setJobsList((prev) => [...prev, ...res.data.content]);

          setIsLoadingNextPage(false);
        } catch (err) {
          showSnackbar(
            t(
              "error.retrieving_jobs_near_you",
              "Sorry, there was an issue retrieving jobs near you. Please try again later.",
              { ns: nsCommonToasts },
            ),
            "error",
          );
        } finally {
          setNextJobPageWasCalled(false);
        }
      }
    })();
  }, [jobsPage]);
  /*************************************************** ***************************************************/

  return <>{children}</>;
};

export default FindJobsWrapper;
