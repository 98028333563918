import { alpha, autocompleteClasses, outlinedInputClasses, touchRippleClasses } from "@mui/material";
import { buttonClasses } from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import EuropaBoldTTF from "src/fonts/EuropaBold.ttf";
import EuropaBoldItalicTTF from "src/fonts/EuropaBoldItalic.ttf";
import EuropaRegularTTF from "src/fonts/EuropaRegular.ttf";
import EuropaRegularItalicTTF from "src/fonts/EuropaRegularItalic.ttf";
import RooneyBoldOTF from "src/fonts/Rooney-Bold.otf";
import RooneyBoldItalicOTF from "src/fonts/Rooney-BoldItalic.otf";
import RooneyMediumOTF from "src/fonts/Rooney-Medium.otf";
import RooneyMediumItalicOTF from "src/fonts/Rooney-MediumItalic.otf";
import RooneyRegularOTF from "src/fonts/Rooney-Regular.otf";
import RooneyRegularItalicOTF from "src/fonts/Rooney-RegularItalic.otf";
import { isUsingRussian } from "src/i18n/Languages";
import CivColors from "src/themes/civilization/CivColors";
import colors from "src/themes/civilization/CivColors";

// Returns width in pixels given number of columns (out of 12) on a 1280px-wide screen.
export function width(columns: number): number {
  return columns > 0 ? 105 * columns - 25 : 0;
}

const theme = createTheme({
  typography: {
    htmlFontSize: 20, // This lets MUI know that the font size on the html element (set in index.css) is 20px
    fontFamily: isUsingRussian() ? "Noto Sans, sans-serif" : "Europa-Regular, sans-serif",
    body1: {
      fontSize: "1.0rem", // Covers a number of cases like Form Control Input Label
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: isUsingRussian() ? "Noto Sans, sans-serif" : "Europa-Regular, sans-serif",
          fontWeight: 400,
          fontSize: "1.0rem",
        },
        columnHeaders: {
          fontFamily: isUsingRussian() ? "Noto Sans, sans-serif" : "Europa-Bold, sans-serif",
          fontWeight: 700,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Europa-Regular";
          src:
            local("Europa-Regular"),
            url(${EuropaRegularTTF}) format("truetype");
          font-weight: 400;
          font-display: swap;
        }
        @font-face {
          font-family: "Europa-Bold";
          src:
            local("Europa-Bold"),
            url(${EuropaBoldTTF}) format("truetype");
          font-weight: 700;
          font-display: swap;
        }
        @font-face {
          font-family: "Europa-BoldItalic";
          src:
            local("Europa-BoldItalic"),
            url(${EuropaBoldItalicTTF}) format("truetype");
          font-weight: 700;
          font-display: swap;
        }
        @font-face {
          font-family: "Europa-RegularItalic";
          src:
            local("Europa-RegularItalic"),
            url(${EuropaRegularItalicTTF}) format("truetype");
          font-weight: 400;
          font-display: swap;
        }
        @font-face {
          font-family: "Rooney-Bold";
          src:
            local("Rooney-Bold"),
            url(${RooneyBoldOTF}) format("opentype");
          font-weight: 700;
          font-display: swap;
        }
        @font-face {
          font-family: "Rooney-BoldItalic";
          src:
            local("Rooney-BoldItalic"),
            url(${RooneyBoldItalicOTF}) format("opentype");
          font-weight: 700;
          font-display: swap;
        }
        @font-face {
          font-family: "Rooney-Medium";
          src:
            local("Rooney-Medium"),
            url(${RooneyMediumOTF}) format("opentype");
          font-weight: 500;
          font-display: swap;
        }
        @font-face {
          font-family: "Rooney-MediumItalic";
          src:
            local("Rooney-MediumItalic"),
            url(${RooneyMediumItalicOTF}) format("opentype");
          font-weight: 500;
          font-display: swap;
        }
        @font-face {
          font-family: "Rooney-Regular";
          src:
            local("Rooney-Regular"),
            url(${RooneyRegularOTF}) format("opentype");
          font-weight: 400;
          font-display: swap;
        }
        @font-face {
          font-family: "Rooney-RegularItalic";
          src:
            local("Rooney-RegularItalic"),
            url(${RooneyRegularItalicOTF}) format("opentype");
          font-weight: 400;
          font-display: swap;
        }
      `,
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "primary",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: isUsingRussian() ? "Noto Sans, sans-serif" : "Europa-Bold, sans-serif",
          fontWeight: 700,
          fontSize: "1.0rem",
          fontVariantLigatures: "no-contextual",
          textTransform: "none",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        htmlColor: CivColors.coreDarkNavy,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1.0rem",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "inherit",
          textDecorationColor: "inherit",
          textUnderlineOffset: "0.2em",
        },
        button: {
          verticalAlign: "baseline", // Aligns <Link component="button"> with the surrounding text
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: isUsingRussian() ? "Noto Sans, sans-serif" : "Europa-Bold, sans-serif",
          fontWeight: 700,
          fontVariantLigatures: "no-contextual",
          fontSize: "1.0rem",
          lineHeight: 1.2,
          textTransform: "none",
          textAlign: "center",
          borderRadius: 100,
          padding: "12px 32px",
          boxShadow: "none",
          color: CivColors.coreDarkNavy,
          "&:hover": {
            boxShadow: "none",
            borderWidth: "2px",
          },
          [`&.${buttonClasses.focusVisible}`]: {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
          [`&.${buttonClasses.contained}.${buttonClasses.colorPrimary}`]: {
            color: CivColors.white,
          },
          [`&.${buttonClasses.contained}.${buttonClasses.colorPrimary}.${buttonClasses.disabled}`]: {
            boxShadow: "none",
            color: CivColors.mediumGray,
            backgroundColor: alpha(CivColors.coreDarkNavy, 0.08),
          },
          [`&.${buttonClasses.outlined}.${buttonClasses.colorPrimary}`]: {
            border: `2px solid ${colors.coreNavy}`,
            padding: "10px 32px", //TODO: Check if this should be '12px 32px' instead
            [`& .${touchRippleClasses.child}`]: {
              backgroundColor: CivColors.coreNavy,
            },
          },
          [`&.${buttonClasses.text}.${buttonClasses.colorPrimary}`]: {
            [`& .${touchRippleClasses.child}`]: {
              backgroundColor: CivColors.coreNavy,
            },
          },
          [`&.${buttonClasses.outlined}.${buttonClasses.colorPrimary}.${buttonClasses.disabled}`]: {
            boxShadow: "none",
            border: `2px solid ${alpha(CivColors.coreDarkNavy, 0.26)}`,
            padding: "10px 32px", //TODO: Check if this should be '12px 32px' instead
            color: CivColors.mediumGray,
            backgroundColor: "inherit",
          },
          [`&.${buttonClasses.text}.${buttonClasses.disabled}`]: {
            color: CivColors.mediumGray,
            backgroundColor: "inherit",
          },
          [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}`]: {
            border: `2px solid ${colors.white}`,
          },
          [`&.${buttonClasses.text}.${buttonClasses.sizeSmall}`]: {
            padding: "6px 5px",
          },
          [`&.${buttonClasses.contained}.${buttonClasses.sizeSmall}`]: {
            padding: "6px 12px",
          },
          [`&.${buttonClasses.outlined}.${buttonClasses.sizeSmall}`]: {
            padding: "6px 12px",
          },
        },
        text: {
          borderRadius: 100,
        },
        sizeSmall: {
          padding: "6px 5px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ["&.Mui-focusVisible"]: {
            backgroundColor: alpha(CivColors.coreDarkNavy, 0.3),
          },
          ["&.Mui-disabled svg"]: {
            color: alpha(CivColors.mediumGray, 0.5),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.root}`]: {
            padding: "12px 14px",
            [`& .${autocompleteClasses.input}`]: {
              padding: 0,
            },
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: colors.white,
    },
    text: {
      primary: colors.coreDarkNavy,
    },
    common: {
      white: colors.white,
      black: colors.coreDarkNavy,
    },
    primary: {
      main: colors.coreNavy,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.coreOrange,
      contrastText: colors.white,
    },
    error: {
      main: colors.deepRose,
    },
    tonalOffset: 0.1,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
