import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/context/NotificationContext";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidJobPost } from "src/i18n/Namespaces";
import CreateEditRepostJobContent from "src/pages/medicaidAccount/consumerAccount/components/CreateEditRepostJobContent";
import { JobFormType } from "src/pages/medicaidAccount/consumerAccount/components/CreateEditRepostJobForm";
import PageWrapper, { PageType } from "src/reusable_view_elements/PageWrapper";

interface Props {
  formType: JobFormType;
}

const CreateEditRepostJobWrapper = ({ formType }: Props) => {
  const { useMainSnackbar, closeSnackbar } = useNotification();
  const { t, ready } = useTranslation([nsCommonToasts, nsMedicaidJobPost, nsCommonFormsBtns]);

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/

  const getPageTitle = (): string => {
    switch (formType) {
      case JobFormType.JOB_REPOST:
        return ready ? t("browser_tab_title.repost_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Job Repost";
      case JobFormType.JOB_EDIT:
        return ready ? t("browser_tab_title.edit_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Job Edit";
      case JobFormType.JOB_CREATE:
        return ready ? t("browser_tab_title.post_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Post a Job";
      default:
        return ready ? t("browser_tab_title.post_job", { ns: nsMedicaidJobPost }) : "Carina | Medicaid Post a Job";
    }
  };

  return (
    <PageWrapper title={getPageTitle()} type={PageType.MEDICAID}>
      <CreateEditRepostJobContent formType={formType} />
    </PageWrapper>
  );
};

export default CreateEditRepostJobWrapper;
