import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { JobListItemDTO, JobPostAndRepostDTO, JobPostListDTO, JobPostTagEnum } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidJobPost } from "src/i18n/Namespaces";
import CreateEditRepostJobForm, {
  JobFormProps,
  JobFormType,
  JobPostFormFields,
} from "src/pages/medicaidAccount/consumerAccount/components/CreateEditRepostJobForm";
import { postMedicaidJob, updateJobPostInfo } from "src/pages/medicaidAccount/consumerAccount/Queries";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { getJobPostInfo } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Section, { SectionProps } from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";

interface Props extends SectionProps, Partial<Omit<JobFormProps, "jobPreFill">> {
  formType: JobFormType;
  jobPreFill?: JobPostListDTO;
}

const CreateEditRepostJobContent = ({
  formType,
  stackContent,
  handleExitEditMode,
  jobPreFill,
  ...sectionProps
}: Props) => {
  const { showSnackbar } = useNotification();
  const { selectedRowJob } = useMedicaidContext();
  const { id } = useParams<{ id: string | undefined }>();
  const { verificationId } = useParams<{ verificationId: string }>();
  const { userSession, isUserType } = useUserSession();
  const [job, setJob] = useState<JobPostListDTO>();
  const [loadingJobDetails, setLoadingJobDetails] = useState<boolean>(false);
  const [isSaving, setSaving] = useState<boolean>(false);
  const { t, ready } = useTranslation([nsCommonToasts, nsMedicaidJobPost, nsCommonFormsBtns]);
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`);
  const history = useHistory();

  // Use passed-in job info (jobPreFill) or retrieve info by id if it exists; Otherwise, we know we are creating a new job
  useEffect(() => {
    if (jobPreFill) {
      setJob(jobPreFill);
    } else if (id) {
      (async () => {
        try {
          setLoadingJobDetails(true);
          const res = await getJobPostInfo(id);
          setJob(res.data);
        } catch {
          showSnackbar(
            t("error.loading_your_job", "Sorry, there was a problem loading your job. Please try again later.", {
              ns: nsCommonToasts,
            }),
            "error",
          );
        } finally {
          setLoadingJobDetails(false);
        }
      })();
    }
  }, [jobPreFill]);

  const handleSubmit = async (values: JobPostFormFields) => {
    const isJPTE = (item: JobPostTagEnum | undefined): item is JobPostTagEnum => {
      return !!item;
    };
    const tagsArray: JobPostTagEnum[] = [
      ...values.consumerGender,
      ...values.pronouns,
      ...values.providerGender,
      values.typeOfCare,
      values.ageRange,
      ...values.typeOfSupport,
      ...values.languages,
      ...values.skillsAndExperience,
      ...values.movement,
      ...values.transportation,
      ...values.environmentGeneral,
      ...values.workEnvironment,
      ...values.pets,
    ].filter(isJPTE);
    const jobPostOrRepostDTO: JobPostAndRepostDTO = {
      jobPostDTO: {
        tags: tagsArray,
        hours: values.hours || 0,
        hoursFlexible: values.hoursFlexible,
        scheduleNotes: values.scheduleNotes,
        scheduleFlexible: values.scheduleFlexible,
        furtherIntroduction: values.furtherIntroduction,
        address: values.address,
        userNotes: values.userNotes,
        firstName: values.consumerName,
      },
      verificationId,
    };

    const getUpdatedJob = () => {
      let updatedSelectedRowJob: JobListItemDTO | undefined = undefined;
      if (!!selectedRowJob && !!jobPostOrRepostDTO.jobPostDTO.firstName) {
        updatedSelectedRowJob = {
          ...selectedRowJob,
          firstName: jobPostOrRepostDTO.jobPostDTO.firstName,
          hours: jobPostOrRepostDTO.jobPostDTO.hours,
        };
      }
      return updatedSelectedRowJob;
    };

    try {
      setSaving(true);
      if (formType === JobFormType.JOB_CREATE) {
        await postMedicaidJob(jobPostOrRepostDTO);
        showSnackbar(
          { messageTitle: t("success.job_created_title"), messageBody: t("success.job_created_description") },
          "success",
          true,
        );
      }
      const editJobId = id || jobPreFill?.entity.id;
      if (formType === JobFormType.JOB_EDIT && editJobId) {
        await updateJobPostInfo(editJobId, jobPostOrRepostDTO);
        const isJobDashboardPanelEdit = selectedRowJob != undefined;
        showSnackbar(t("success.job_updated_description"), "success", true, !isJobDashboardPanelEdit);
      }
      if (formType === JobFormType.JOB_REPOST && id) {
        await postMedicaidJob({
          ...jobPostOrRepostDTO,
          jobPostDTO: {
            ...jobPostOrRepostDTO.jobPostDTO,
            reposted: true,
          },
        });
        showSnackbar((t("success.job_reposted_title"), t("success.job_reposted_description")), "success", true);
      }
      if (handleExitEditMode) {
        const updatedSelectedRowJob = getUpdatedJob();
        handleExitEditMode(updatedSelectedRowJob);
      } else {
        history.push(getMedicaidRouteByUserType("myJobList", isUserType));
      }
    } catch {
      showSnackbar(
        t("error.posting_your_job", "Sorry, there was an issue posting your job. Please try again later.", {
          ns: nsCommonToasts,
        }),
        "error",
      );
    } finally {
      setSaving(false);
    }
  };

  const getMainTitle = (): string => {
    switch (formType) {
      case JobFormType.JOB_REPOST:
        return t("repost_job.label", { ns: nsMedicaidJobPost });
      case JobFormType.JOB_EDIT:
        return t("edit_job.label", { ns: nsMedicaidJobPost });
      case JobFormType.JOB_CREATE:
        return t("post_a_job.label", { ns: nsMedicaidJobPost });
      default:
        return t("post_a_job.label", { ns: nsMedicaidJobPost });
    }
  };

  if (!ready || (jobPreFill && !job)) {
    return (
      <Section bgcolor={CivColors.lightGray} minHeight="100vh" {...sectionProps}>
        <Constraint columns={6} textAlign="center">
          <Box sx={{ width: desktopSize ? "650px" : "300px" }} justifySelf="center">
            <LoadingCircle />
          </Box>
        </Constraint>
      </Section>
    );
  }

  if (loadingJobDetails) {
    return (
      <>
        <Section bgcolor={CivColors.lightGray} textAlign="center" {...sectionProps}>
          <Constraint columns={6}>
            <SectionTitle gutterBottom>{getMainTitle()}</SectionTitle>
          </Constraint>
        </Section>
        <Section bgcolor={CivColors.lightGray} textAlign="center" {...sectionProps}>
          <LoadingCircle />
        </Section>
      </>
    );
  }

  // Handle case where job id is invalid, or user is not the job post owner
  if ((id && !job) || (job && userSession?.id !== job.entity.postingUserInfo.id)) {
    return (
      <Section bgcolor={CivColors.lightGray} textAlign="center" pt="10vh" pb="30vh" {...sectionProps}>
        <Constraint columns={6}>
          <SectionTitle gutterBottom>{t("sorry_cannot_find.label", { ns: nsMedicaidJobPost })}</SectionTitle>
          <Body paragraph>{t("sorry_cannot_find.description", { ns: nsMedicaidJobPost })}</Body>
          <LinkStyledAsButton
            variant="contained"
            component={Link}
            to={getMedicaidRouteByUserType("myJobList", isUserType)}
          >
            {t("button.return_to_job_list", { ns: nsCommonFormsBtns })}
          </LinkStyledAsButton>
        </Constraint>
      </Section>
    );
  }

  return (
    <>
      <Section bgcolor={CivColors.lightGray} textAlign="center" minimizeBottomGutter {...sectionProps}>
        <Constraint columns={6}>
          <SectionTitle gutterBottom>{getMainTitle()}</SectionTitle>
          <Body paragraph>{t("post_a_job.description.let_providers_know", { ns: nsMedicaidJobPost })}</Body>
        </Constraint>
      </Section>
      <Section bgcolor={CivColors.lightGray} minimizeTopGutter {...sectionProps}>
        <Constraint columns={6}>
          <CreateEditRepostJobForm
            handleSubmit={handleSubmit}
            formType={formType}
            jobPreFill={job?.entity}
            submitting={isSaving}
            stackContent={stackContent}
            handleExitEditMode={handleExitEditMode}
          />
        </Constraint>
      </Section>
    </>
  );
};

export default CreateEditRepostJobContent;
