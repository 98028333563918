import { Box, Grid } from "@mui/material";
import { Field, Formik, FormikProps } from "formik";
import { TFunction } from "i18next";
import { ChangeEvent, ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { QuestionCircleIcon } from "src/assets/icons/StreamlineIcons";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobListItemDTO, JobPostExtendedDTO, JobPostTagCategoryEnum, JobPostTagEnum } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonTooltips, nsMedicaidJobPost, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedJobPostTag } from "src/i18n/TagHelpers";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { getRoutePrefix } from "src/pages/medicaidAccount/SharedHelperFunctions";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import DashSection from "src/reusable_view_elements/DashSection";
import {
  FormAddressField,
  FormRadioGroupField,
  FormSelectField,
  RadioGroupOption,
  SelectOption,
} from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import { FormCheckboxGroupField } from "src/reusable_view_elements/form_fields/FormCheckboxGroupField";
import { FormTextField } from "src/reusable_view_elements/form_fields/FormTextField";
import LongForm from "src/reusable_view_elements/form_fields/LongForm";
import {
  AutocompleteOption,
  MultiAutocompleteAndChipsField,
} from "src/reusable_view_elements/form_fields/MultiAutocompleteAndChipsField";
import InteractiveWrapper from "src/reusable_view_elements/InteractiveWrapper";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Popover from "src/reusable_view_elements/Popover";
import GenderPopover from "src/reusable_view_elements/popovers/GenderPopover";
import LgbtPopover from "src/reusable_view_elements/popovers/LgbtPopover";
import NurseDelegationPopover from "src/reusable_view_elements/popovers/NurseDelegationPopover";
import PronounsPopover from "src/reusable_view_elements/popovers/PronounsPopover";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { number, object, string } from "yup";

/*******************************************************************************
 ******************** Options definitions **********************************
 ******************************************************************************/

export interface GeneralTagOptionType<T> {
  value: T;
  label: string | ReactElement;
  locizeKey?: string;
  namespace?: string;
}

export const ageRangeOptions: RadioGroupOption<JobPostTagEnum>[] = [
  {
    key: JobPostTagEnum.CHILD,
    optionLabel: "Child (17 years old or younger)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.CHILD).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    key: JobPostTagEnum.ADULT,
    optionLabel: "Adult (18 - 64 years old)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ADULT).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    key: JobPostTagEnum.OLDER_ADULT,
    optionLabel: "Older adult (65 years old or older)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.OLDER_ADULT).key,
    namespace: nsMedicaidTagsEnums,
  },
];

export const typeOfSupportOptions: GeneralTagOptionType<JobPostTagEnum>[] = [
  {
    value: JobPostTagEnum.ADDICTIONS_MENTAL,
    label: "Addictions and/or mental health needs",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ADDICTIONS_MENTAL).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.BEHAVIORAL,
    label: "Behavioral needs",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.BEHAVIORAL).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.DEVELOPMENTAL_DISABILITY,
    label: "Developmental disability",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.DEVELOPMENTAL_DISABILITY).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.HOME_GENERAL,
    label: "General care to remain at home",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.HOME_GENERAL).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.PHYSICAL_DISABILITY,
    label: "Physical disability",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.PHYSICAL_DISABILITY).key,
    namespace: nsMedicaidTagsEnums,
  },
];

export const languageOptions: GeneralTagOptionType<JobPostTagEnum>[] = [
  {
    value: JobPostTagEnum.ENGLISH,
    label: "English",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ENGLISH).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.RUSSIAN,
    label: "Russian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.RUSSIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.SPANISH,
    label: "Spanish",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.SPANISH).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.VIETNAMESE,
    label: "Vietnamese",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.VIETNAMESE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.KOREAN,
    label: "Korean",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.KOREAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.CANTONESE,
    label: "Cantonese",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.CANTONESE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.ARABIC,
    label: "Arabic",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ARABIC).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.SOMALI,
    label: "Somali",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.SOMALI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.UKRAINIAN,
    label: "Ukrainian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.UKRAINIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.KHMER,
    label: "Khmer",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.KHMER).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TAGALOG,
    label: "Tagalog",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TAGALOG).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.MANDARIN,
    label: "Mandarin",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.MANDARIN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.LAOTIAN,
    label: "Laotian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.LAOTIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.SAMOAN,
    label: "Samoan",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.SAMOAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.AMERICAN_SIGN,
    label: "American Sign Language",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.AMERICAN_SIGN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.AMHARIC,
    label: "Amharic",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.AMHARIC).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.PUNJABI,
    label: "Punjabi",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.PUNJABI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.NEPALI,
    label: "Nepali",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.NEPALI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.FARSI,
    label: "Farsi",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.FARSI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TIGRINYA,
    label: "Tigrinya",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TIGRINYA).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.OROMO,
    label: "Oromo",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.OROMO).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.ARMENIAN,
    label: "Armenian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ARMENIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.JAPANESE,
    label: "Japanese",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.JAPANESE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.ROMANIAN,
    label: "Romanian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ROMANIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.SWAHILI,
    label: "Swahili",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.SWAHILI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.BOSNIAN,
    label: "Bosnian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.BOSNIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.THAI,
    label: "Thai",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.THAI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.HINDI,
    label: "Hindi",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.HINDI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.ALBANIAN,
    label: "Albanian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ALBANIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.FRENCH,
    label: "French",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.FRENCH).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.OTHER_LANGUAGE,
    label: "Other Language",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.OTHER_LANGUAGE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.BENGALI,
    label: "Bengali",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.BENGALI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.DARI,
    label: "Dari",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.DARI).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.KAREN,
    label: "Karen",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.KAREN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TURKISH,
    label: "Turkish",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TURKISH).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.BURMESE,
    label: "Burmese",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.BURMESE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.URDU,
    label: "Urdu",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.URDU).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.BULGARIAN,
    label: "Bulgarian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.BULGARIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.CHUUKESE,
    label: "Chuukese",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.CHUUKESE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.HMONG,
    label: "Hmong",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.HMONG).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.KURDISH,
    label: "Kurdish",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.KURDISH).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.MOLDAVIAN,
    label: "Moldavian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.MOLDAVIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.POLISH,
    label: "Polish",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.POLISH).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.PORTUGUESE,
    label: "Portuguese",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.PORTUGUESE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TAMIL,
    label: "Tamil",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TAMIL).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.CROATIAN,
    label: "Croatian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.CROATIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.GERMAN,
    label: "German",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.GERMAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.PASHTU,
    label: "Pashtu",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.PASHTU).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TONGAN,
    label: "Tongan",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TONGAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.ITALIAN,
    label: "Italian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.ITALIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.CAMBODIAN,
    label: "Cambodian",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.CAMBODIAN).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.CREOLE,
    label: "Creole",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.CREOLE).key,
    namespace: nsMedicaidTagsEnums,
  },
];

//TODO: Unify MultiSelectlanguageOptions and LanguageOptions once the advanced job filters
// are worked on. Skipping here to avoid scope creep.
export function getLanguageOptions(t: TFunction): AutocompleteOption<JobPostTagEnum>[] {
  return languageOptions.map((option) => ({
    value: option.value,
    label: t(getLocalizedJobPostTag(option.value).key, { ns: nsMedicaidTagsEnums }),
  }));
}

interface SkillsTagOptionType {
  value: keyof typeof JobPostTagEnum;
  label: string;
  locizeKey: string;
  namespace: string;
}

const skillsMovementOptions: SkillsTagOptionType[] = [
  {
    value: JobPostTagEnum.AMBULATION,
    label: "Mobility assistance (ambulation)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.AMBULATION).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.POSITIONING,
    label: "Positioning",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.POSITIONING).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TRANSFER,
    label: "Transfers",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TRANSFER).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TRANSFER_ASSISTED,
    label: "Assisted transfers (using Hoyer Lift, etc.)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TRANSFER_ASSISTED).key,
    namespace: nsMedicaidTagsEnums,
  },
];

const skillsTransportationOptions: SkillsTagOptionType[] = [
  {
    value: JobPostTagEnum.TRANSPORT,
    label: "Transport - using the consumer's vehicle",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TRANSPORT).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.TRANSPORT_PROVIDER,
    label: "Transport - using the provider's vehicle",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.TRANSPORT_PROVIDER).key,
    namespace: nsMedicaidTagsEnums,
  },
];

const workEnvironmentOptions: GeneralTagOptionType<JobPostTagEnum>[] = [
  {
    value: JobPostTagEnum.FRAG_FREE,
    label: "Fragrance-free home",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.FRAG_FREE).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.NON_SMOKING,
    label: "Non-smoking home",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.NON_SMOKING).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.SMOKING,
    label: "Smoking home",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.SMOKING).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.NON_SMOKING_PROVIDER,
    label: "I prefer a non-smoking provider",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.NON_SMOKING_PROVIDER).key,
    namespace: nsMedicaidTagsEnums,
  },
];

const petOptions: GeneralTagOptionType<JobPostTagEnum>[] = [
  {
    value: JobPostTagEnum.DOG,
    label: "Dog(s)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.DOG).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.CAT,
    label: "Cat(s)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.CAT).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.BIRD,
    label: "Bird(s)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.BIRD).key,
    namespace: nsMedicaidTagsEnums,
  },
  {
    value: JobPostTagEnum.OTHER_PET,
    label: "Other pet(s)",
    locizeKey: getLocalizedJobPostTag(JobPostTagEnum.OTHER_PET).key,
    namespace: nsMedicaidTagsEnums,
  },
];

export enum JobFormType {
  JOB_CREATE,
  JOB_EDIT,
  JOB_REPOST,
}

/*******************************************************************************
 ********************** Helper definition ***************************************
 ******************************************************************************/

export interface TagsObjectType {
  tagArray: JobPostTagEnum[];
  ageRangeTag: JobPostTagEnum;
  typeOfSupportTags: JobPostTagEnum[];
  languageTags: JobPostTagEnum[];
  skillTags: JobPostTagEnum[];
  movementTags: JobPostTagEnum[];
  transportationTags: JobPostTagEnum[];
  environmentGeneralTags: JobPostTagEnum[];
  environmentTags: JobPostTagEnum[];
  petTags: JobPostTagEnum[];
  consumerGenderTags: JobPostTagEnum[];
  pronounTags: JobPostTagEnum[];
  providerGenderTags: JobPostTagEnum[];
  typeOfCareTag: JobPostTagEnum;
}

/****************** initializing tags object  *******************/

export const getTagsObject = (jobPost?: JobPostExtendedDTO): TagsObjectType => {
  return {
    tagArray: jobPost ? jobPost.tags.map((tc) => tc.tag) : [],
    ageRangeTag: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.AGE_RANGE).map((tc) => tc.tag)[0]
      : JobPostTagEnum.ADULT,
    typeOfSupportTags: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.TYPE_OF_SUPPORT).map((tc) => tc.tag)
      : [],
    languageTags: jobPost
      ? jobPost.tags
          .filter((tc) => tc.category === JobPostTagCategoryEnum.LANGUAGE)
          .sort((a, b) => a.order - b.order)
          .map((tc) => tc.tag)
      : [],
    skillTags: jobPost
      ? jobPost.tags
          .filter((tc) => tc.category === JobPostTagCategoryEnum.SKILL)
          .filter((tagObj) => tagObj.tag !== JobPostTagEnum.LGBT_FRIENDLY) //filter out the old LGBT tag for now until we can remove from enum class
          .map((tc) => tc.tag)
      : [],
    movementTags: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.MOVEMENT).map((tc) => tc.tag)
      : [],
    transportationTags: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.TRANSPORTATION).map((tc) => tc.tag)
      : [],
    environmentGeneralTags: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.ENVIRONMENT_GENERAL).map((tc, _) => tc.tag)
      : [],
    environmentTags: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.ENVIRONMENT).map((tc) => tc.tag)
      : [],
    petTags: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.PETS).map((tc) => tc.tag)
      : [],
    consumerGenderTags: jobPost
      ? jobPost.tags
          .filter((tc) => tc.category === JobPostTagCategoryEnum.CLIENT_GENDER)
          .sort((a, b) => a.order - b.order)
          .map((tc) => tc.tag)
      : [],
    pronounTags: jobPost
      ? jobPost.tags
          .filter((tc) => tc.category === JobPostTagCategoryEnum.PRONOUN)
          .sort((a, b) => a.order - b.order)
          .map((tc) => tc.tag)
      : [],
    providerGenderTags: jobPost
      ? jobPost.tags
          .filter((tc) => tc.category === JobPostTagCategoryEnum.PREFERRED_PROVIDER_GENDER)
          .sort((a, b) => a.order - b.order)
          .map((tc) => tc.tag)
      : [],
    typeOfCareTag: jobPost
      ? jobPost.tags.filter((tc) => tc.category === JobPostTagCategoryEnum.TYPE_OF_CARE).map((tc) => tc.tag)[0]
      : JobPostTagEnum.ONGOING,
  };
};

export interface JobPostFormFields {
  userNotes?: string;
  consumerName?: string;
  ageRange?: JobPostTagEnum;
  typeOfSupport: JobPostTagEnum[];
  consumerGender: JobPostTagEnum[];
  pronouns: JobPostTagEnum[];
  providerGender: JobPostTagEnum[];
  languages: JobPostTagEnum[];
  typeOfCare?: JobPostTagEnum;
  hours?: number;
  hoursFlexible: boolean;
  scheduleNotes: string;
  scheduleFlexible: boolean;
  skillsAndExperience: JobPostTagEnum[];
  movement: JobPostTagEnum[];
  transportation: JobPostTagEnum[];
  environmentGeneral: JobPostTagEnum[];
  workEnvironment: JobPostTagEnum[];
  pets: JobPostTagEnum[];
  furtherIntroduction: string;
  address: string;
}

/*******************************************************************************
 ******************* Main Component definition **********************************
 ******************************************************************************/

export interface JobFormProps {
  formType: JobFormType;
  jobPreFill?: JobPostExtendedDTO;
  handleSubmit: (values: JobPostFormFields) => Promise<void>;
  submitting: boolean;
  stackContent?: boolean;
  // Example use of 'handleExitEditMode' callback: when assistive role user exits edit mode in the job side panel
  handleExitEditMode?: (updatedJob?: JobListItemDTO) => void;
}

const CreateEditRepostJobForm = ({
  handleSubmit,
  formType,
  jobPreFill,
  submitting,
  stackContent,
  handleExitEditMode,
}: JobFormProps) => {
  const { isUserType } = useUserSession();
  const { consumerFirstNameFromCoordinator } = useMedicaidContext();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsCommonTooltips, nsMedicaidJobPost, nsMedicaidTagsEnums]);

  function getTranslatedJobPostTag(tag: JobPostTagEnum): string {
    return t(getLocalizedJobPostTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  const consumerGenderOptions: GeneralTagOptionType<JobPostTagEnum>[] = [
    JobPostTagEnum.MALE_CLIENT,
    JobPostTagEnum.FEMALE_CLIENT,
    JobPostTagEnum.NONBINARY_CLIENT,
    JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT,
  ].map((tag) => ({ value: tag, label: getTranslatedJobPostTag(tag) }));

  const allProviderGenderTags = [
    JobPostTagEnum.NO_GENDER_PREFERENCE,
    JobPostTagEnum.MALE,
    JobPostTagEnum.FEMALE,
    JobPostTagEnum.NONBINARY,
  ];
  const providerGenderOptions: GeneralTagOptionType<JobPostTagEnum>[] = allProviderGenderTags.map((tag) => ({
    value: tag,
    label: getTranslatedJobPostTag(tag),
  }));

  const pronounsOptions: AutocompleteOption<JobPostTagEnum>[] = [
    JobPostTagEnum.HE_HIM,
    JobPostTagEnum.SHE_HER,
    JobPostTagEnum.THEY_THEM,
    JobPostTagEnum.XE_XEM,
    JobPostTagEnum.ZE_ZIR,
  ].map((tag) => ({ value: tag, label: getTranslatedJobPostTag(tag) }));

  const environmentGeneralOptions: GeneralTagOptionType<JobPostTagEnum>[] = [
    JobPostTagEnum.MULTIGENERATIONAL_HOUSEHOLD,
    JobPostTagEnum.MULTILINGUAL_HOUSEHOLD,
  ].map((tag) => ({ value: tag, label: getTranslatedJobPostTag(tag) }));
  environmentGeneralOptions.push({
    value: JobPostTagEnum.LGBTQIA_HOUSEHOLD,
    label: <LgbtPopover>{getTranslatedJobPostTag(JobPostTagEnum.LGBTQIA_HOUSEHOLD)}</LgbtPopover>,
  });

  const skillsGeneralOptions: GeneralTagOptionType<JobPostTagEnum>[] = [
    JobPostTagEnum.HOUSEKEEPING,
    JobPostTagEnum.LAUNDRY,
    JobPostTagEnum.MEAL_PREP,
    JobPostTagEnum.SHOPPING,
    JobPostTagEnum.PERSONAL_CARE,
    JobPostTagEnum.MEDS_MGMT,
    JobPostTagEnum.BEHAVIOR_CHALLENGE,
  ].map((tag) => ({ value: tag, label: getTranslatedJobPostTag(tag) }));
  skillsGeneralOptions.push({
    value: JobPostTagEnum.NURSE_DELEGATION,
    label: <NurseDelegationPopover>{getTranslatedJobPostTag(JobPostTagEnum.NURSE_DELEGATION)}</NurseDelegationPopover>,
  });

  const typeOfCareOptions: SelectOption<JobPostTagEnum>[] = [
    {
      value: JobPostTagEnum.ONGOING,
      label: getTranslatedJobPostTag(JobPostTagEnum.ONGOING),
    },
    {
      value: JobPostTagEnum.ONETIME,
      label: getTranslatedJobPostTag(JobPostTagEnum.ONETIME),
    },
    {
      value: JobPostTagEnum.ONCALL,
      label: getTranslatedJobPostTag(JobPostTagEnum.ONCALL),
    },
  ];

  const FrequencyTooltipContent = () => (
    <>
      <BodyEmphasis>{t("routine_care.label", { ns: nsCommonTooltips })}</BodyEmphasis>
      <Body style={{ marginBottom: 24 }}>{t("routine_care.description", { ns: nsCommonTooltips })}</Body>
      <BodyEmphasis>{t("relief_respite.label", { ns: nsCommonTooltips })}</BodyEmphasis>
      <Body style={{ marginBottom: 24 }}>{t("relief_respite.description", { ns: nsCommonTooltips })}</Body>
      <BodyEmphasis>{t("emergency.label", { ns: nsCommonTooltips })}</BodyEmphasis>
      <Body>{t("emergency.description", { ns: nsCommonTooltips })}</Body>
    </>
  );

  function getSubmitButtonText(): string {
    switch (formType) {
      case JobFormType.JOB_REPOST:
        return submitting
          ? t("button.reposting", { ns: nsCommonFormsBtns })
          : t("button.repost_job", { ns: nsCommonFormsBtns });
      case JobFormType.JOB_EDIT:
        return submitting
          ? t("button.saving", { ns: nsCommonFormsBtns })
          : t("button.save_job", { ns: nsCommonFormsBtns });
      case JobFormType.JOB_CREATE:
      default:
        return submitting
          ? t("button.posting", { ns: nsCommonFormsBtns })
          : t("button.post_job", { ns: nsCommonFormsBtns });
    }
  }

  function getCancelButtonLink(): string {
    if (formType === JobFormType.JOB_REPOST && jobPreFill?.id) {
      return generatePath(getRoutePrefix(isUserType).myDeletedJobDetails, { id: jobPreFill.id });
    }
    if (formType === JobFormType.JOB_EDIT && jobPreFill?.id) {
      return generatePath(getRoutePrefix(isUserType).myJobDetails, { id: jobPreFill.id });
    }
    return getRoutePrefix(isUserType).myJobList;
  }

  const handleConsumerGenderChange = (
    e: ChangeEvent<HTMLInputElement>,
    { values, setFieldValue }: Pick<FormikProps<JobPostFormFields>, "values" | "setFieldValue">,
  ) => {
    if (e.currentTarget.value === JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT) {
      setFieldValue(
        e.currentTarget.name,
        values.consumerGender.includes(JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT)
          ? []
          : [JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT],
        false,
      );
    } else if (!values.consumerGender.includes(e.currentTarget.value as JobPostTagEnum)) {
      const newValue = [...values.consumerGender, e.currentTarget.value];
      newValue.filter((v) => v !== JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT);
      setFieldValue(e.currentTarget.name, newValue);
    }
  };

  const isConsumerGenderCheckboxDisabled = (
    checkboxValue: keyof typeof JobPostTagEnum,
    { values }: Pick<FormikProps<JobPostFormFields>, "values">,
  ): boolean => {
    return checkboxValue !== JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT
      ? values.consumerGender.includes(JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT)
      : values.consumerGender.includes(JobPostTagEnum.MALE_CLIENT) ||
          values.consumerGender.includes(JobPostTagEnum.FEMALE_CLIENT) ||
          values.consumerGender.includes(JobPostTagEnum.NONBINARY_CLIENT);
  };

  const handleProviderGenderChange = (
    e: ChangeEvent<HTMLInputElement>,
    { values, setFieldValue }: Pick<FormikProps<JobPostFormFields>, "values" | "setFieldValue">,
  ) => {
    if (e.currentTarget.value === JobPostTagEnum.NO_GENDER_PREFERENCE) {
      setFieldValue(
        e.currentTarget.name,
        values.providerGender.includes(JobPostTagEnum.NO_GENDER_PREFERENCE) ? [] : allProviderGenderTags,
        false,
      );
    } else if (
      values.providerGender.length >= 2 &&
      !values.providerGender.includes(e.currentTarget.value as JobPostTagEnum)
    ) {
      setFieldValue(e.currentTarget.name, allProviderGenderTags);
    }
  };

  const isProviderGenderCheckboxDisabled = (
    checkboxValue: keyof typeof JobPostTagEnum,
    { values }: Pick<FormikProps<JobPostFormFields>, "values">,
  ): boolean => {
    return checkboxValue !== JobPostTagEnum.NO_GENDER_PREFERENCE
      ? values.providerGender.includes(JobPostTagEnum.NO_GENDER_PREFERENCE)
      : false;
  };

  const scheduleMaxLength = 500;
  const furtherIntroMaxLength = 500;
  const userNotesMaxLength = 500;

  //TODO: [issue#1427] - When the validation schema message is triggered, if the user changes language,
  // the message remains in the previous language.
  const validationSchema = object({
    userNotes: string()
      .label(t("field.notes.placeholder", { ns: nsCommonFormsBtns }))
      .nullable()
      .max(userNotesMaxLength, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    consumerName: isUserType(UserType.Consumer)
      ? string().nullable()
      : string()
          .label(t("field.consumer_name.label", { ns: nsMedicaidJobPost }))
          .required(t("field.care_recipient_name.error", { ns: nsCommonFormsBtns })),
    ageRange: string()
      .label(t("general_information.age.label", { ns: nsMedicaidJobPost }))
      .required(t("field.generic.error.select_one_these_options", { ns: nsCommonFormsBtns })),
    typeOfSupport: string()
      .label(t("general_information.care_support.label", { ns: nsMedicaidJobPost }))
      .required(t("field.generic.error.select_one_these_options", { ns: nsCommonFormsBtns })),
    consumerGender: string()
      .label(t("gender_s.label", { ns: nsMedicaidJobPost }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    providerGender: string()
      .label(t("gender_s_of_provider.description.select_preference", { ns: nsMedicaidJobPost }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    languages: string()
      .label(t("languages.label", { ns: nsMedicaidJobPost }))
      .required(t("field.languages.error", { ns: nsCommonFormsBtns })),
    typeOfCare: string()
      .label(t("field.care_freq.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.select_one_these_options", { ns: nsCommonFormsBtns })),
    hours: number()
      .label(t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns }))
      .required(t("field.avail_month_hours.error", { ns: nsCommonFormsBtns }))
      .max(320, t("field.available_monthly_hours.error_max", { ns: nsCommonFormsBtns }))
      .min(0, t("field.available_monthly_hours.error_min", { ns: nsCommonFormsBtns })),
    scheduleNotes: string()
      .label(t("field.care_schedule.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(scheduleMaxLength, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    skillsAndExperience: string()
      .label(t("care_needs.general_care.label", { ns: nsMedicaidJobPost }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    movement: string().nullable(),
    transportation: string().nullable(),
    workEnvironment: string()
      .label(t("home_env.scents_smoking.label", { ns: nsMedicaidJobPost }))
      .required(t("field.generic.error.select_at_least_one", { ns: nsCommonFormsBtns })),
    furtherIntroduction: string()
      .label(t("field.hobbies_interests.label", { ns: nsCommonFormsBtns }))
      .max(furtherIntroMaxLength, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    address: string()
      .label(t("field.address.label", { ns: nsCommonFormsBtns }))
      .required(t("field.address.error_valid", { ns: nsCommonFormsBtns })),
  });

  const tagsObject = getTagsObject(jobPreFill);
  const initialValues: JobPostFormFields = jobPreFill
    ? {
        userNotes: formType === JobFormType.JOB_REPOST ? undefined : jobPreFill.userNotes,
        consumerName: jobPreFill.firstName,
        ageRange: tagsObject.ageRangeTag,
        typeOfSupport: tagsObject.typeOfSupportTags,
        consumerGender: tagsObject.consumerGenderTags,
        pronouns: tagsObject.pronounTags,
        providerGender: tagsObject.providerGenderTags,
        languages: tagsObject.languageTags,
        typeOfCare: tagsObject.typeOfCareTag,
        hours: jobPreFill.hours,
        hoursFlexible: jobPreFill.hoursFlexible,
        scheduleNotes: jobPreFill.scheduleNotes,
        scheduleFlexible: jobPreFill.scheduleFlexible,
        skillsAndExperience: tagsObject.skillTags,
        movement: tagsObject.movementTags,
        transportation: tagsObject.transportationTags,
        environmentGeneral: tagsObject.environmentGeneralTags,
        workEnvironment: tagsObject.environmentTags,
        pets: tagsObject.petTags,
        furtherIntroduction: jobPreFill.furtherIntroduction,
        address: jobPreFill.address,
      }
    : {
        userNotes: undefined,
        consumerName: consumerFirstNameFromCoordinator || undefined,
        ageRange: undefined,
        typeOfSupport: [],
        consumerGender: [],
        pronouns: [],
        providerGender: [],
        languages: [],
        typeOfCare: undefined,
        hours: undefined,
        hoursFlexible: false,
        scheduleNotes: "",
        scheduleFlexible: false,
        skillsAndExperience: [],
        movement: [],
        transportation: [],
        environmentGeneral: [],
        workEnvironment: [],
        pets: [],
        furtherIntroduction: "",
        address: "",
      };

  if (!ready) {
    return (
      <Section minHeight="100vh">
        <Constraint columns={8} textAlign="center">
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  const languages = getLanguageOptions(t);

  const stackedStyle = stackContent && {
    labelGridProps: { md: 12 },
    contentGridProps: { md: 12 },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => {
        return (
          <LongForm localizationReady={ready}>
            <Body>{t("field_marked_asterisk.label", { ns: nsMedicaidJobPost })}</Body>
            <DashSection
              bodyTitleLabel
              label={t("general_information.label_v2", { ns: nsMedicaidJobPost })}
              container
              {...stackedStyle}
            >
              {!isUserType(UserType.Consumer) && (
                <>
                  {(isUserType(UserType.MedicaidReferralCoordinator) || isUserType(UserType.MedicaidCaseManager)) && (
                    <Grid item xs={12} id="userNotes-grid">
                      <FormTextField
                        name="userNotes"
                        label={t("field.notes.placeholder", { ns: nsCommonFormsBtns })}
                        multiline
                        minRows={5}
                        helperText={t("field.consumer_notes.helper_text", { ns: nsMedicaidJobPost })}
                        required={isRequiredField("userNotes", validationSchema)}
                        maximumlength={userNotesMaxLength}
                        placeholder={t("field.notes.placeholder", { ns: nsCommonFormsBtns })}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} id="consumerName-grid">
                    <FormTextField
                      name="consumerName"
                      label={t("field.consumer_name.label", { ns: nsMedicaidJobPost })}
                      helperText={t("field.consumer_name.helper_text", { ns: nsMedicaidJobPost })}
                      required={isRequiredField("consumerName", validationSchema)}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} id="ageRange-grid">
                <FormRadioGroupField<JobPostTagEnum>
                  name="ageRange"
                  label={`${t("general_information.age.label", { ns: nsMedicaidJobPost })} *`}
                  labelAlwaysOnTop
                  options={ageRangeOptions}
                />
              </Grid>
              <Grid item xs={12} id="typeOfSupport-grid">
                <FormCheckboxGroupField<keyof typeof JobPostTagEnum>
                  name="typeOfSupport"
                  label={t("general_information.care_support.label", { ns: nsMedicaidJobPost })}
                  options={typeOfSupportOptions}
                  required={isRequiredField("typeOfSupport", validationSchema)}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("gender_s.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12} id="consumerGender-grid">
                <InteractiveWrapper
                  fieldName="consumerGender"
                  sx={{ marginBottom: "24px" }}
                  languagePeek={t("gender_s.description.check_all_apply", { ns: nsMedicaidJobPost })}
                >
                  <Trans
                    t={t}
                    i18nKey="gender_s.description.check_all_apply"
                    ns={nsMedicaidJobPost}
                    components={{ popover: <GenderPopover /> }}
                    parent={Body}
                  />
                </InteractiveWrapper>
                <FormCheckboxGroupField<keyof typeof JobPostTagEnum>
                  name="consumerGender"
                  label={t("gender_s.label", { ns: nsMedicaidJobPost })}
                  options={consumerGenderOptions}
                  checkboxesProps={{
                    onChange: (e) => handleConsumerGenderChange(e, { values, setFieldValue }),
                    disabled: (checkboxValue) => isConsumerGenderCheckboxDisabled(checkboxValue, { values }),
                  }}
                  required={isRequiredField("consumerGender", validationSchema)}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("pronouns.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12}>
                <InteractiveWrapper
                  fieldName="pronouns"
                  sx={{ marginBottom: "24px" }}
                  languagePeek={t("field.pronouns.label", { ns: nsMedicaidJobPost })}
                >
                  <Trans
                    t={t}
                    i18nKey="pronouns.description.enter_your_pronouns"
                    ns={nsMedicaidJobPost}
                    components={{ popover: <PronounsPopover /> }}
                    parent={Body}
                  />
                </InteractiveWrapper>
                <Body paragraph>{t("pronouns.description.you_can_add", { ns: nsMedicaidJobPost })}</Body>
                <Body paragraph>{t("pronouns.description.pronouns_are_optional", { ns: nsMedicaidJobPost })}</Body>
                <MultiAutocompleteAndChipsField<JobPostTagEnum>
                  name="pronouns"
                  label={t("field.pronouns.label", { ns: nsMedicaidJobPost })}
                  maxSelections={2}
                  noOptionsText={t("field.pronouns.no_options_text", { ns: nsMedicaidJobPost })}
                  reachedMaxText={t("field.pronouns.remove_existing_pronoun_text", { ns: nsMedicaidJobPost })}
                  selectOptions={pronounsOptions}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("gender_s_of_provider.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12} id="providerGender-grid">
                <FormCheckboxGroupField<keyof typeof JobPostTagEnum>
                  name="providerGender"
                  label={t("gender_s_of_provider.description.select_preference", { ns: nsMedicaidJobPost })}
                  options={providerGenderOptions}
                  checkboxesProps={{
                    onChange: (e) => handleProviderGenderChange(e, { values, setFieldValue }),
                    disabled: (checkboxValue) => isProviderGenderCheckboxDisabled(checkboxValue, { values }),
                  }}
                  required={isRequiredField("providerGender", validationSchema)}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("languages.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12} id="languages-grid">
                <MultiAutocompleteAndChipsField<JobPostTagEnum>
                  name="languages"
                  label={`${t("languages.label", { ns: nsMedicaidJobPost })} *`}
                  disableDefaultSort
                  maxSelections={languages.length}
                  selectOptions={[
                    [...languages.filter((v) => v.value === JobPostTagEnum.ENGLISH)],
                    [...languages.filter((v) => v.value !== JobPostTagEnum.ENGLISH)].sort((a, b) =>
                      a.label.localeCompare(b.label),
                    ),
                  ].flat()}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("schedule.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12} container spacing={1} id="typeOfCare-grid">
                <Grid item xs={11}>
                  <FormSelectField
                    selectOptions={typeOfCareOptions}
                    name="typeOfCare"
                    label={t("field.care_freq.label", { ns: nsCommonFormsBtns })}
                    required={isRequiredField("typeOfCare", validationSchema)}
                  />
                </Grid>
                <Grid item xs={1} direction="column" sx={{ alignContent: "start", marginTop: "42px" }}>
                  <InteractiveWrapper
                    fieldName="typeOfCare"
                    languagePeek={t("field.care_freq.label", { ns: nsCommonFormsBtns })}
                  >
                    <Popover
                      contentTitle={t("frequency_of_care.popover_title", { ns: nsMedicaidJobPost })}
                      aria-label={t("frequency_of_care.popover_title", { ns: nsMedicaidJobPost })}
                      content={<FrequencyTooltipContent />}
                      contentId="typeOfCare-popover"
                    >
                      <QuestionCircleIcon />
                    </Popover>
                  </InteractiveWrapper>
                </Grid>
              </Grid>
              <Grid item xs={12} id="hours-grid">
                <FormTextField
                  type="number"
                  name="hours"
                  label={`${t("field.available_monthly_hours.label", { ns: nsCommonFormsBtns })}`}
                  inputProps={{ min: 0, max: 200 }}
                  fullWidth
                  required={isRequiredField("hours", validationSchema)}
                  helperText={t("field.avail_month_hours.helper_text", { ns: nsCommonFormsBtns })}
                />
              </Grid>
              <Grid item xs={12} direction="column" style={{ justifyContent: "center" }} container>
                <Grid item>
                  <CheckboxField
                    name="hoursFlexible"
                    id="hoursFlexible"
                    label={t("field.avail_month_hours.hours_flexible.label", { ns: nsCommonFormsBtns })}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} id="scheduleNotes-grid">
                <Field
                  as={FormTextField}
                  name="scheduleNotes"
                  label={t("field.care_schedule.label", { ns: nsCommonFormsBtns })}
                  placeholder={t("field.care_schedule.placeholder", { ns: nsCommonFormsBtns })}
                  multiline
                  minRows={5}
                  helperText={""}
                  required={isRequiredField("scheduleNotes", validationSchema)}
                  maximumlength={scheduleMaxLength}
                />
              </Grid>
              <Grid item xs={12} direction="column" style={{ justifyContent: "center" }} container>
                <Grid item>
                  <CheckboxField
                    name="scheduleFlexible"
                    id="scheduleFlexible"
                    label={t("field.care_schedule.days_times_flexible.label", { ns: nsCommonFormsBtns })}
                  />
                </Grid>
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("care_needs.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Body>{t("care_needs.description", { ns: nsMedicaidJobPost })}</Body>
              </Grid>
              <Grid item xs={12} id="skillsAndExperience-grid">
                <FormCheckboxGroupField<keyof typeof JobPostTagEnum>
                  name="skillsAndExperience"
                  label={t("care_needs.general_care.label", { ns: nsMedicaidJobPost })}
                  options={skillsGeneralOptions}
                  required={isRequiredField("skillsAndExperience", validationSchema)}
                />
              </Grid>
              <Grid item xs={12} id="movement-grid">
                <FormCheckboxGroupField<keyof typeof JobPostTagEnum>
                  name="movement"
                  label={t("care_needs.movement.label", { ns: nsMedicaidJobPost })}
                  options={skillsMovementOptions}
                />
              </Grid>
              <Grid item xs={12} id="transportation-grid">
                <FormCheckboxGroupField<keyof typeof JobPostTagEnum>
                  name="transportation"
                  label={t("care_needs.transportation.label", { ns: nsMedicaidJobPost })}
                  options={skillsTransportationOptions}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("home_env.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12}>
                <Body gutterBottom>{t("home_env.description.check_all_apply", { ns: nsMedicaidJobPost })}</Body>
              </Grid>
              <Grid item xs={12}>
                <FormCheckboxGroupField<string>
                  name="environmentGeneral"
                  label={t("home_env.general.label", { ns: nsMedicaidJobPost })}
                  options={environmentGeneralOptions}
                  required={isRequiredField("environmentGeneral", validationSchema)}
                />
              </Grid>
              <Grid item xs={12} id="workEnvironment-grid">
                <FormCheckboxGroupField<string>
                  name="workEnvironment"
                  label={t("home_env.scents_smoking.label", { ns: nsMedicaidJobPost })}
                  options={workEnvironmentOptions}
                  required={isRequiredField("workEnvironment", validationSchema)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormCheckboxGroupField<string>
                  name="pets"
                  label={t("home_env.my_pets.label", { ns: nsMedicaidJobPost })}
                  options={petOptions}
                  required={isRequiredField("pets", validationSchema)}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("hobbies_interests.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12} id="furtherIntroduction-grid">
                <FormTextField
                  name="furtherIntroduction"
                  label={t("field.hobbies_interests.label", { ns: nsCommonFormsBtns })}
                  placeholder={t("field.hobbies_and_further_info.placeholder", { ns: nsCommonFormsBtns })}
                  multiline
                  minRows={5}
                  helperText={""}
                  required={isRequiredField("furtherIntroduction", validationSchema)}
                  maximumlength={furtherIntroMaxLength}
                />
              </Grid>
            </DashSection>
            <DashSection
              bodyTitleLabel
              label={t("location.label", { ns: nsMedicaidJobPost })}
              topBorder
              container
              {...stackedStyle}
            >
              <Grid item xs={12} style={{ paddingTop: 16, paddingBottom: 40 }}>
                <FormAddressField name="address" label={`${t("field.address.label", { ns: nsCommonFormsBtns })} *`} />
                <Body style={{ color: CivColors.coreGray, marginTop: 6 }}>
                  {t("field.address.helper_text_kept_private", { ns: nsCommonFormsBtns })}
                </Body>
              </Grid>
            </DashSection>
            <FormAlert schema={validationSchema} sx={{ mb: "80px" }} />
            <Box textAlign="center" sx={{ margin: "24px 16px" }}>
              <LinkStyledAsButton
                variant="outlined"
                component={handleExitEditMode ? undefined : Link}
                to={handleExitEditMode ? undefined : getCancelButtonLink()}
                onClick={handleExitEditMode ? handleExitEditMode : undefined}
              >
                {t("button.cancel", { ns: nsCommonFormsBtns })}
              </LinkStyledAsButton>
              <Button variant="contained" type="submit" sx={{ marginLeft: "30px" }} disabled={submitting}>
                {getSubmitButtonText()}
              </Button>
            </Box>
          </LongForm>
        );
      }}
    </Formik>
  );
};

export default CreateEditRepostJobForm;
