import { Box } from "@mui/material";
import { Field, Formik } from "formik";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { VerificationParameterType } from "src/generated/api_types";
import { getLocalizedVerificationResponse } from "src/i18n/Utilities";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidBEContent, nsMedicaidRegVerification } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { useRegistrationContext } from "src/pages/registration/components/RegistrationContext";
import { verifyUserCredentials } from "src/pages/registration/verification/Queries";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Constraint from "src/reusable_view_elements/Constraint";
import ErrorBox from "src/reusable_view_elements/ErrorBox";
import { FormTextField, TextField } from "src/reusable_view_elements/form_fields";
import Form from "src/reusable_view_elements/form_fields/Form";
import { InternalLink } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { scrollToId } from "src/utilities/ScrollToId";
import { object, string } from "yup";

/***********************************
 *** OR Consumer Form Components ***
 **********************************/

export interface ORVerificationFormFields {
  firstName: string;
  lastName: string;
  verificationToken: string;
  prid: string;
}

export const useVerificationSchemaConsumerOR = () => {
  const { t, ready: translationReady } = useTranslation(nsCommonFormsBtns);

  const VerificationSchemaConsumerOR = object().shape({
    firstName: string()
      .label(t("field.first_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.first_name.error.provide", { ns: nsCommonFormsBtns })),
    lastName: string()
      .label(t("field.last_name.label", { ns: nsCommonFormsBtns }))
      .required(t("field.last_name.error.provide", { ns: nsCommonFormsBtns })),
    verificationToken: string()
      .label(t("field.token_verification_code.label", { ns: nsCommonFormsBtns }))
      .required(t("field.token_verification_code.error", { ns: nsCommonFormsBtns })),
  });

  return {
    VerificationSchemaConsumerOR,
    translationReady,
  };
};

export const ORConsumerVerificationForm = () => {
  const { userInfo, getNextStepRoute, regPath, groupInviteToken: OREGON_TOKEN } = useRegistrationContext();
  const { showSnackbar } = useNotification();
  const history = useHistory();
  const [credentialsError, setCredentialsError] = useState(false);
  const { t, ready } = useTranslation([
    nsMedicaidRegVerification,
    nsCommonFormsBtns,
    nsCommonToasts,
    nsMedicaidBEContent,
  ]);
  const location = useLocation();
  const { VerificationSchemaConsumerOR, translationReady: schemaReady } = useVerificationSchemaConsumerOR();

  const initialValues: ORVerificationFormFields = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    verificationToken: OREGON_TOKEN || "",
    prid: "",
  };

  const handleSubmit = async (values: ORVerificationFormFields) => {
    const verificationData = {
      params: {
        clientFirstName: {
          type: VerificationParameterType.FIRST_NAME,
          value: values.firstName,
        },
        clientLastName: {
          type: VerificationParameterType.LAST_NAME,
          value: values.lastName,
        },
        token: {
          type: VerificationParameterType.INVITE_TOKEN,
          value: values.verificationToken,
        },
      },
    };

    try {
      const response = await verifyUserCredentials(verificationData);

      const verificationResMsg = response.data.message;

      if (verificationResMsg === "Verification succeeded") {
        history.push(
          getNextStepRoute(response.data.regStep.step, {
            funder: userInfo.funder.split("-")[1].toLowerCase(),
            role: regPath?.roleConfig?.roleType.toLowerCase() || "",
          }),
        );
      }

      if (verificationResMsg === "API deactivated or bad verification config") {
        showSnackbar(getLocalizedVerificationResponse(verificationResMsg, "error", t), "error");
      }

      if (verificationResMsg === "Verification failed" || verificationResMsg === "User is blocked") {
        setCredentialsError(true);
        scrollToId("credentials-error");
      }
    } catch (err: any) {
      setCredentialsError(true);
      scrollToId("credentials-error");
    }
  };

  if (!(ready && schemaReady)) {
    return (
      <Section bgcolor={CivColors.lightGray}>
        <LoadingCircle />
      </Section>
    );
  }

  return (
    <Section bgcolor={CivColors.lightGray} sx={{ padding: 2 }}>
      {credentialsError && (
        <Constraint columns={8}>
          <ErrorBox marginBottom={6} id="credentials-error">
            <BodyEmphasis paragraph>
              {t("we_couldnt_verify_error.label", { ns: nsMedicaidRegVerification })}
            </BodyEmphasis>
            <Body paragraph>
              {t("we_couldnt_verify_error.description.please_check", { ns: nsMedicaidRegVerification })}
            </Body>
            <Trans
              t={t}
              i18nKey="we_couldnt_verify_error.description.if_need_contact_and_call"
              ns={nsMedicaidRegVerification}
              parent={Body}
              components={{
                cntctus: (
                  <InternalLink
                    to={{ pathname: ROUTES.contactUs, state: { from: location.pathname } }}
                    target="_blank"
                  />
                ),
              }}
              values={{
                cntctus_key: t("we_couldnt_verify_error.contact_us.label", {
                  ns: nsMedicaidRegVerification,
                }),
              }}
            />
          </ErrorBox>
        </Constraint>
      )}
      <Formik initialValues={initialValues} validationSchema={VerificationSchemaConsumerOR} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <Form localizationReady={ready}>
            <Constraint columns={4}>
              <Body sx={{ marginBottom: "24px" }}>
                {t("field.marked_with_asterisk.label", { ns: nsCommonFormsBtns })}
              </Body>
              <FormTextField
                label={t("field.first_name.label", { ns: nsCommonFormsBtns })}
                name="firstName"
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                trimSpaces
                required={isRequiredField("firstName", VerificationSchemaConsumerOR)}
                sx={{ marginBottom: "24px" }}
                InputProps={{ readOnly: true }}
              />
              <Field
                as={TextField}
                label={t("field.last_name.label", { ns: nsCommonFormsBtns })}
                id="lastName"
                name="lastName"
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                trimSpaces
                required={isRequiredField("lastName", VerificationSchemaConsumerOR)}
                sx={{ marginBottom: "32px" }}
                InputProps={{ readOnly: true }}
              />

              <BodyEmphasis gutterBottom>
                {t("input_care_recipient_code.label", { ns: nsMedicaidRegVerification })}
              </BodyEmphasis>
              <Body paragraph>
                {t("input_care_recipient_code.description.check_for_an_email", {
                  ns: nsMedicaidRegVerification,
                })}
              </Body>
              <Body paragraph>
                {t("input_care_recipient_code.description.if_you_cannot_find", {
                  ns: nsMedicaidRegVerification,
                })}
              </Body>
              <Field
                as={TextField}
                label={t("field.token_verification_code.label", { ns: nsCommonFormsBtns })}
                id="verificationToken"
                name="verificationToken"
                error={Boolean(touched.verificationToken && errors.verificationToken)}
                helperText={touched.verificationToken && errors.verificationToken}
                trimSpaces
                required={isRequiredField("verificationToken", VerificationSchemaConsumerOR)}
              />
            </Constraint>

            <Constraint columns={6}>
              <Box mt={8} textAlign="center">
                <FormAlert schema={VerificationSchemaConsumerOR} sx={{ mt: 2, mb: 2, textAlign: "left" }} />
                <Button variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting
                    ? t("button.verifying", { ns: nsCommonFormsBtns })
                    : t("button.verify", { ns: nsCommonFormsBtns })}
                </Button>
              </Box>
            </Constraint>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
