import { ArrowDropDown, EditOutlined, MailOutline } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  alpha,
  Box,
  Drawer,
  DrawerProps,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip as MuiTooltip,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { CloseSidePanelIcon } from "src/assets/icons/StreamlineIcons";
import { useNotification } from "src/context/NotificationContext";
import { JobListItemDTO, JobPostListDTO, JobStatusEnum } from "src/generated/api_types";
import { nsCommonAria, nsCommonToasts, nsMedicaidProviderProfile, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import CreateEditRepostJobContent from "src/pages/medicaidAccount/consumerAccount/components/CreateEditRepostJobContent";
import { JobFormType } from "src/pages/medicaidAccount/consumerAccount/components/CreateEditRepostJobForm";
import { ChangeJobStatusMenu, JobModalEnum } from "src/pages/medicaidAccount/coordinatorAccount/AssistiveJobsDashboard";
import {
  getJobStatusLabel,
  StatusIcon,
} from "src/pages/medicaidAccount/coordinatorAccount/components/JobCountsByStatusBoxes";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import JobPostDetailsContent from "src/pages/medicaidAccount/sharedComponents/JobPostDetailsContent";
import { getJobPostInfo } from "src/pages/medicaidAccount/sharedComponents/Queries";
import TextField from "src/reusable_view_elements/form_fields/TextField";
import { Snackbar } from "src/reusable_view_elements/Snackbar";
import CivColors from "src/themes/civilization/CivColors";
import CivTheme from "src/themes/civilization/CivTheme";
import theme from "src/themes/civilization/CivTheme";

interface JobDetailsDrawerProps extends Omit<DrawerProps, "onClose"> {
  onClose: () => void;
  updateRowFn: (updatedJob: JobListItemDTO) => void;
}

const CoordinatorJobDrawer = (props: JobDetailsDrawerProps) => {
  const { selectedRowJob, activeJobModalTypes, setActiveJobModalTypes } = useMedicaidContext();
  const { useMainSnackbar, showSnackbar } = useNotification();
  const [drawerContextJob, setDrawerContextJob] = useState<JobPostListDTO>();
  const [jobStatusMenuAnchor, setJobStatusMenuAnchor] = useState<null | HTMLElement>(null);
  const onDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const { t, ready } = useTranslation([nsMedicaidProviderProfile, nsCommonAria, nsCommonToasts, nsMedicaidTagsEnums]);

  const isDrawerEditView = activeJobModalTypes.includes(JobModalEnum.SIDE_PANEL_EDIT);

  useEffect(() => {
    (async () => {
      if (isDrawerEditView && selectedRowJob) {
        try {
          const resJobPost = await getJobPostInfo(selectedRowJob.id);
          setDrawerContextJob(resJobPost.data);
        } catch (error) {
          showSnackbar(
            t("error.loading_your_job", "Sorry, there was a problem loading your job. Please try again later.", {
              ns: nsCommonToasts,
            }),
            "error",
          );
        }
      }
    })();
    return () => setDrawerContextJob(undefined);
  }, [props.open]);

  if (!selectedRowJob) return <></>;

  const jobDetailsPath = generatePath(Medicaid_Coordinator_Dashboard_Routes.myJobDetails, {
    id: selectedRowJob.id,
  });

  const inboxPath = generatePath(MEDICAID_INBOX_ROUTES.allThreadsWithPresetSearch, {
    presetInboxSearchQuery: selectedRowJob.jobNumber,
  });

  const EditJobButton = (
    <MuiTooltip title={t("icon.edit_job", { ns: nsCommonAria })} enterTouchDelay={0} leaveTouchDelay={8000}>
      <IconButton
        size="large"
        onClick={() => setActiveJobModalTypes([...activeJobModalTypes, JobModalEnum.SIDE_PANEL_EDIT])}
        disabled={selectedRowJob.jobStatus === JobStatusEnum.EXPIRED}
      >
        <EditOutlined />
      </IconButton>
    </MuiTooltip>
  );

  const GoToThreadButton = (
    <MuiTooltip title={t("icon.go_to_thread", { ns: nsCommonAria })} enterTouchDelay={0} leaveTouchDelay={8000}>
      <IconButton size="large" component={RouterLink} to={inboxPath} target="_blank">
        <MailOutline />
      </IconButton>
    </MuiTooltip>
  );

  const GoToJobDetailsButton = (
    <MuiTooltip title={t("icon.view_job", { ns: nsCommonAria })} enterTouchDelay={0} leaveTouchDelay={8000}>
      <IconButton size="large" component={RouterLink} to={jobDetailsPath} target="_blank">
        <LaunchIcon />
      </IconButton>
    </MuiTooltip>
  );

  const CloseButton = (
    <MuiTooltip title={t("icon.close", { ns: nsCommonAria })} enterTouchDelay={0} leaveTouchDelay={8000}>
      <IconButton
        onClick={props.onClose}
        size="large"
        aria-label={t("icon.close", { ns: nsCommonAria })}
        sx={{ "& .MuiSvgIcon-root": { width: "20px", height: "20px", path: { strokeWidth: "2.5px" } } }}
      >
        <CloseSidePanelIcon />
      </IconButton>
    </MuiTooltip>
  );

  return (
    <Drawer
      open={props.open}
      onClose={() => !isDrawerEditView && props.onClose()}
      anchor={onDesktop ? "right" : "bottom"}
    >
      <Snackbar isEnabled={!useMainSnackbar} />
      {/* Header */}
      {ready && (
        <Box
          borderBottom={`1px solid ${alpha(CivColors.mediumGray, 0.8)}`}
          padding="18px"
          position="sticky"
          sx={{ width: "100%", background: CivColors.white, top: 0 }}
          zIndex={CivTheme.zIndex.drawer + 1}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <TextField
                name="jobStatus"
                value={getJobStatusLabel(selectedRowJob.jobStatus, t)}
                tabIndex={-1}
                onClick={(event) => {
                  if (selectedRowJob.jobStatus !== JobStatusEnum.CLOSED) {
                    setJobStatusMenuAnchor(event.currentTarget);
                    setActiveJobModalTypes([...activeJobModalTypes, JobModalEnum.SIDE_PANEL_STATUS_MENU]);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ opacity: isDrawerEditView ? 0.6 : 1 }}>
                      <StatusIcon status={selectedRowJob.jobStatus} />
                    </InputAdornment>
                  ),
                  endAdornment: selectedRowJob.jobStatus !== JobStatusEnum.CLOSED && (
                    <InputAdornment position="end">
                      <IconButton disabled={isDrawerEditView}>
                        <ArrowDropDown />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  tabIndex: -1,
                  sx: { cursor: isDrawerEditView ? undefined : "pointer" },
                  readOnly: true,
                }}
                sx={{ width: "250px", "& .MuiInputBase-root": { cursor: isDrawerEditView ? undefined : "pointer" } }}
                disabled={isDrawerEditView}
              />
            </Grid>
            {selectedRowJob.jobStatus && (
              <ChangeJobStatusMenu
                jobStatusMenuAnchor={jobStatusMenuAnchor}
                updateRowFn={props.updateRowFn}
                open={activeJobModalTypes.includes(JobModalEnum.SIDE_PANEL_STATUS_MENU)}
                onClose={() =>
                  setActiveJobModalTypes(
                    activeJobModalTypes.filter((type) => type !== JobModalEnum.SIDE_PANEL_STATUS_MENU),
                  )
                }
              />
            )}
            {!isDrawerEditView &&
              (onDesktop ? (
                <Grid item>
                  {selectedRowJob.jobStatus !== JobStatusEnum.CLOSED && EditJobButton}
                  {GoToThreadButton}
                  {GoToJobDetailsButton}
                  {CloseButton}
                </Grid>
              ) : (
                CloseButton
              ))}
          </Grid>

          {!onDesktop && !isDrawerEditView && (
            <Grid container alignItems="center" justifyContent="center" spacing={4}>
              <Grid item>{GoToJobDetailsButton}</Grid>
            </Grid>
          )}
        </Box>
      )}

      <Box pl={onDesktop ? "84px" : "8px"} pr={onDesktop ? "84px" : "8px"}>
        {/* Job Details content */}
        {!isDrawerEditView && (
          <JobPostDetailsContent
            jobPostId={selectedRowJob.id}
            showNotes
            hideButtons
            onJobDetailsLoad={(loadedJob) => setDrawerContextJob(loadedJob)}
          />
        )}

        {/* Job Edit content */}
        {isDrawerEditView && (
          <CreateEditRepostJobContent
            formType={JobFormType.JOB_EDIT}
            stackContent
            bgcolor="inherit"
            handleExitEditMode={(updatedJob) => {
              updatedJob && props.updateRowFn(updatedJob);
              setActiveJobModalTypes(activeJobModalTypes.filter((type) => type !== JobModalEnum.SIDE_PANEL_EDIT));
            }}
            jobPreFill={drawerContextJob}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default CoordinatorJobDrawer;
