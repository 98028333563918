import { useState } from "react";
import {
  JobCountsDTO,
  JobListItemDTO,
  JobPostListDTO,
  JobPostSearchDTO,
  JobPostSort,
  NotificationFrequency,
  ProviderListDTO,
  ProviderProfileSearchDTO,
  ProviderProfileSort,
  ProviderProfileTagEnum,
} from "src/generated/api_types";
import { JobModalEnum } from "src/pages/medicaidAccount/coordinatorAccount/AssistiveJobsDashboard";
import { FirstMessageDialogProps } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import { ViewEnvOptionsBannerEnum } from "src/pages/medicaidAccount/sharedComponents/NewEnvOptionsBanner";
import { ViewBannerControlEnum } from "src/pages/medicaidAccount/sharedComponents/SettingsBanner";
import { ViewAPIResBannerControlEnum } from "src/reusable_view_elements/form_fields/ApiResponseBanner";
import { zipRegExp } from "src/utilities/GeneralUtilities";
import { createContainer } from "unstated-next";

function medicaidContext() {
  // Tracking the list of provider or job post IDs is needed to support the "Next" button on detail pages
  const [providerIdList, setProviderIdList] = useState<string[]>([]);

  // Tracking list of job post IDs is needed to support the "Next" button on detail pages
  // These are also state variables for My Jobs page
  const [jobIdList, setJobIdList] = useState<string[]>([]);
  const [displayedPageNumber, setDisplayedPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  // Reusing the message is a requested feature for sending the first message to a consumer or provider
  const [lastMessageSent, setLastMessageSent] = useState<string>("");

  // Drives render logic in JobDetails page component
  const [jobPostListOrigin, setJobPostListOrigin] = useState<JobListOrigin>(JobListOrigin.FIND_JOBS);

  // Drives render logic in ProviderProfileDetails page component
  const [providerListOrigin, setProviderListOrigin] = useState<ProviderListOrigin>(ProviderListOrigin.FIND_PROVIDERS);

  // Drives logic to show/not show the ApiResponseBanner after a user report has been submitted
  const [reportResponseView, setReportResponseView] = useState<ViewAPIResBannerControlEnum>(
    ViewAPIResBannerControlEnum.HIDDEN,
  );

  // the next two state variables drive logic to show/not show the settings banner
  const [settingsBannerView, setSettingsBannerView] = useState<ViewBannerControlEnum>();
  const [showSettingsBanner, setShowSettingsBanner] = useState<boolean>(false);

  // Drives logic to show/not show the new environment general options banner
  const [newEnvOptionsBannerView, setNewEnvOptionsBannerView] = useState<ViewEnvOptionsBannerEnum>();

  // Used to seed job post form initial values when user is Coordinator
  const [consumerFirstNameFromCoordinator, setConsumerFirstNameFromCoordinator] = useState<string>();

  // Used for send message dialog from job and provider summary cards
  type MessageDialogContextState = Omit<FirstMessageDialogProps, "isDialogOpen" | "onCloseDialog">;
  const [sendMsgDialogProps, setSendMsgDialogProps] = useState<MessageDialogContextState>();

  /************************ States for FIND JOBS Advanced Filters *******************************/

  // Contains the list of jobs 'searched and/or filtered'
  const [jobsList, setJobsList] = useState<JobPostListDTO[]>([]);

  // This is the total count of results, which may or may not be larger than page size
  const [totalJobCount, setTotalJobCount] = useState(0);

  // This is the total count of result pages
  const [totalJobPages, setTotalJobPages] = useState(0);

  // Indicates loading of next page is in progress
  const [loadingNextPage, setIsLoadingNextPage] = useState(false);

  const emptyJobFilterObject = {
    address: "",
    distanceMinutes: 60,
    minHours: undefined,
    maxHours: undefined,
    tagGroups: [],
    excludedTags: [],
    sort: JobPostSort.DISTANCE,
    pageNumber: 0,
  };

  // Contains the filter selection used in the end point request
  const [jobSearchDTO, setJobSearchDTO] = useState<JobPostSearchDTO>(emptyJobFilterObject);

  const zipForGAEvent = () => {
    // this address comes back as a string "......, zip, United States"
    const zip = jobSearchDTO.address.slice(-20, -15);
    return zip.match(zipRegExp) ? zip : "";
  };

  // Controls rendering of list content as the data is fetched from server
  const [isLoadingJobs, setLoadingJobs] = useState(false);

  // Keeps track of the dirty state of the advanced filters
  const [isJobFilterDirty, setIsJobFilterDirty] = useState(false);

  // Marks the initial search of the users address. Used for debouncing all searches except the initial
  const [isInitialJobsSearch, setIsInitialJobsSearch] = useState(true);

  // Drives reset of provider filters
  const [clearJobFilters, setClearJobFilters] = useState(false);

  // Contains state of job filter notif checkbox and drives persistence of job filters to db
  const [jobFltrWkNotifEnabled, enableJobFltrWkNotif] = useState(false);

  // Dynamically changes the copy on the filter notification checkbox, i.e. "Email me [daily/weekly/monthly]..."
  const [jobFilterNotificationFrequency, setJobFilterNotificationFrequency] = useState<NotificationFrequency>(
    NotificationFrequency.DAILY,
  );
  const [loadingJobFilterNotification, setLoadingJobFilterNotification] = useState<boolean>(false);

  // Page number of the request
  const [jobsPage, setJobsPage] = useState(0);

  // Keeps track of infinite scrolling trigger
  const [nextJobPageWasCalled, setNextJobPageWasCalled] = useState(false);

  // Keeps track of currently visited job. It's used to know where to scroll to, upon returning to list page
  const [visitedJobIndex, setVisitedJobIndex] = useState(-1);

  /************************ States for FIND PROVIDERS Advanced Filters *******************************/

  // Contains the list of providers 'searched and/or filtered'
  const [providerProfileList, setProviderProfileList] = useState<ProviderListDTO[]>([]);

  // This is the total count of results, which may or may not be larger than page size
  const [totalProviderCount, setTotalProviderCount] = useState(0);

  // This is the total count of result pages
  const [totalProviderPages, setTotalProviderPages] = useState(0);

  // Indicates loading of next page is in progress
  const [loadingNextProvidersPage, setIsLoadingNextProvidersPage] = useState(false);

  const emptyProviderFilterObject = {
    address: "",
    distanceMinutes: 60,
    minHours: undefined,
    maxHours: undefined,
    tagGroups: [],
    excludedTags: [],
    sort: ProviderProfileSort.DISTANCE,
    pageNumber: 0,
    availability: [ProviderProfileTagEnum.SEEKING, ProviderProfileTagEnum.SOON],
  };

  // Contains the filter selection used in the end point request
  const [providerSearchDTO, setProviderSearchDTO] = useState<ProviderProfileSearchDTO>(emptyProviderFilterObject);

  // Controls rendering of list content as the data is fetched from server
  const [isLoadingProviders, setLoadingProviders] = useState(false);

  // Keeps track of the dirty state of the advanced filters
  const [isProvFilterDirty, setIsProvFilterDirty] = useState(false);

  // Marks the initial search of the users address. Used for debouncing all searches except the initial
  const [isInitialProvidersSearch, setIsInitialProvidersSearch] = useState(true);

  // Drives reset of provider filters
  const [clearProviderFilters, setClearProviderFilters] = useState(false);

  // Contains state of provider filter notif checkbox and drives persistence of provider filters to db
  const [providerFltrWkNotifEnabled, enableProviderFltrWkNotif] = useState(false);

  // Dynamically changes the copy on the filter notification checkbox, i.e. "Email me [daily/weekly/monthly]..."
  const [providerFilterNotificationFrequency, setProviderFilterNotificationFrequency] = useState<NotificationFrequency>(
    NotificationFrequency.DAILY,
  );
  const [loadingProviderFilterNotification, setLoadingProviderFilterNotification] = useState<boolean>(false);

  // Page number of the request
  const [providersPage, setProvidersPage] = useState(0);

  // Keeps track of infinite scrolling trigger
  const [nextProviderPageWasCalled, setNextProviderPageWasCalled] = useState(false);

  // Keeps track of currently visited job. It's used to know where to scroll to, upon returning to list page
  const [visitedProviderIndex, setVisitedProviderIndex] = useState(-1);

  /************************ States for Assistive Role Job Dashboard *****************************/

  // States to keep track of job selected on the Assistive Role Job Dashboard and the type of interactive modal to show
  const [selectedRowJob, setSelectedRowJob] = useState<JobListItemDTO>();
  const [activeJobModalTypes, setActiveJobModalTypes] = useState<JobModalEnum[]>([]);

  // State to update job status counters on the Assistive Role Job Dashboard w/o refreshing
  const [editedJobStatusCounter, setEditedJobStatusCounter] = useState<JobCountsDTO>();

  /*********************************************** *************************************************/
  return {
    providerIdList,
    setProviderIdList,
    jobIdList,
    setJobIdList,
    displayedPageNumber,
    setDisplayedPageNumber,
    totalPages,
    setTotalPages,
    lastMessageSent,
    setLastMessageSent,
    jobPostListOrigin,
    setJobPostListOrigin,
    providerListOrigin,
    setProviderListOrigin,
    reportResponseView,
    setReportResponseView,
    settingsBannerView,
    setSettingsBannerView,
    showSettingsBanner,
    setShowSettingsBanner,
    newEnvOptionsBannerView,
    setNewEnvOptionsBannerView,
    consumerFirstNameFromCoordinator,
    setConsumerFirstNameFromCoordinator,
    sendMsgDialogProps,
    setSendMsgDialogProps,
    //--- states for job filters:
    jobsList,
    setJobsList,
    jobSearchDTO,
    zipForGAEvent,
    setJobSearchDTO,
    isLoadingJobs,
    setLoadingJobs,
    isJobFilterDirty,
    setIsJobFilterDirty,
    isInitialJobsSearch,
    setIsInitialJobsSearch,
    totalJobCount,
    setTotalJobCount,
    clearJobFilters,
    setClearJobFilters,
    jobFltrWkNotifEnabled,
    enableJobFltrWkNotif,
    jobFilterNotificationFrequency,
    setJobFilterNotificationFrequency,
    loadingJobFilterNotification,
    setLoadingJobFilterNotification,
    jobsPage,
    setJobsPage,
    totalJobPages,
    setTotalJobPages,
    loadingNextPage,
    setIsLoadingNextPage,
    nextJobPageWasCalled,
    setNextJobPageWasCalled,
    visitedJobIndex,
    setVisitedJobIndex,
    //--- states for provider filters:
    providerProfileList,
    setProviderProfileList,
    providerSearchDTO,
    setProviderSearchDTO,
    isLoadingProviders,
    setLoadingProviders,
    isProvFilterDirty,
    setIsProvFilterDirty,
    isInitialProvidersSearch,
    setIsInitialProvidersSearch,
    totalProviderCount,
    setTotalProviderCount,
    clearProviderFilters,
    setClearProviderFilters,
    providerFltrWkNotifEnabled,
    enableProviderFltrWkNotif,
    providerFilterNotificationFrequency,
    setProviderFilterNotificationFrequency,
    loadingProviderFilterNotification,
    setLoadingProviderFilterNotification,
    providersPage,
    setProvidersPage,
    totalProviderPages,
    setTotalProviderPages,
    loadingNextProvidersPage,
    setIsLoadingNextProvidersPage,
    emptyProviderFilterObject,
    emptyJobFilterObject,
    nextProviderPageWasCalled,
    setNextProviderPageWasCalled,
    visitedProviderIndex,
    setVisitedProviderIndex,
    //--- states for Assistive Role Job Dashboard:
    selectedRowJob,
    setSelectedRowJob,
    activeJobModalTypes,
    setActiveJobModalTypes,
    editedJobStatusCounter,
    setEditedJobStatusCounter,
  };
}

const MedicaidContext = createContainer(medicaidContext);

export const MedicaidContextProvider: React.FC = ({ children }) => (
  <MedicaidContext.Provider>{children}</MedicaidContext.Provider>
);

export const useMedicaidContext = () => MedicaidContext.useContainer();

export enum JobListOrigin {
  DELETED,
  MY_JOBS,
  FIND_JOBS,
  INBOX,
}

export enum ProviderListOrigin {
  FIND_PROVIDERS,
  INBOX,
}

// Given an id, return the next id in the list
export function getNextId(currentId: string, list: string[]): string {
  if (list.length === 0) {
    return "";
  }
  const currentIndex = list.indexOf(currentId);
  if (currentIndex === -1) {
    return list[0];
  }
  const nextIndex = currentIndex + 1 === list.length ? 0 : list.indexOf(currentId) + 1;
  return list[nextIndex];
}
