import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { useUserSession } from "src/context/UserSessionContext";
import { nsCommonFormsBtns, nsCommonLogin, nsCommonToasts } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { Button } from "src/reusable_view_elements/Button";
import { useStateIfMounted } from "use-state-if-mounted";
import { Body, BodyTitle } from "src/reusable_view_elements/Typography";
import { useTranslation } from "react-i18next";

export const AutoLogoutModal: React.FC = () => {
  const { showLogoutModal, loading, reloadAuthState } = useUserSession();
  const { showSnackbar } = useNotification();
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useStateIfMounted<number | undefined>(undefined);
  const history = useHistory();
  const { t, ready } = useTranslation([nsCommonLogin, nsCommonFormsBtns, nsCommonToasts]);

  // Set initial time left before auto-logout (default: 5 minutes)
  useEffect(() => {
    if (showLogoutModal && !loading) {
      setTimeLeftInSeconds(5 * 60);
    }
  }, [showLogoutModal, loading]);

  // Countdown time left
  useEffect(() => {
    if (showLogoutModal) {
      if (timeLeftInSeconds!! > 0) {
        setTimeout(() => {
          setTimeLeftInSeconds(timeLeftInSeconds!! - 1);
        }, 1000);
      }
    } else {
      setTimeLeftInSeconds(undefined);
    }
  }, [showLogoutModal, timeLeftInSeconds]);

  if (!showLogoutModal || timeLeftInSeconds === undefined) return <></>;
  if (timeLeftInSeconds === 0) {
    showSnackbar(t("error.logged_out_due_to_inactivity", { ns: nsCommonToasts }), "info", false);
    return <Redirect to={ROUTES.logout} />;
  }

  const minutes = Math.floor(timeLeftInSeconds / 60);
  const seconds = timeLeftInSeconds > 60 ? timeLeftInSeconds % 60 : timeLeftInSeconds;

  const descriptionTimeLeft =
    timeLeftInSeconds >= 60
      ? t("session_about_to_time_out.in_minutes", {
          ns: nsCommonLogin,
          minutes: t("count_minutes.label", { ns: nsCommonLogin, count: minutes }),
          seconds: t("count_seconds.label", { ns: nsCommonLogin, count: seconds }),
        })
      : t("session_about_to_time_out.in_seconds", {
          ns: nsCommonLogin,
          seconds: t("count_seconds.label", { ns: nsCommonLogin, count: seconds }),
        });

  return (
    <Dialog
      open={showLogoutModal}
      maxWidth="sm"
      PaperProps={{
        style: { padding: "8px 0px 16px 0px" },
      }}
    >
      <DialogTitle>
        <BodyTitle>{ready && t("session_about_to_time_out.label", { ns: nsCommonLogin })}</BodyTitle>
      </DialogTitle>
      <DialogContent>
        <Body>{ready && descriptionTimeLeft}</Body>

        {ready && (
          <Grid style={{ textAlign: "right" }}>
            <Box p={2} />
            <Button variant="text" type="button" onClick={() => reloadAuthState(false, true)}>
              {t("button.yes_stay_logged_in", { ns: nsCommonFormsBtns })}
            </Button>
            <Button variant="contained" type="button" onClick={() => history.push(ROUTES.logout)}>
              {t("button.no_log_out", { ns: nsCommonFormsBtns })}
            </Button>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
