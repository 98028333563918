import posthog from "posthog-js";
import { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { HealthCheckService } from "src/api/service/health/HealthCheckService";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { AxiosUsersImpersonationControllerClient, User_AccountStatus } from "src/generated/api_types";
import { DASHBOARD_ROUTES } from "src/pages/agencyAdmin/dashboard/DashboardRouter";
import { getInviteByToken } from "src/pages/agencyAdmin/GetInviteByToken";
import { AuthenticatedComponent } from "src/pages/auth/AuthenticatedComponent";
import AuthenticatedRoute from "src/pages/auth/AuthenticatedRoute";
import { postLogin, sendLogout } from "src/pages/auth/AuthRequests";
import { ForgotPassword } from "src/pages/auth/ForgotPassword";
import { Login } from "src/pages/auth/Login";
import { LoginResult } from "src/pages/auth/LoginResult";
import { Logout } from "src/pages/auth/Logout";
import { PasswordReset } from "src/pages/auth/PasswordReset";
import { postPasswordReset } from "src/pages/auth/PostPasswordReset";
import { getTokenData, requestPasswordReset } from "src/pages/auth/Queries";
import { ShortlinkHandler } from "src/pages/auth/ShortlinkHandler";
import { UnauthenticatedRoute } from "src/pages/auth/UnauthenticatedRoute";
import { CARINA_ADMIN_DASHBOARD_ROUTES } from "src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter";
import CarinaAdminMgmtRouter, {
  CARINA_ADMIN_MGMT_ROUTES,
} from "src/pages/carinaAdmin/management/CarinaAdminMgmtRouter";
import { CHILD_CARE_ROUTES } from "src/pages/childcare/ChildCareRouter";
import { UpdateCCAvailability } from "src/pages/childcare/providerProfile/UpdateCCAvailability";
import { FUNDER_ADMIN_DASHBOARD_ROUTES } from "src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter";
import Home from "src/pages/home/Home";
import LoadingPage from "src/pages/LoadingPage";
import { DeleteJobFromNotification } from "src/pages/medicaidAccount/consumerAccount/DeleteJobFromNotification";
import { DeleteJobFromNotifConfirmation } from "src/pages/medicaidAccount/consumerAccount/jobDeletionConfirmation/DeleteJobFromNotifConfirmation";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { UpdateAvailability } from "src/pages/medicaidAccount/providerAccount/UpdateAvailability";
import { ONBOARDING_ROUTES } from "src/pages/onboarding/components/OnboardingRouter";
import { REGISTRATION_ROUTES } from "src/pages/registration/components/RegistrationRouter";
import { SUPPORT_ADMIN_DASHBOARD_ROUTES } from "src/pages/supportAdminAccount/SupportAdminDashboardRouter";
import { AutoLogoutModal } from "src/reusable_view_elements/AutoLogoutModal";
import DevBanner from "src/reusable_view_elements/DevBanner";
import ImpersonationBanner from "src/reusable_view_elements/ImpersonationBanner";
import StaticDown from "src/StaticDown";
import ScrollToTop from "src/utilities/ScrollToTop";
import { useOnce } from "src/utilities/useOnce";
import ContactUs from "./pages/contactUs/ContactUs";
import NotFound from "./pages/NotFound";
import { Snackbar } from "./reusable_view_elements/Snackbar";

const GeoTest = lazy(() => import("src/pages/GeoTest"));
const AboutCarina = lazy(() => import("src/pages/aboutCarina/AboutCarina"));
const AgencyAdminRegistration = lazy(() => import("src/pages/agencyAdmin/Registration"));
const Blog = lazy(() => import("src/pages/blog/Blog"));
const SearchAgencyProviders = lazy(
  () => import("src/pages/findHomeCare/findPrivatePay/searchExperience/SearchAgencyProviders"),
);
const HomeCareOptions = lazy(() => import("src/pages/findHomeCare/HomeCareOptions"));
const DCHSLandingPage = lazy(() => import("src/pages/findHomeCare/findPrivatePay/DCHSLandingPage"));
const PPHCGeneralLandingPage = lazy(() => import("src/pages/findHomeCare/findPrivatePay/PPHCGeneralLandingPage"));
const MessageAgency = lazy(() => import("src/pages/findHomeCare/findPrivatePay/searchExperience/MessageAgency"));
const MessageAgencyConfirmation = lazy(
  () => import("src/pages/findHomeCare/findPrivatePay/searchExperience/MessageAgencyConfirmation"),
);
const PrivatePayHomeCareWaitlistSignup = lazy(
  () => import("src/pages/findHomeCare/findPrivatePay/searchExperience/WaitlistSignup"),
);
const PrivatePayHomeCareWaitlistConfirmation = lazy(
  () => import("src/pages/findHomeCare/findPrivatePay/searchExperience/WaitlistConfirmation"),
);
const PrivacyPolicy = lazy(() => import("src/pages/Privacy & TOS/PrivacyPolicy"));
const TermsOfService = lazy(() => import("src/pages/Privacy & TOS/TermsOfService"));
const MedicaidORClient = lazy(() => import("src/pages/findHomeCare/findMedicaid/MedicaidORClient"));
const MedicaidORProvider = lazy(() => import("src/pages/providers/MedicaidORProvider"));
const MedicaidWAClient = lazy(() => import("src/pages/findHomeCare/findMedicaid/MedicaidWAClient"));
const MedicaidWAProvider = lazy(() => import("src/pages/providers/MedicaidWAProvider"));
const MedicaidWAPreviewJobs = lazy(() => import("src/pages/medicaidAccount/providerAccount/JobsPreview"));
const MedicaidProvidersPreview = lazy(
  () => import("src/pages/medicaidAccount/consumerAccount/ProviderProfileListPreview"),
);
const ProvideCare = lazy(() => import("src/pages/providers/ProvideCare"));
const WhoIsWho = lazy(() => import("src/pages/who_is_who/WhoIsWho"));
const WhoIsWhoOR = lazy(() => import("src/pages/who_is_who/WhoIsWhoOR"));
const CarinaAdminDashboardRouter = lazy(() => import("src/pages/carinaAdmin/dashboard/CarinaAdminDashboardRouter"));
const FunderAdminDashboardRouter = lazy(() => import("src/pages/funderAdmin/dashboard/FunderAdminDashboardRouter"));

const SupportAdminDashboardRouter = lazy(() => import("src/pages/supportAdminAccount/SupportAdminDashboardRouter"));

const DashboardRouter = lazy(() => import("src/pages/agencyAdmin/dashboard/DashboardRouter"));
const RegistrationRouter = lazy(() => import("src/pages/registration/components/RegistrationRouter"));
const OnboardingRouter = lazy(() => import("src/pages/onboarding/components/OnboardingRouter"));
const MedicaidAccountRouter = lazy(() => import("src/pages/medicaidAccount/MedicaidAccountRouter"));
const ChildCareRouter = lazy(() => import("src/pages/childcare/ChildCareRouter"));

export const ROUTES = {
  root: "/",
  login: "/login",
  logout: "/homecare/logout",
  loginSuccess: "/homecare/auth/login_result",
  forgotPassword: "/forgotpassword",
  passwordReset: "/passwordreset",
  emailChange: "/changeEmail/:token",
  agencyAdminRegistration: "/homecare/agency/registration",
  homeCareOptions: "/homecare-options",
  privatePayHomeCare: "/homecare",
  privatePayHomeCareSearchByZip: "/homecare/privatepay/search",
  privatePayHomeCareNeeds: "/homecare/privatepay/careneeds",
  privatePayHomeCarePivotConfirmation: "/homecare/message-confirmation",
  privatePayHomeCareWaitlist: "/homecare/privatepay/waitlist",
  privatePayHomeCareWaitlistConfirmation: "/homecare/privatepay/waitlist/confirmation",
  dchs: "/homecare/dchs",
  providers: "/providecare",
  aboutCarina: "/about",
  blog: "/blog",
  privacyPolicy: "/privacy",
  termsOfService: "/terms",
  medicaidORClient: "/homecare/medicaid/oregon/:token?",
  medicaidORProvider: "/providecare/medicaid/oregon",
  medicaidWAProvider: "/providecare/medicaid/washington",
  medicaidWAClient: "/homecare/medicaid/washington",
  medicaidWANewJobsPreview: "/homecare/medicaid/previewjobs/:id",
  medicaidProvidersPreview: "/homecare/medicaid/previewproviders/:id",
  updateAvailability: "/homecare/medicaid/profile/availability/:id/:status",
  updateCCAvailability: "/childcare/profile/availability/:id/:status",
  deleteJobFromNotification: "/homecare/medicaid/consumer/jobs/:jobid/user/:userid/delete",
  deleteJobFromNotifConfirmation: "/homecare/medicaid/consumer/jobs/:jobid/user/:userid/deleteConfirmation",
  whosWho: "/homecare/medicaid/whoswho/washington",
  whosWhoOR: "/homecare/medicaid/whoswho/oregon",
  contactUs: "/contactus",
  shortlink: "/sl/:base62id",
  geoTest: "/geo-test",
};

// TODO: Can we remove these? Are emails and marketing pointing to the correct places?
export const LEGACY_ROUTES = {
  PPHCForm: "/homecare/connect-for-care",
  PPHCLogin: "/homecare/login",
};

const impersonateClient = new AxiosUsersImpersonationControllerClient();

export const MainRouter = () => {
  const { isInEnv, loading: loadingConfig } = useConfig();
  const { loading: loadingUserSession, isImpersonated, reloadAuthState, userSession } = useUserSession();
  const history = useHistory();
  const [loadingDB, setLoadingDB] = useState(true);
  const [dbHealthResponse, setDbHealthResponse] = useState(0);
  const { isUserType } = useUserSession();
  const { useMainSnackbar } = useNotification();
  const location = useLocation();

  useOnce(() => {
    new HealthCheckService()
      .healthCheck()
      .then((res: boolean) => {
        if (res) {
          setDbHealthResponse(200);
        } else {
          setDbHealthResponse(500);
        }
        setLoadingDB(false);
      })
      .catch((e) => {
        console.log("Healthcheck failed", e);
        setLoadingDB(false);
        setDbHealthResponse(500);
      });
  });

  useEffect(() => {
    if (posthog) {
      posthog.capture("$pageview");
    }
  }, [location]);

  if (loadingConfig || loadingUserSession) return <></>;
  if (!loadingDB && dbHealthResponse !== 200) return <StaticDown />;

  return (
    <>
      <Snackbar isEnabled={useMainSnackbar} />
      <ScrollToTop />
      <AuthenticatedComponent component={AutoLogoutModal} />
      {!isInEnv(EnvName.PROD) && <DevBanner />}
      {isImpersonated() && (
        <ImpersonationBanner
          name={`${userSession?.firstName} ${userSession?.lastName}`}
          onStop={async () => {
            try {
              await impersonateClient.getImpersonateExit();
              reloadAuthState();
              if (!loadingUserSession) {
                history.push(CARINA_ADMIN_DASHBOARD_ROUTES.agency_list);
              }
            } catch (e) {
              // window.alert("Error: Could not end impersonation");
            }
          }}
        />
      )}
      <Suspense fallback={<LoadingPage showNav />}>
        <Switch>
          {/* These routes need to stay accessible for both public and logged-in users */}
          <Route exact path={ROUTES.shortlink} component={ShortlinkHandler} />
          <Route exact path={ROUTES.contactUs} component={ContactUs} />
          <Route exact path={ROUTES.privacyPolicy} component={PrivacyPolicy} />
          <Route exact path={ROUTES.termsOfService} component={TermsOfService} />
          <Route exact path={ROUTES.aboutCarina} component={AboutCarina} />
          <Route exact path={ROUTES.blog} component={Blog} />

          <UnauthenticatedRoute exact path={ROUTES.geoTest} component={GeoTest} />
          <UnauthenticatedRoute exact path={ROUTES.medicaidORClient} component={MedicaidORClient} />
          <UnauthenticatedRoute exact path={ROUTES.medicaidORProvider} component={MedicaidORProvider} />
          <UnauthenticatedRoute exact path={ROUTES.root} component={Home} />
          <UnauthenticatedRoute exact path={ROUTES.homeCareOptions} component={HomeCareOptions} />
          <UnauthenticatedRoute exact path={ROUTES.medicaidWAClient} component={MedicaidWAClient} />
          <UnauthenticatedRoute exact path={ROUTES.medicaidWAProvider} component={MedicaidWAProvider} />
          <UnauthenticatedRoute exact path={ROUTES.medicaidWANewJobsPreview} component={MedicaidWAPreviewJobs} />
          <UnauthenticatedRoute exact path={ROUTES.medicaidProvidersPreview} component={MedicaidProvidersPreview} />
          <UnauthenticatedRoute exact path={ROUTES.updateAvailability} component={UpdateAvailability}>
            <UpdateAvailability />
          </UnauthenticatedRoute>

          {/* These need to stay in this order because the router begins with the same URL slug as the Update route */}
          <UnauthenticatedRoute exact path={ROUTES.updateCCAvailability} component={UpdateCCAvailability} />

          <UnauthenticatedRoute exact path={ROUTES.deleteJobFromNotification} component={DeleteJobFromNotification} />
          <UnauthenticatedRoute
            exact
            path={ROUTES.deleteJobFromNotifConfirmation}
            component={DeleteJobFromNotifConfirmation}
          />

          <Route path={CHILD_CARE_ROUTES.root} component={ChildCareRouter} />

          <UnauthenticatedRoute exact path={ROUTES.providers} component={ProvideCare} />
          <UnauthenticatedRoute exact path={ROUTES.whosWho} component={WhoIsWho} />
          <UnauthenticatedRoute exact path={ROUTES.whosWhoOR} component={WhoIsWhoOR} />

          <UnauthenticatedRoute exact path={ROUTES.forgotPassword}>
            <ForgotPassword onSubmit={requestPasswordReset} />
          </UnauthenticatedRoute>
          <Route exact path={ROUTES.passwordReset}>
            <PasswordReset getToken={getTokenData} onSubmit={postPasswordReset} />
          </Route>
          <UnauthenticatedRoute exact path={ROUTES.login}>
            <Login postLogin={postLogin} />
          </UnauthenticatedRoute>
          <AuthenticatedRoute exact path={ROUTES.logout}>
            <Logout sendLogout={sendLogout} />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path={ROUTES.loginSuccess} component={LoginResult} />
          <UnauthenticatedRoute exact path={ROUTES.agencyAdminRegistration}>
            <AgencyAdminRegistration getInviteByToken={getInviteByToken} />
          </UnauthenticatedRoute>

          <UnauthenticatedRoute exact path={ROUTES.privatePayHomeCare} component={PPHCGeneralLandingPage} />
          <UnauthenticatedRoute exact path={ROUTES.dchs} component={DCHSLandingPage} />
          <UnauthenticatedRoute exact path={ROUTES.privatePayHomeCareSearchByZip} component={SearchAgencyProviders} />
          <UnauthenticatedRoute exact path={ROUTES.privatePayHomeCareNeeds} component={MessageAgency} />
          <UnauthenticatedRoute
            exact
            path={ROUTES.privatePayHomeCarePivotConfirmation}
            component={MessageAgencyConfirmation}
          />
          <UnauthenticatedRoute exact path={ROUTES.privatePayHomeCareWaitlist}>
            <PrivatePayHomeCareWaitlistSignup />
          </UnauthenticatedRoute>
          <UnauthenticatedRoute exact path={ROUTES.privatePayHomeCareWaitlistConfirmation}>
            <PrivatePayHomeCareWaitlistConfirmation />
          </UnauthenticatedRoute>

          {isUserType(UserType.UserSupportAgent) && (
            <AuthenticatedRoute
              path={SUPPORT_ADMIN_DASHBOARD_ROUTES.root}
              component={SupportAdminDashboardRouter}
              userType={UserType.UserSupportAgent}
            />
          )}
          {isUserType(UserType.UserSupportManager) && (
            <AuthenticatedRoute
              path={SUPPORT_ADMIN_DASHBOARD_ROUTES.root}
              component={SupportAdminDashboardRouter}
              userType={UserType.UserSupportManager}
            />
          )}

          <AuthenticatedRoute
            path={DASHBOARD_ROUTES.root}
            component={DashboardRouter}
            userType={UserType.AgencyAdmin}
          />
          <AuthenticatedRoute
            path={CARINA_ADMIN_DASHBOARD_ROUTES.root}
            component={CarinaAdminDashboardRouter}
            userType={UserType.CarinaAdmin}
          />
          <AuthenticatedRoute
            path={FUNDER_ADMIN_DASHBOARD_ROUTES.root}
            component={FunderAdminDashboardRouter}
            userType={UserType.FunderAdmin}
          />
          <AuthenticatedRoute
            path={ONBOARDING_ROUTES.root}
            component={OnboardingRouter}
            accountStatus={User_AccountStatus.NEW}
          />
          <AuthenticatedRoute
            path={CARINA_ADMIN_MGMT_ROUTES.root}
            component={CarinaAdminMgmtRouter}
            userType={UserType.CarinaAdmin}
          />
          <Redirect exact from={LEGACY_ROUTES.PPHCForm} to={ROUTES.privatePayHomeCare} />
          <Redirect exact from={LEGACY_ROUTES.PPHCLogin} to={ROUTES.login} />
          <AuthenticatedRoute path={MEDICAID_ACCOUNT_ROUTES.root} component={MedicaidAccountRouter} />
          <UnauthenticatedRoute path={REGISTRATION_ROUTES.root} component={RegistrationRouter} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
};
