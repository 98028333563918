import { BlindsClosed } from "@mui/icons-material";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Grid, SvgIconProps } from "@mui/material";
import { TFunction } from "i18next";
import { PropsWithChildren, useEffect, useState } from "react";
import { AxiosMedicaidCoordinatorControllerClient, JobCountsDTO, JobStatusEnum } from "src/generated/api_types";
import { nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import Alert from "src/reusable_view_elements/alert/Alert";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import Popover from "src/reusable_view_elements/Popover";
import { Body, BodyEmphasis, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const client = new AxiosMedicaidCoordinatorControllerClient();

export const JobCountsByStatusBoxes = () => {
  const { editedJobStatusCounter, setEditedJobStatusCounter } = useMedicaidContext();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState<JobCountsDTO>();
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    client
      .getJobCountsByStatus()
      .then((res) => {
        setCounts(res.data);
        setEditedJobStatusCounter(res.data);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  // Next useEffect updates the counters when editedJobStatusCounter object is updated, saving an api call or page refresh
  useEffect(() => {
    if (editedJobStatusCounter) {
      setCounts(editedJobStatusCounter);
    }
  }, [editedJobStatusCounter]);

  if (error) {
    return (
      <Alert title="Server Error" severity="error">
        <Body>Unable to load job status metrics.</Body>
      </Alert>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <JobCountBox status={JobStatusEnum.OPEN} loading={loading} count={counts?.open} />
      </Grid>
      <Grid item xs={4}>
        <JobCountBox status={JobStatusEnum.EXPIRING_SOON} loading={loading} count={counts?.expiringSoon} />
      </Grid>
      <Grid item xs={4}>
        <JobCountBox status={JobStatusEnum.EXPIRED} loading={loading} count={counts?.expired} />
      </Grid>
    </Grid>
  );
};

export default JobCountsByStatusBoxes;

/********************
 * Sub-Components
 ********************/
interface JobCountBoxProps {
  status: JobStatusEnum;
  loading: boolean;
  count: number | undefined;
}

export const getJobStatusLabel = (status?: JobStatusEnum, t?: TFunction) => {
  if (t && status) return t(status.toString().toLowerCase(), { ns: nsMedicaidTagsEnums });
  switch (status) {
    case JobStatusEnum.OPEN:
      return "Open";
    case JobStatusEnum.EXPIRING_SOON:
      return "Expiring soon";
    case JobStatusEnum.EXPIRED:
      return "Expired";
    case JobStatusEnum.CLOSED:
      return "Closed";
    default:
      return "Unknown";
  }
};

export const StatusIcon = ({ status, ...restProps }: { status?: JobStatusEnum } & SvgIconProps) => {
  switch (status) {
    case JobStatusEnum.OPEN:
      return <WorkIcon htmlColor={CivColors.forestSpring} {...restProps} />;
    case JobStatusEnum.EXPIRING_SOON:
      return <FlagCircleIcon htmlColor={CivColors.sunnyDune} {...restProps} />;
    case JobStatusEnum.EXPIRED:
      return <WarningRoundedIcon htmlColor={CivColors.deepRose} {...restProps} />;
    case JobStatusEnum.CLOSED:
      return <BlindsClosed htmlColor={CivColors.coreDarkNavy} {...restProps} />;
    default:
      return <HelpRoundedIcon htmlColor={CivColors.deepGray} {...restProps} />;
  }
};

const JobCountBox = ({ status, loading, count }: JobCountBoxProps) => {
  const statusText = getJobStatusLabel(status);

  return (
    <Box borderRadius="4px" border="1px solid rgba(0, 0, 0, 0.12)" pt="16px" px="16px" id={`${status}-job-count`}>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item>
          {/* 2px marginTop needed to offset icon so that it aligns with all the styling defined in Popover.tsx */}
          <StatusIcon status={status} sx={{ marginTop: "2px" }} />
        </Grid>
        <Grid item>
          <StatusPopover status={status}>
            <BodyEmphasis>{statusText}</BodyEmphasis>
          </StatusPopover>
        </Grid>
      </Grid>
      {loading ? <LoadingCircle /> : <SectionTitle>{count}</SectionTitle>}
    </Box>
  );
};

const StatusPopover = ({ status, children }: PropsWithChildren<{ status: JobStatusEnum }>) => {
  let title;
  let content;
  switch (status) {
    case JobStatusEnum.OPEN:
      title = `What does the "Open" status mean?`;
      content = <Body>The job post is actively seeking a Provider and is visible to Providers on our platform.</Body>;
      break;
    case JobStatusEnum.EXPIRING_SOON:
      title = `What does "Expiring soon" mean?`;
      content = (
        <>
          <Body paragraph>
            The job post is open, but nearing its expiration date, with 14 days remaining before transitioning to
            &quot;Expired&quot;.
          </Body>
          <Body>You may extend the job to keep it active on Carina, or close the job if it is no longer needed.</Body>
        </>
      );
      break;
    case JobStatusEnum.EXPIRED:
      title = `What does "Expired" mean?`;
      content = (
        <>
          <Body paragraph>
            The job post has been on our platform for over 120 days and presumed to be stale. The job is no longer
            visible in search results.
          </Body>
          <Body>You may reopen the job to keep it active on Carina, or close the job if it is no longer needed.</Body>
        </>
      );
      break;
    default:
      title = "Unknown";
  }

  // Handle unknown status edge case
  if (title === "Unknown") {
    return <BodyTitle>Unknown</BodyTitle>;
  }

  return (
    <Popover contentTitle={title} content={content} contentId={`${status}-popover`}>
      {children}
    </Popover>
  );
};
