import { BlindsClosed } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import WorkIcon from "@mui/icons-material/Work";
import { BoxProps, Grid, IconButton, InputAdornment, Menu, MenuProps } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { MouseEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { generatePath, useHistory } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import {
  AxiosMedicaidConsumerControllerClient,
  AxiosMedicaidCoordinatorControllerClient,
  JobCountsDTO,
  JobListItemDTO,
  JobStatusEnum,
  Nullable,
} from "src/generated/api_types";
import { useDebounce } from "src/i18n/Utilities";
import { MEDICAID_INBOX_ROUTES } from "src/pages/inbox/InboxRouter";
import JobCountsByStatusBoxes from "src/pages/medicaidAccount/coordinatorAccount/components/JobCountsByStatusBoxes";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import CoordinatorJobDrawer from "src/pages/medicaidAccount/sharedComponents/CoordinatorJobDrawer";
import { Button } from "src/reusable_view_elements/Button";
import Constraint from "src/reusable_view_elements/Constraint";
import TextField from "src/reusable_view_elements/form_fields/TextField";
import { InternalLink, LinkStyledAsButton } from "src/reusable_view_elements/Link";
import MenuItem from "src/reusable_view_elements/MenuItem";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import Section from "src/reusable_view_elements/Section";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import CivTheme from "src/themes/civilization/CivTheme";
import { toSentenceCase } from "src/utilities/GeneralUtilities";
import { convertSortModelToString, convertStringToSortModel } from "src/utilities/MuiDataGridUtilities";

const client = new AxiosMedicaidCoordinatorControllerClient();

export enum JobModalEnum {
  SIDE_PANEL_DETAILS,
  SIDE_PANEL_EDIT,
  INLINE_STATUS_MENU,
  SIDE_PANEL_STATUS_MENU,
}

export const AssistiveJobsDashboard = () => {
  const { useMainSnackbar, closeSnackbar } = useNotification();
  const { selectedRowJob, setSelectedRowJob, activeJobModalTypes, setActiveJobModalTypes } = useMedicaidContext();

  /******************************************** ******************************************************/
  /******** Next useEffect is used to close snackbars other than  ************************************/
  /******** the one in  MainRouter when the user navigates        ************************************/
  useEffect(() => {
    if (!useMainSnackbar) closeSnackbar();
  }, []);
  /******************************************** ******************************************************/

  const updateRow = (row: JobListItemDTO) => {
    const updatedRows = rows.map((r) => (r.id === row.id ? row : r));
    setRows(updatedRows);
    if (selectedRowJob) {
      setSelectedRowJob((prev) => ({ ...prev, ...row }));
    }
  };

  const openJobDetailsSidePanel = (row: JobListItemDTO) => {
    setSelectedRowJob(row);
    setActiveJobModalTypes([...activeJobModalTypes, JobModalEnum.SIDE_PANEL_DETAILS]);
  };

  const columns: GridColDef[] = [
    {
      field: "jobNumber",
      headerName: "Job #",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="text"
          type="button"
          sx={{ fontWeight: "400px", width: "100%", paddingLeft: "0px", "&:hover": { background: "none" } }}
          onClick={() => openJobDetailsSidePanel(params.row as JobListItemDTO)}
        >
          <Body sx={{ textDecoration: "underline" }}>{params.value}</Body>
        </Button>
      ),
    },
    { field: "firstName", headerName: "First name", flex: 1 },
    { field: "lastName", headerName: "Last name", flex: 1 },
    { field: "hours", headerName: "Monthly hours", flex: 1 },
    { field: "daysOpen", headerName: "Days open", flex: 1 },
    {
      field: "jobStatus",
      headerName: "Status",
      flex: 1,
      minWidth: 225,
      renderCell: (params) => JobPostStatusCell(params, updateRow),
    },
    {
      field: "moreOptions",
      type: "actions",
      headerName: "More options",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => JobPostMoreOptionsCell({ row: params.row as JobListItemDTO }),
    },
  ];

  // Data State
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [rows, setRows] = useState<JobListItemDTO[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);

  // Pagination State
  const pageSizeOptions = [5, 10, 50, 100];
  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);

  // Sorting State: By default, sort by job status
  const [sortString, setSortString] = useState<Nullable<string>>("daysOpen,desc");

  // The search input and job status filters should be persisted between navigation and page refreshes
  // Check localStorage for saved search and status filter, before setting search and status filter initial states
  const JOB_DASHBOARD_STATE_KEY = "job-dashboard-state";
  const storedSearchFilter = localStorage.getItem(JOB_DASHBOARD_STATE_KEY);
  const parsedStoredSearchFilter = storedSearchFilter ? JSON.parse(storedSearchFilter) : null;

  // Textfield Search State
  const [realtimeSearchQuery, setRealtimeSearchQuery] = useState<string>(parsedStoredSearchFilter?.searchInput ?? "");
  const debouncedSearchQuery = useDebounce(realtimeSearchQuery, 500);

  // Job Status Filter State (if no persisted filters, default to all statuses except Closed)
  const [showFilterByStatusMenu, setShowFilterByStatusMenu] = useState(false);
  const [filterByStatusMenuAnchor, setFilterByStatusMenuAnchor] = useState<null | HTMLElement>(null);
  const [jobStatusFilter, setJobStatusFilter] = useState<JobStatusEnum[]>(
    parsedStoredSearchFilter?.statusFilter ?? [JobStatusEnum.OPEN, JobStatusEnum.EXPIRING_SOON, JobStatusEnum.EXPIRED],
  );

  useEffect(() => {
    const searchFilterStateStr = JSON.stringify({
      searchInput: realtimeSearchQuery,
      statusFilter: jobStatusFilter,
    });
    localStorage.setItem(JOB_DASHBOARD_STATE_KEY, searchFilterStateStr);
  }, [realtimeSearchQuery, jobStatusFilter]);

  const handleJobDrawerClose = () => {
    setSelectedRowJob(undefined);
    setActiveJobModalTypes([]);
  };

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      const jobStatusesString = jobStatusFilter.length > 0 ? jobStatusFilter.join(",") : "";
      const response = await client.getJobs({
        jobStatus: jobStatusesString,
        search: debouncedSearchQuery,
        page: page,
        size: pageSize,
        sort: sortString,
      });
      setRows(response.data.content);
      setTotalRows(response.data.totalElements);
      setLoadingData(false);
    })();
  }, [page, pageSize, sortString, debouncedSearchQuery, jobStatusFilter]);

  const FilterByStatusMenu = () => {
    const handleCheckboxAction = (status: JobStatusEnum) => {
      if (jobStatusFilter.includes(status)) {
        const updatedFilter = jobStatusFilter.filter((element) => element != status);
        setJobStatusFilter(updatedFilter);
        setShowFilterByStatusMenu(false);
      } else {
        const updatedFilter = jobStatusFilter.concat(status);
        setJobStatusFilter(updatedFilter);
        setShowFilterByStatusMenu(false);
      }
    };

    return (
      <Menu
        anchorEl={filterByStatusMenuAnchor}
        keepMounted // mounted in the DOM for accessibility
        open={showFilterByStatusMenu}
        onClose={() => setShowFilterByStatusMenu(false)}
      >
        <MenuItem
          variant="checkbox"
          id="Open"
          selected={jobStatusFilter.includes(JobStatusEnum.OPEN)}
          value={JobStatusEnum.OPEN}
          onClick={() => handleCheckboxAction(JobStatusEnum.OPEN)}
        >
          Open
        </MenuItem>
        <MenuItem
          variant="checkbox"
          id="Expiring_soon"
          selected={jobStatusFilter.includes(JobStatusEnum.EXPIRING_SOON)}
          value={JobStatusEnum.EXPIRING_SOON}
          onClick={() => handleCheckboxAction(JobStatusEnum.EXPIRING_SOON)}
        >
          Expiring soon
        </MenuItem>
        <MenuItem
          variant="checkbox"
          id="Expired"
          selected={jobStatusFilter.includes(JobStatusEnum.EXPIRED)}
          value={JobStatusEnum.EXPIRED}
          onClick={() => handleCheckboxAction(JobStatusEnum.EXPIRED)}
        >
          Expired
        </MenuItem>
        <MenuItem
          variant="checkbox"
          id="Closed"
          selected={jobStatusFilter.includes(JobStatusEnum.CLOSED)}
          value={JobStatusEnum.CLOSED}
          onClick={() => handleCheckboxAction(JobStatusEnum.CLOSED)}
        >
          Closed
        </MenuItem>
      </Menu>
    );
  };

  return (
    <>
      <Helmet>
        <title>TEST Jobs Dashboard</title>
      </Helmet>

      <MedicaidNavbar />

      <CoordinatorJobDrawer
        open={
          !!selectedRowJob &&
          (activeJobModalTypes.includes(JobModalEnum.SIDE_PANEL_DETAILS) ||
            activeJobModalTypes.includes(JobModalEnum.SIDE_PANEL_EDIT))
        }
        onClose={handleJobDrawerClose}
        updateRowFn={updateRow}
      />

      <main id="main-content">
        {/* Top Section: Job Counts */}
        <PageSection paddingBottom={2}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item sm={12} md={6}>
              <JobCountsByStatusBoxes />
            </Grid>
            <Grid item>
              <LinkStyledAsButton variant="contained" href={Medicaid_Coordinator_Dashboard_Routes.preVerifyJobPost}>
                <AddIcon htmlColor={CivColors.white} style={{ width: "24px", height: "24px", marginRight: "6px" }} />
                Open new job
              </LinkStyledAsButton>
            </Grid>
          </Grid>
        </PageSection>

        {/* Bottom Section: Job DataGrid */}
        <PageSection paddingTop={2}>
          {/* Search Field and Status Filter */}
          <Grid container alignItems="flex-end" style={{ marginBottom: "32px" }}>
            <TextField
              label="Search by client name or job #"
              id="job-status-table-search-field"
              onChange={(event) => setRealtimeSearchQuery(event.target.value)}
              value={realtimeSearchQuery}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
              style={{ maxWidth: "400px" }}
            />
            <IconButton
              style={{ marginBottom: 6, marginLeft: 10 }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setFilterByStatusMenuAnchor(event.currentTarget);
                setShowFilterByStatusMenu(true);
              }}
              aria-label="Show status filter menu"
            >
              <FilterListIcon />
            </IconButton>
            <FilterByStatusMenu />
          </Grid>

          {/* Job Summary DataGrid */}
          <DataGrid
            loading={loadingData}
            columns={columns}
            rows={rows}
            rowCount={totalRows}
            disableRowSelectionOnClick
            disableColumnFilter
            pageSizeOptions={pageSizeOptions}
            paginationMode="server"
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={(paginationModel) => {
              setPage(paginationModel.page);
              setPageSize(paginationModel.pageSize);
            }}
            sortingMode="server"
            sortModel={convertStringToSortModel(sortString)}
            onSortModelChange={(model) => {
              setSortString(convertSortModelToString(model));
            }}
          />
        </PageSection>
      </main>
    </>
  );
};

/******************
 * Sub-Components
 *******************/

const PageSection = ({ children, ...boxProps }: BoxProps) => {
  return (
    <Section bgcolor={CivColors.white} {...boxProps}>
      <Constraint columns={18}>{children}</Constraint>
    </Section>
  );
};

type ChangeJobMenuProps = {
  jobStatusMenuAnchor: HTMLElement | null;
  updateRowFn: (updatedJob: JobListItemDTO) => void; // Callback to update the job row w/o refreshing the page
} & MenuProps;

export const ChangeJobStatusMenu = (props: ChangeJobMenuProps) => {
  const { jobStatusMenuAnchor, updateRowFn, ...menuProps } = props;
  const {
    selectedRowJob,
    setSelectedRowJob,
    activeJobModalTypes,
    setActiveJobModalTypes,
    editedJobStatusCounter,
    setEditedJobStatusCounter,
  } = useMedicaidContext();
  const { showSnackbar } = useNotification();
  const history = useHistory();

  const handleExtendJobRequest = async () => {
    try {
      if (!selectedRowJob) return; // This should not happen, but just to avoid checking nullity below
      // Call the endpoint to extend/reopen the job
      const medConsumerClient = new AxiosMedicaidConsumerControllerClient();
      await medConsumerClient.renewJobPost(selectedRowJob.id);

      // Do the next if the request is successful:
      //1. Update dashboard row
      updateRowFn({ ...selectedRowJob, jobStatus: JobStatusEnum.OPEN, daysOpen: 0 });

      // 2. Update job status counts
      if (editedJobStatusCounter) {
        const updatedCounts: JobCountsDTO = {
          expired:
            selectedRowJob.jobStatus === JobStatusEnum.EXPIRED
              ? editedJobStatusCounter.expired - 1
              : editedJobStatusCounter.expired,
          expiringSoon:
            selectedRowJob.jobStatus === JobStatusEnum.EXPIRING_SOON
              ? editedJobStatusCounter.expiringSoon - 1
              : editedJobStatusCounter.expiringSoon,
          open: editedJobStatusCounter.open + 1,
        };
        setEditedJobStatusCounter(updatedCounts);
      }

      // 3. Show success snackbar
      showSnackbar(
        `Job ${selectedRowJob.jobStatus === JobStatusEnum.EXPIRED ? "reopened" : "extended"} successfully`,
        "success",
        true,
        activeJobModalTypes.includes(JobModalEnum.INLINE_STATUS_MENU),
      );
    } catch (e) {
      // Show error message if the request fails
      showSnackbar(
        `Error ${selectedRowJob?.jobStatus === JobStatusEnum.EXPIRED ? "reopening" : "extending"} job. 
        Please try again later`,
        "error",
      );
    } finally {
      // Reset selected job if it's inline (directly on the dashboard) edit
      if (activeJobModalTypes.includes(JobModalEnum.INLINE_STATUS_MENU)) setSelectedRowJob(undefined);

      // Close the menu regardless of where it was opened (inline or side-panel)
      setActiveJobModalTypes(
        activeJobModalTypes.filter(
          (type) => ![JobModalEnum.INLINE_STATUS_MENU, JobModalEnum.SIDE_PANEL_STATUS_MENU].includes(type),
        ),
      );
    }
  };

  if (!selectedRowJob) return <></>;

  return (
    <Menu
      id="job-status-menu-options"
      anchorEl={jobStatusMenuAnchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted // mounted in the DOM for accessibility
      {...menuProps} // spread the rest of the props to the menu
    >
      {selectedRowJob.jobStatus === JobStatusEnum.EXPIRING_SOON && (
        <MenuItem onClick={handleExtendJobRequest}>
          <Grid item display="flex" direction="row" alignItems="center" mb="12px">
            <WorkIcon />
            <Body style={{ marginLeft: 12 }}>Extend job</Body>
          </Grid>
          <Grid item>
            <Body>Keeps job open and visible to providers</Body>
          </Grid>
        </MenuItem>
      )}

      {selectedRowJob.jobStatus === JobStatusEnum.EXPIRED && (
        <MenuItem onClick={handleExtendJobRequest}>
          <Grid item display="flex" direction="row" alignItems="center" mb="12px">
            <WorkIcon />
            <Body style={{ marginLeft: 12 }}>Reopen job</Body>
          </Grid>
          <Grid item>
            <Body>Returns job to open making it visible to providers</Body>
          </Grid>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          setSelectedRowJob(undefined); // ensures that the CoordinatorJobDrawer is closed when user returns to jobs dashboard after completing delete job flow
          history.push(generatePath(Medicaid_Coordinator_Dashboard_Routes.jobDelete, { id: selectedRowJob.id }));
        }}
      >
        <Grid item display="flex" direction="row" alignItems="center" mb="12px">
          <BlindsClosed />
          <Body style={{ marginLeft: 12 }}>Close job</Body>
        </Grid>
        <Grid item>
          <Body>Removes job from platform but keeps it in your records</Body>
        </Grid>
      </MenuItem>
    </Menu>
  );
};

const JobPostStatusCell = (
  params: GridRenderCellParams<any, any, any>,
  updateRowFn: (updatedRow: JobListItemDTO) => void,
) => {
  const { selectedRowJob, setSelectedRowJob, activeJobModalTypes, setActiveJobModalTypes } = useMedicaidContext();
  const [jobStatusMenuAnchor, setJobStatusMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOpenInlineMenu = (event: MouseEvent<HTMLButtonElement>, row: JobListItemDTO) => {
    setJobStatusMenuAnchor(event.currentTarget);
    setSelectedRowJob(row);
    setActiveJobModalTypes([...activeJobModalTypes, JobModalEnum.INLINE_STATUS_MENU]);
  };

  const handleInlineMenuOnClose = () => {
    setSelectedRowJob(undefined);
    setActiveJobModalTypes([]);
  };

  const getStatusIcon = () => {
    const iconStyle = { margin: 9 };
    switch (params.row.jobStatus) {
      case JobStatusEnum.OPEN:
        return <WorkIcon htmlColor={CivColors.forestSpring} style={iconStyle} />;
      case JobStatusEnum.EXPIRING_SOON:
        return <FlagCircleIcon htmlColor={CivColors.sunnyDune} style={iconStyle} />;
      case JobStatusEnum.EXPIRED:
        return <WarningRoundedIcon htmlColor={CivColors.deepRose} style={iconStyle} />;
      case JobStatusEnum.CLOSED:
        return <BlindsClosed htmlColor={CivColors.coreDarkNavy} style={iconStyle} />;
      default:
        return <HelpRoundedIcon htmlColor={CivColors.deepGray} style={iconStyle} />;
    }
  };
  return (
    <>
      <Grid container direction="row" alignItems="center">
        {getStatusIcon()}
        <Body>{toSentenceCase(params.row.jobStatus)}</Body>
        <IconButton
          aria-label="job status actions"
          style={{ padding: 1, marginLeft: "auto" }}
          onClick={(event) => handleOpenInlineMenu(event, params.row as JobListItemDTO)}
        >
          {params.row.jobStatus != JobStatusEnum.CLOSED && (
            <ArrowDropDownIcon fontSize="large" htmlColor={CivColors.coreGray} />
          )}
        </IconButton>
      </Grid>
      <ChangeJobStatusMenu
        jobStatusMenuAnchor={jobStatusMenuAnchor}
        updateRowFn={updateRowFn}
        open={
          !!selectedRowJob &&
          selectedRowJob.id === params.row.id &&
          activeJobModalTypes.includes(JobModalEnum.INLINE_STATUS_MENU)
        }
        onClose={handleInlineMenuOnClose}
      />
    </>
  );
};

const JobPostMoreOptionsCell = ({ row }: { row: JobListItemDTO }) => {
  const { setSelectedRowJob, activeJobModalTypes, setActiveJobModalTypes } = useMedicaidContext();
  const [jobRowMenuAnchor, setJobRowMenuAnchor] = useState<null | HTMLElement>(null);
  const [showJobRowMenu, setShowJobRowMenu] = useState<boolean>(false);

  const handleViewJobOption = () => {
    setSelectedRowJob(row);
    setActiveJobModalTypes([...activeJobModalTypes, JobModalEnum.SIDE_PANEL_DETAILS]);
    setShowJobRowMenu(false);
  };

  const handleEditJobOption = () => {
    setSelectedRowJob(row);
    setActiveJobModalTypes([...activeJobModalTypes, JobModalEnum.SIDE_PANEL_DETAILS, JobModalEnum.SIDE_PANEL_EDIT]);
    setShowJobRowMenu(false);
  };

  const isJobEditable = row.jobStatus === JobStatusEnum.OPEN || row.jobStatus === JobStatusEnum.EXPIRING_SOON;

  return (
    <>
      <IconButton
        id={`job-row-menu-button-${row.id}`}
        aria-label="show more actions button"
        aria-haspopup="true"
        onClick={(event) => {
          setJobRowMenuAnchor(event.currentTarget);
          setShowJobRowMenu(true);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`job-row-menu-options-${row.id}`}
        anchorEl={jobRowMenuAnchor}
        keepMounted // mounted in the DOM for accessibility
        open={showJobRowMenu}
        onClose={() => setShowJobRowMenu(false)}
        sx={{ zIndex: CivTheme.zIndex.drawer - 1 }} //Prevent overlaying the drawer (job side panel)
      >
        <MenuItem onClick={handleViewJobOption}>View job</MenuItem>
        {isJobEditable && <MenuItem onClick={handleEditJobOption}>Edit job</MenuItem>}
        <MenuItem>
          <InternalLink
            to={generatePath(MEDICAID_INBOX_ROUTES.allThreadsWithPresetSearch, {
              presetInboxSearchQuery: row.jobNumber,
            })}
            target="_blank"
            sx={{ textDecoration: "none" }}
          >
            Go to message threads
          </InternalLink>
        </MenuItem>
      </Menu>
    </>
  );
};
