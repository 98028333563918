import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { UserType } from "src/context/UserSessionContext";
import { JobPostListDTO, JobPostTagCategoryEnum, JobPostTagEnum } from "src/generated/api_types";
import { nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedJobPostTag } from "src/i18n/TagHelpers";
import { getLocalizedUserType } from "src/i18n/Utilities";
import { getTagsObject } from "src/pages/medicaidAccount/consumerAccount/components/CreateEditRepostJobForm";
import JobCard from "src/reusable_view_elements/JobCard";
import { getDistanceInMiles } from "src/utilities/GeneralUtilities";

interface JobCardForSearchProps {
  jobPost: JobPostListDTO;
  onBookmark?: () => void;
  buttonSection: ReactElement;
  hideBookmark?: boolean;
  previewPageCard?: boolean;
  bookmarked?: boolean;
  daysUp?: number;
  schedule?: string;
  first_name?: string;
  buttonLink: string;
}

const JobCardTranslated = ({
  jobPost,
  onBookmark,
  buttonSection,
  hideBookmark,
  previewPageCard = false,
  bookmarked,
  daysUp,
  schedule,
  first_name,
  buttonLink,
}: JobCardForSearchProps) => {
  const { t } = useTranslation(nsMedicaidTagsEnums);
  const tagsObject = getTagsObject(jobPost.entity);

  function getAgeAndSupportDescription(): string {
    const ageLocizeKey: string = getLocalizedJobPostTag(tagsObject.ageRangeTag).key;
    const supportLocizeKeys: string[] = tagsObject.typeOfSupportTags.map((tag) => getLocalizedJobPostTag(tag).key);
    const keySet: string[] = [ageLocizeKey, ...supportLocizeKeys];
    return keySet.map((key) => t(key, { ns: nsMedicaidTagsEnums })).join(" • ");
  }

  function getRoleOrAgencyDescription(): string {
    const posterRole = jobPost.entity.postingUserInfo.role;
    const posterOrg = jobPost.entity.postingUserOrganization;
    if (posterRole === UserType.MedicaidReferralCoordinator && !!posterOrg) {
      return posterOrg;
    }
    const locizeObject = getLocalizedUserType(posterRole as UserType);
    return t(locizeObject.key, { ns: locizeObject.namespace });
  }

  function getTranslatedPronounsString(): string | undefined {
    return jobPost.entity.postingUserInfo.role === UserType.Consumer
      ? jobPost.entity.tags
          .filter((tagObj) => tagObj.category === JobPostTagCategoryEnum.PRONOUN)
          .sort((a, b) => a.order - b.order)
          .map((tagObj) => getTranslatedJobPostTag(tagObj.tag))
          .join(", ")
      : undefined;
  }

  function getTranslatedClientGender(): string | undefined {
    const clientGenderTags = tagsObject.consumerGenderTags.filter(
      (tag) => tag !== JobPostTagEnum.NO_GENDER_PREFERENCE_CLIENT,
    );
    return clientGenderTags.length > 0
      ? clientGenderTags.map((tag) => getTranslatedJobPostTag(tag)).join(", ")
      : undefined;
  }

  function getTranslatedJobPostTag(tag: JobPostTagEnum): string {
    return t(getLocalizedJobPostTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  return (
    <JobCard
      flexibleHours={jobPost.entity.hoursFlexible}
      flexibleSchedule={jobPost.entity.scheduleFlexible}
      first_name={first_name}
      gender={getTranslatedClientGender()}
      pronouns={getTranslatedPronounsString()}
      city={jobPost.entity.geoData.city}
      state={jobPost.entity.geoData.state}
      description={getAgeAndSupportDescription()}
      previewPageCard={previewPageCard}
      postedBy={jobPost.entity.postingUserInfo.firstName}
      roleOrAgency={getRoleOrAgencyDescription()}
      hours={jobPost.entity.hours}
      schedule={schedule}
      languages={tagsObject.languageTags.map(getTranslatedJobPostTag)}
      careNeeds={tagsObject.skillTags.map(getTranslatedJobPostTag)}
      distance={getDistanceInMiles(jobPost.distance)}
      buttonLink={buttonLink}
      daysUp={daysUp}
      hideBookmark={hideBookmark}
      bookmarked={bookmarked}
      onBookmark={onBookmark}
      buttonSection={buttonSection}
      jobNumber={jobPost.entity.jobNumber}
    />
  );
};

export default JobCardTranslated;
