import { useTranslation } from "react-i18next";
import { useUserSession } from "src/context/UserSessionContext";
import { RoleType } from "src/generated/api_types";
import { nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedRoleType } from "src/i18n/Utilities";
import { nameAndRole } from "src/pages/inbox/inboxComponents/AssistiveRoleThreadListCard";

export const firstNameAndInitial = (firstName: string, lastNameOrInitial?: string): string => {
  let text = firstName;
  if (lastNameOrInitial) {
    text += ` ${lastNameOrInitial[0]}.`;
  }
  return text;
};

export const ellipsisTrim = (text: string, lengthLimit: number): string => {
  if (text.length < Math.max(3, lengthLimit)) return text;
  return `${text.substring(0, lengthLimit - 3)}...`;
};

export const NameOrgRoleTitle = ({
  component: Component,
  roleType,
  firstName,
  lastInitial,
  org,
}: {
  component: React.FC;
  firstName: string;
  lastInitial?: string;
  roleType: RoleType;
  org?: string;
}) => {
  const { t } = useTranslation(nsMedicaidTagsEnums);
  const { isAssistiveRoleView } = useUserSession();

  enum ParenthesesDataType {
    organization,
    role,
    none,
  }

  // Helps determine what sort of information to render based on their role
  function determineParenthesesDataType(senderRole: RoleType): ParenthesesDataType {
    if (senderRole === RoleType.MEDICAID_REFERRAL_COORDINATOR) {
      return ParenthesesDataType.organization;
    }
    if (senderRole === RoleType.MEDICAID_CASE_MANAGER || senderRole === RoleType.PROXY_PROVIDER) {
      return ParenthesesDataType.role;
    }
    return ParenthesesDataType.none;
  }

  let text = "";
  if (isAssistiveRoleView()) {
    text = nameAndRole(t, roleType, firstName, lastInitial, isAssistiveRoleView);
  } else {
    text = firstNameAndInitial(firstName, lastInitial);
    // Information shown in parentheses after name (role or organization)
    const type = determineParenthesesDataType(roleType);
    if (type === ParenthesesDataType.organization && org) {
      text += ` (${org})`;
    }
    if (type === ParenthesesDataType.role) {
      const locizeRole = getLocalizedRoleType(roleType);
      text += ` (${t(locizeRole.key, { ns: locizeRole.namespace })})`;
    }
  }
  return <Component>{text}</Component>;
};
