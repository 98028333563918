import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import {
  Box,
  FormHelperText,
  InputLabel,
  TextField as MTextField,
  TextFieldProps as MTextFieldProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";
import { Body } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const StyledTextField = styled(MTextField)((props) => ({
  "& .MuiFormHelperText-root": {
    margin: 0,
    lineHeight: 1.2,
    color: CivColors.coreGray,
    "&.Mui-disabled": {
      color: CivColors.mediumGray,
    },
    "&.Mui-error": {
      color: CivColors.deepRose,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    backgroundColor: CivColors.white,
    "& fieldset": {
      borderColor: CivColors.coreDarkNavy,
    },
    "&.Mui-focused fieldset": {
      borderColor: CivColors.coreDarkNavy,
    },
    "&.Mui-error fieldset": {
      borderColor: CivColors.deepRose,
    },
    "&.Mui-disabled": {
      backgroundColor: "inherit",
      "& fieldset": {
        borderColor: CivColors.mediumGray,
      },
    },
    "&.Mui-readOnly fieldset": {
      borderColor: "rgba(45, 48, 69, .23)",
    },
    "&.Mui-readOnly.Mui-focused fieldset": {
      borderColor: CivColors.coreDarkNavy,
    },
  },
  "& .MuiOutlinedInput-input": {
    fontVariantLigatures: "no-contextual",
    fontFamily: "Europa-Regular, sans-serif",
    fontSize: "1.0rem",
    lineHeight: 1.2,
    "&::-webkit-input-placeholder": { color: CivColors.deepGray, opacity: 1 },
    "&::-moz-placeholder": { color: CivColors.deepGray, opacity: 1 }, // Firefox 19+
    "&:-ms-input-placeholder": { color: CivColors.deepGray, opacity: 1 }, // IE11
    "&::-ms-input-placeholder": { color: CivColors.deepGray, opacity: 1 }, // Edge
    "&.Mui-disabled": {
      "-webkit-text-fill-color": CivColors.mediumGray,
    },
  },
  "& .MuiInputBase-root": {
    "&.Mui-readOnly": {
      backgroundColor: "transparent",
    },
    marginTop: "4px",
    marginBottom: "4px",
    padding: props.InputProps?.endAdornment ? "12px 0px 12px 14px" : "12px 14px",
    "& .MuiInputBase-input": {
      padding: 0,
      height: "24px",
    },
  },
}));

export const StyledInputLabel = styled(InputLabel)({
  color: CivColors.coreDarkNavy,
  fontFamily: "Europa-Bold, sans-serif",
  fontSize: "1.0rem",
  lineHeight: 1.2,
  textAlign: "left",
  fontVariantLigatures: "no-contextual",
  "&.Mui-disabled": { color: CivColors.mediumGray }, //Wondering if it's preferable to set this globally
});

export const DescriptiveText = (props: {
  helperText: ReactNode;
  multiline?: boolean;
  isFormikError?: boolean;
  isLengthError: boolean;
  capacityText?: string;
  disabled?: boolean;
}): React.ReactElement => {
  const helperTextStyle = {
    display: "flex",
    justifyContent: "space-between",
    color: props.isLengthError ? CivColors.deepRose : props.disabled ? CivColors.mediumGray : CivColors.coreGray,
  };
  // Red Error icon descriptive text appears whether formik or length error
  const eitherError = props.isFormikError || props.isLengthError;

  return (
    <Box style={helperTextStyle}>
      <Box sx={{ display: "flex", alignItems: "start", marginRight: "24px" }}>
        {/* Error icon appears whether it is formik or length error */}
        {eitherError && (
          <ErrorRoundedIcon
            sx={{
              alignItems: "top",
              width: 24,
              height: 24,
              marginRight: "12px",
              verticalAlign: "1px",
              fill: CivColors.deepRose,
            }}
          />
        )}
        <Body sx={{ color: eitherError ? CivColors.deepRose : undefined }}>{props.helperText}</Body>
      </Box>
      {props.multiline && (
        // Capacity text only appears red when over the limit
        <Body aria-hidden="true">{props.capacityText}</Body>
      )}
    </Box>
  );
};

// maxLength is provided out of the box, but hard stops the text at the limit.
// maximumlength is a custom prop that allows the user to see how many characters they have left and go over the limit
export type TextFieldProps = Omit<MTextFieldProps, "label"> & {
  label?: string;
  maximumlength?: number;
  trimSpaces?: boolean;
};

const TextField = ({ label, InputLabelProps, helperText, sx, onChange, ...restProps }: TextFieldProps) => {
  let charCount: number = 0;
  let capacityText: string = "";
  let isLengthError: boolean = false;

  // Calculate character counter if multiline is true
  // Multiline fields should also include a maximumlength prop
  if (restProps.multiline) {
    charCount = typeof restProps.value === "string" ? restProps.value.length : 0;
    const maximumlength = restProps.maximumlength || 1000; // Default to 1000 if not specified
    capacityText = `${charCount}/${maximumlength}`;
    isLengthError = charCount > maximumlength;
  }

  return (
    <Box sx={{ ...sx }}>
      <StyledInputLabel
        {...InputLabelProps}
        required={restProps.required}
        disabled={restProps.disabled}
        htmlFor={restProps.id}
      >
        {label}
      </StyledInputLabel>
      <StyledTextField
        {...restProps}
        variant={restProps.variant || "outlined"}
        fullWidth
        onChange={(e) => {
          if (restProps.trimSpaces) e.target.value = e.target.value.trim();
          onChange && onChange(e);
        }}
      />
      <FormHelperText component={"span"}>
        <DescriptiveText
          helperText={helperText}
          multiline={restProps.multiline}
          isFormikError={restProps.error}
          isLengthError={isLengthError}
          capacityText={capacityText}
          disabled={restProps.disabled}
        />
      </FormHelperText>
    </Box>
  );
};
export default TextField;
