import { Grid, Link as MuiLink, useMediaQuery } from "@mui/material";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { usePostHog } from "posthog-js/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { RoleType, TestimonialSourceEnum, ThreadDetailsDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidJobSurvey } from "src/i18n/Namespaces";
import ThreadSurveyMatchedConfirmation from "src/pages/inbox/matchingSurveyComponents/ThreadSurveyMatchedConfirmation";
import ThreadSurveyNotMatchedConfirmation from "src/pages/inbox/matchingSurveyComponents/ThreadSurveyNotMatchedConfirmation";
import { messagingClient } from "src/pages/inbox/MessagingControllerClient";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import FormAlert from "src/reusable_view_elements/alert/FormAlert";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import { FormRadioGroupField, FormSelectField, FormTextField } from "src/reusable_view_elements/form_fields";
import { CheckboxField } from "src/reusable_view_elements/form_fields/CheckboxField";
import Form from "src/reusable_view_elements/form_fields/Form";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import { Button } from "src/reusable_view_elements/Button";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, BodyTitle, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import theme from "src/themes/civilization/CivTheme";
import { isRequiredField } from "src/utilities/GeneralUtilities";
import { object, string } from "yup";

enum HiredEnum {
  YES = "YES",
  NO = "NO",
}

export interface ThreadMatchingSurveyData {
  hired?: HiredEnum;
  hours?: number;
  careProviderId?: string;
  furtherInformation: string;
  testimonialConsent: boolean;
  testimonialSource?: TestimonialSourceEnum;
  testimonialName?: string;
}

const ThreadMatchingSurvey = () => {
  const { showSnackbar } = useNotification();
  const { userSession, isUserType } = useUserSession();
  const desktopSize = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px)`);
  const [showMatchedConfirmation, setShowMatchedConfirmation] = useState<boolean>(false);
  const [showNotMatchedConfirmation, setShowNotMatchedConfirmation] = useState<boolean>(false);
  const [userWasHired, setUserWasHired] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { id: threadId } = useParams<{ id: string }>();
  const [thread, setThread] = useState<ThreadDetailsDTO>();
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const { t, ready } = useTranslation([nsMedicaidJobSurvey, nsCommonFormsBtns, nsCommonToasts]);
  const posthog = usePostHog();

  const FURTHER_INFO_MAX = 500;

  useEffect(() => {
    setLoadingDetails(true);
    messagingClient
      .getThreadDetails({ threadId })
      .then((res) => {
        setThread(res.data);
      })
      .catch((error: AxiosError) => {
        showSnackbar(error.message, "error");
      })
      .finally(() => {
        setLoadingDetails(false);
      });
  }, []);

  const PageWrapper = (props: PropsWithChildren<{}>) => (
    <>
      <Helmet>
        <title>{ready ? t("browser_tab_title", { ns: nsMedicaidJobSurvey }) : "Carina | Medicaid Match Survey"}</title>
      </Helmet>
      <MedicaidNavbar />
      <main id="main-content">{props.children}</main>
      <Footer />
    </>
  );

  const initialValues: ThreadMatchingSurveyData = {
    hired: undefined,
    hours: undefined,
    careProviderId: showProvideCareDropdown() ? thread?.otherUser.id || undefined : undefined,
    furtherInformation: "",
    testimonialConsent: false,
    testimonialSource: undefined,
    testimonialName: "",
  };

  function showProvideCareDropdown() {
    const isUserProviderType = isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider);
    const isOtherUserProviderType =
      thread?.otherUser.roleType === RoleType.PROVIDER || thread?.otherUser.roleType === RoleType.PROXY_PROVIDER;
    return isUserProviderType && isOtherUserProviderType;
  }

  function getTestimonialSource(values: ThreadMatchingSurveyData) {
    if (values.hired === HiredEnum.YES && values.testimonialConsent) {
      if (isUserType(UserType.Consumer)) {
        return values.testimonialSource;
      }
      return TestimonialSourceEnum.SELF_REPORT;
    }
    return undefined;
  }

  function getTestimonialName(values: ThreadMatchingSurveyData) {
    if (values.hired === HiredEnum.YES && values.testimonialConsent) {
      if (
        isUserType(UserType.Consumer) &&
        (values.testimonialSource === TestimonialSourceEnum.FRIEND_FAMILY ||
          values.testimonialSource === TestimonialSourceEnum.OTHER_REPRESENTATIVE)
      ) {
        return values.testimonialName;
      }
      return userSession?.firstName;
    }
    return undefined;
  }

  function showSourceDropdown(values: ThreadMatchingSurveyData) {
    return values.hired === HiredEnum.YES && values.testimonialConsent && isUserType(UserType.Consumer);
  }

  function showFirstNameField(values: ThreadMatchingSurveyData) {
    return (
      values.hired === HiredEnum.YES &&
      values.testimonialConsent &&
      isUserType(UserType.Consumer) &&
      (values.testimonialSource === TestimonialSourceEnum.FRIEND_FAMILY ||
        values.testimonialSource === TestimonialSourceEnum.OTHER_REPRESENTATIVE)
    );
  }

  const handleSubmit = async (values: ThreadMatchingSurveyData) => {
    if (!thread) return;

    try {
      setSubmitting(true);
      await messagingClient.postMatchingSurveyForThread({
        threadId: thread.id,
        jobPostId: thread.jobPostId || "",
        hours: values.hired === HiredEnum.YES ? values.hours : 0,
        careProviderId: values.hired === HiredEnum.YES ? values.careProviderId : undefined,
        resolvedNotes: values.furtherInformation,
        hired: values.hired === HiredEnum.YES,
        testimonialConsent: values.hired === HiredEnum.YES ? values.testimonialConsent : false,
        testimonialSource: getTestimonialSource(values),
        testimonialName: getTestimonialName(values),
      });
      posthog?.capture("match_survey_submit");
      if (values.hired === HiredEnum.YES) {
        setUserWasHired(() => {
          if (
            isUserType(UserType.Consumer) ||
            isUserType(UserType.MedicaidCaseManager) ||
            isUserType(UserType.MedicaidReferralCoordinator)
          ) {
            return false; // These user types can never be hired
          }
          // Providers and Proxy IPs surveying each other will need to pick who was hired
          if (showProvideCareDropdown()) {
            return values.careProviderId === userSession?.id; // This user was hired if they picked themselves
          }
          if (isUserType(UserType.Provider)) {
            return true; // If there was no picker and this user is a provider, they were hired
          }
          if (thread.jobIsPostedByOtherUser !== null) {
            return !!thread.jobIsPostedByOtherUser; // If the job was posted by other user, then this user was hired
          }
          // TODO: I think I want to revisit this last part of the logic, I have a feeling I'm missing one more case
          return true; // At this point, it's likely this user was hired
        });
        setShowMatchedConfirmation(true);
      } else {
        setShowNotMatchedConfirmation(true);
      }
    } catch {
      await showSnackbar(
        t(
          "matching_survey.problem_submitting_survey",
          "There was a problem submitting your survey. Please try again later.",
          { ns: nsCommonToasts },
        ),
        "error",
      );
    } finally {
      setSubmitting(false);
    }
  };

  const FURTHER_INFO_MAX_LENGTH = 500;
  const validationSchema = object({
    hired: string()
      .label(
        t("have_you_and_user_agreed_to_work.label", {
          ns: nsMedicaidJobSurvey,
          name: thread?.otherUser.firstName,
        }),
      )
      .required(t("field.generic.error.select_one_of_two", { ns: nsCommonFormsBtns })),
    hours: string()
      .label(t("field.monthly_hours.label", { ns: nsCommonFormsBtns }))
      .when("hired", {
        is: (val) => val === HiredEnum.YES,
        then: string().required(t("field.monthly_hours.error", { ns: nsCommonFormsBtns })),
      }),
    careProviderId: string()
      .label(t("field.care_provider.label", { ns: nsCommonFormsBtns }))
      .when("hired", {
        is: (val) => val === HiredEnum.YES && showProvideCareDropdown(),
        then: string().required(t("field.who_providing_care.error", { ns: nsCommonFormsBtns })),
      }),
    furtherInformation: string()
      .label(t("field.comments.label", { ns: nsCommonFormsBtns }))
      .required(t("field.generic.error.required", "Required", { ns: nsCommonFormsBtns }))
      .max(FURTHER_INFO_MAX_LENGTH, ({ max, value }) =>
        t("field.generic.count_char_over_limit.label", {
          ns: nsCommonFormsBtns,
          count: value.length - max,
        }),
      ),
    testimonialSource: string()
      .label(t("field.i_am.label", { ns: nsCommonFormsBtns }))
      .when("testimonialConsent", {
        is: (val) => val === true && isUserType(UserType.Consumer),
        then: string().required(t("field.i_am.error", { ns: nsCommonFormsBtns })),
      }),
    testimonialName: string()
      .label(t("field.first_name.label", { ns: nsCommonFormsBtns }))
      .when("testimonialSource", {
        is: (val) =>
          isUserType(UserType.Consumer) &&
          (val === TestimonialSourceEnum.FRIEND_FAMILY || val === TestimonialSourceEnum.OTHER_REPRESENTATIVE),
        then: string().required(t("field.first_name.error.name_for_testimonial", { ns: nsCommonFormsBtns })),
      }),
  });

  if (showMatchedConfirmation) {
    return (
      <PageWrapper>
        <ThreadSurveyMatchedConfirmation userGotJob={userWasHired} threadId={threadId} />
      </PageWrapper>
    );
  }

  if (showNotMatchedConfirmation) {
    return (
      <PageWrapper>
        <ThreadSurveyNotMatchedConfirmation threadId={threadId} />
      </PageWrapper>
    );
  }

  if (loadingDetails) {
    return (
      <PageWrapper>
        <Section bgcolor={CivColors.lightGray} height="60vh" centerContent>
          <LoadingCircle />
        </Section>
      </PageWrapper>
    );
  }

  // TODO: Localize this error text
  if (!loadingDetails && !thread) {
    return (
      <PageWrapper>
        <Section bgcolor={CivColors.lightGray} height="60vh" centerContent>
          <Constraint columns={6} textAlign="center">
            <BodyTitle paragraph>Oops. Something went wrong.</BodyTitle>
            <Body paragraph>Unable to load details for survey. Please go back and try again.</Body>
          </Constraint>
        </Section>
      </PageWrapper>
    );
  }

  if (!ready) {
    return (
      <Section bgcolor={CivColors.lightGray} minimizeBottomGutter minHeight="100vh">
        <Constraint columns={6}>
          <LoadingCircle />
        </Constraint>
      </Section>
    );
  }

  return (
    <PageWrapper>
      <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
        <Constraint columns={6} textAlign="center">
          <SectionTitle>{t("working_together.label", { ns: nsMedicaidJobSurvey })}</SectionTitle>
          <Body paragraph>{t("working_together.description.your_feedback", { ns: nsMedicaidJobSurvey })}</Body>
          {isUserType(UserType.Consumer) && (
            <Body paragraph>
              <Trans
                t={t}
                i18nKey="working_together.description.will_not_affect_provider"
                ns={nsMedicaidJobSurvey}
                components={{ b: <b /> }}
              />
            </Body>
          )}

          {isUserType(UserType.Provider) && (
            <Body paragraph>
              <Trans
                t={t}
                i18nKey="working_together.description.will_not_take_down_job"
                ns={nsMedicaidJobSurvey}
                components={{ b: <b /> }}
              />
            </Body>
          )}
        </Constraint>
      </Section>

      <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
        <Constraint columns={8}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values }) => (
              <Form localizationReady={ready}>
                <Grid container spacing={4} direction="column">
                  <Grid item xs={12}>
                    <FormRadioGroupField<string>
                      name="hired"
                      label={`${t("have_you_and_user_agreed_to_work.label", {
                        ns: nsMedicaidJobSurvey,
                        name: thread?.otherUser.firstName,
                      })} *`}
                      labelAlwaysOnTop
                      options={[
                        {
                          key: HiredEnum.YES,
                          optionLabel: t("yes_we_will_work_together_option.label", { ns: nsMedicaidJobSurvey }),
                        },
                        {
                          key: HiredEnum.NO,
                          optionLabel: t("no_we_wont_work_together_option.label", { ns: nsMedicaidJobSurvey }),
                        },
                      ]}
                    />
                  </Grid>
                  {values.hired === HiredEnum.YES && thread?.otherUser && userSession && showProvideCareDropdown() && (
                    <Grid item xs={desktopSize ? 6 : 12}>
                      <BodyEmphasis style={{ marginBottom: 22 }}>
                        {t("who_providing_care.label", { ns: nsMedicaidJobSurvey })}
                      </BodyEmphasis>
                      <FormSelectField
                        name="careProviderId"
                        label={t("field.care_provider.label", { ns: nsCommonFormsBtns })}
                        selectOptions={[
                          {
                            label: "Me",
                            value: userSession.id,
                          },
                          {
                            label: thread.otherUser.firstName,
                            value: thread.otherUser.id,
                          },
                        ]}
                      />
                    </Grid>
                  )}
                  {values.hired === HiredEnum.YES && (
                    <Grid item xs={desktopSize ? 6 : 12}>
                      <BodyEmphasis style={{ marginBottom: 22 }}>
                        {isUserType(UserType.Consumer) &&
                          t("how_many_hours_are_you_filling.label", { ns: nsMedicaidJobSurvey })}
                        {(isUserType(UserType.Provider) || isUserType(UserType.ProxyProvider)) &&
                          t("how_many_hours_were_filled.label", { ns: nsMedicaidJobSurvey })}
                      </BodyEmphasis>
                      <FormTextField
                        type="number"
                        name="hours"
                        label={t("field.monthly_hours.label", { ns: nsCommonFormsBtns })}
                        inputProps={{ min: 0 }}
                        required
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <BodyEmphasis style={{ marginBottom: 18 }}>
                      {t("tell_us_what_happened.label", { ns: nsMedicaidJobSurvey })}
                    </BodyEmphasis>
                    <Body>{t("tell_us_what_happened.description", { ns: nsMedicaidJobSurvey })}</Body>
                    <FormTextField
                      name="furtherInformation"
                      label={t("field.comments.label", { ns: nsCommonFormsBtns })}
                      required={isRequiredField("furtherInformation", validationSchema)}
                      multiline
                      minRows={5}
                      maximumlength={FURTHER_INFO_MAX_LENGTH}
                      helperText={""}
                    />
                  </Grid>

                  {values.hired === HiredEnum.YES && (
                    <Grid item xs={12}>
                      <BodyEmphasis style={{ marginBottom: 22 }}>
                        <Trans
                          t={t}
                          i18nKey="do_you_consent_question.label"
                          ns={nsMedicaidJobSurvey}
                          components={{
                            i: <i />,
                            cwsc: <MuiLink href={MEDICAID_ACCOUNT_ROUTES.testimonialConsent} target="_blank" />,
                          }}
                        />
                      </BodyEmphasis>
                      <CheckboxField
                        name="testimonialConsent"
                        id="testimonialConsent"
                        label={<Body>{t("yes_i_consent.label", { ns: nsMedicaidJobSurvey })}</Body>}
                      />
                    </Grid>
                  )}

                  {showSourceDropdown(values) && (
                    <Grid item xs={12}>
                      <FormSelectField
                        name="testimonialSource"
                        label={`${t("field.i_am.label", { ns: nsCommonFormsBtns })} *`}
                        selectOptions={[
                          {
                            label: t("self_reporting_match.label", { ns: nsMedicaidJobSurvey }),
                            value: TestimonialSourceEnum.SELF_REPORT,
                          },
                          {
                            label: t("friend_family_member.label", { ns: nsMedicaidJobSurvey }),
                            value: TestimonialSourceEnum.FRIEND_FAMILY,
                          },
                          {
                            label: t("another_representative.label", { ns: nsMedicaidJobSurvey }),
                            value: TestimonialSourceEnum.OTHER_REPRESENTATIVE,
                          },
                        ]}
                      />
                    </Grid>
                  )}

                  {showFirstNameField(values) && (
                    <Grid item xs={12}>
                      <FormTextField
                        name="testimonialName"
                        label={t("field.first_name.label", { ns: nsCommonFormsBtns })}
                        required
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} style={{ paddingTop: "24px" }}>
                    <FormAlert schema={validationSchema} />
                  </Grid>

                  <Grid container style={{ padding: 24, margin: 0, justifyContent: "center" }} spacing={4}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{ paddingBottom: 12, textAlign: desktopSize ? "right" : "center" }}
                    >
                      <LinkStyledAsButton variant="outlined" component={Link} to={MEDICAID_ACCOUNT_ROUTES.inbox}>
                        {t("button.cancel", { ns: nsCommonFormsBtns })}
                      </LinkStyledAsButton>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: desktopSize ? "left" : "center" }}>
                      <Button variant="contained" type="submit" disabled={isSubmitting}>
                        {isSubmitting
                          ? t("button.submitting", { ns: nsCommonFormsBtns })
                          : t("button.submit", { ns: nsCommonFormsBtns })}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Constraint>
      </Section>
    </PageWrapper>
  );
};

export default ThreadMatchingSurvey;
