import { Grid, GridProps } from "@mui/material";
import { ReactNode } from "react";
import { BodyEmphasis, BodyTitle } from "src/reusable_view_elements/Typography";

export interface DashSectionProps extends GridProps {
  label: ReactNode;
  topBorder?: boolean;
  bottomBorder?: boolean;
  contentGridProps?: Partial<GridProps>;
  container?: boolean;
  bodyTitleLabel?: boolean;
  labelGridProps?: Partial<GridProps>;
}

/**
 * Section component made specifically for dashboard detail pages.
 * - label: String to render in the left-hand side of the section
 * - children: React nodes to render inside the content area (right-hand side)
 * - topBorder: Boolean to show top border
 * - bodyTitleLabel: Boolean to use BodyTitle instead of BodyEmphasis in left-hand side
 * - bottomBorder: Boolean to show bottom border
 * - style: Style to apply to the root Grid container
 * - contentGridProps: Props to give to the surrounding content Grid item
 * - container: Boolean to turn the content Grid item into a Grid container
 * - labelGridProps: Props to give to the grid surrounding the label
 * - restRootProps: Any extra props left over for the surrounding (root) Grid container
 */
const DashSection = ({
  label,
  children,
  topBorder,
  bottomBorder,
  style,
  contentGridProps,
  container,
  bodyTitleLabel,
  labelGridProps,
  ...restRootProps
}: DashSectionProps) => {
  const rootStyle = {
    borderTop: topBorder ? "1px solid #989898" : "none",
    borderBottom: bottomBorder ? "1px solid #989898" : "none",
    paddingTop: 32,
    paddingBottom: 32,
    ...style,
  };

  return (
    <Grid container spacing={2} style={rootStyle} {...restRootProps}>
      <Grid item xs={12} md={3} {...labelGridProps}>
        {bodyTitleLabel ? <BodyTitle paragraph>{label}</BodyTitle> : <BodyEmphasis>{label}</BodyEmphasis>}
      </Grid>
      {container ? (
        <Grid item xs={12} md={9} container spacing={2} {...contentGridProps}>
          {children}
        </Grid>
      ) : (
        <Grid item xs={12} md={9} {...contentGridProps}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

DashSection.defaultProps = {
  topBorder: false,
  bottomBorder: false,
  contentGridProps: {},
  container: false,
  bodyTitleLabel: false,
  labelGridProps: {},
};

export default DashSection;
