import { Box, Divider, Hidden, ListItem, ListItemText, MenuList } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as NavRouterLink } from "react-router-dom";
import { EnvName, useConfig } from "src/context/ConfigContext";
import { FunderState, UserType, useUserSession } from "src/context/UserSessionContext";
import { Permission } from "src/generated/api_types";
import { nsCommonNavigation } from "src/i18n/Namespaces";
import { ROUTES } from "src/MainRouter";
import { useInboxContext } from "src/pages/inbox/InboxContext";
import { Medicaid_Coordinator_Dashboard_Routes } from "src/pages/medicaidAccount/coordinatorAccount/CoordinatorRouter";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { Medicaid_Provider_Dashboard_Routes } from "src/pages/medicaidAccount/providerAccount/ProviderRouter";
import { Medicaid_Proxy_Provider_Routes } from "src/pages/medicaidAccount/ProxyProvider/ProxyProviderRouter";
import { getRoutePrefix } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Badge from "src/reusable_view_elements/Badge";
import Header from "src/reusable_view_elements/Header";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import { MenuButton } from "src/reusable_view_elements/MenuButton";
import MenuItem from "src/reusable_view_elements/MenuItem";
import { NavLink, StyledText } from "src/reusable_view_elements/navbars/NavBar";
import FullSizeMenu from "src/reusable_view_elements/navbars/navbar_elements/FullSizeMenu";
import LanguageButton from "src/reusable_view_elements/navbars/navbar_elements/LanguageButton";
import NavBarGrid from "src/reusable_view_elements/navbars/navbar_elements/NavBarGrid";
import CivColors from "src/themes/civilization/CivColors";

type NavBarLink = {
  label: string;
  route: string;
  badgeNumber?: number;
};

const MedicaidNavbar = () => {
  const [mainMenuIsOpen, setMainMenuVisibility] = useState(false);
  const { isUserType, isFunderState, hasPermission, isImpersonated, impersonatorIsUserType, isAssistiveRoleView } =
    useUserSession();
  const { totalUnreadCount } = useInboxContext();
  const { isInEnv } = useConfig();
  const { t, ready } = useTranslation(nsCommonNavigation);
  const routeVisibileForDevelopmentOnly = isInEnv(EnvName.DEV) || isInEnv(EnvName.LOCAL);

  const navbarLinks: Array<NavBarLink> = [
    {
      label: t("link.home", { ns: nsCommonNavigation }),
      route: MEDICAID_ACCOUNT_ROUTES.homepage,
    },
    {
      label: t("link.find_providers", { ns: nsCommonNavigation }),
      route: MEDICAID_ACCOUNT_ROUTES.browseProviders,
    },
  ];

  if (!(isUserType(UserType.Consumer) && isFunderState(FunderState.Oregon))) {
    navbarLinks.push({
      label: t("link.find_jobs", { ns: nsCommonNavigation }),
      route: MEDICAID_ACCOUNT_ROUTES.browseJobs,
    });
  }

  if (
    isUserType(UserType.Consumer) ||
    isUserType(UserType.MedicaidReferralCoordinator) ||
    isUserType(UserType.MedicaidCaseManager) ||
    isUserType(UserType.ProxyProvider)
  ) {
    navbarLinks.push({
      label: t("link.my_jobs", { ns: nsCommonNavigation }),
      route: getRoutePrefix(isUserType).myJobList,
    });
  }

  // TODO: remove routeVisible flag once fully developed and tested
  if (isAssistiveRoleView() && routeVisibileForDevelopmentOnly) {
    navbarLinks.push({
      label: "Test Jobs Dashboard",
      route: MEDICAID_ACCOUNT_ROUTES.jobDashboard,
    });
  }

  if (isUserType(UserType.Provider)) {
    navbarLinks.push({
      label: t("link.my_profile", { ns: nsCommonNavigation }),
      route: Medicaid_Provider_Dashboard_Routes.viewMyProfile,
    });
  }

  if (isUserType(UserType.ProxyProvider)) {
    navbarLinks.push({
      label: t("link.my_profile", { ns: nsCommonNavigation }),
      route: Medicaid_Proxy_Provider_Routes.viewMyProfile,
    });
  }

  if (hasPermission(Permission.MESSAGING_ACCESS) && !impersonatorIsUserType(UserType.UserSupportAgent)) {
    navbarLinks.push({
      label: t("link.messages", { ns: nsCommonNavigation }),
      route: MEDICAID_ACCOUNT_ROUTES.inbox,
      badgeNumber: totalUnreadCount > 0 ? totalUnreadCount : undefined,
    });
  }

  if (hasPermission(Permission.SETTINGS_ACCESS)) {
    navbarLinks.push({
      label: t("link.settings", { ns: nsCommonNavigation }),
      route: MEDICAID_ACCOUNT_ROUTES.accountSettings,
    });
  }

  return (
    <Header>
      <NavBarGrid
        center={
          ready ? (
            <Hidden lgDown>
              {/* DESKTOP SIZE */}
              <Box component="nav" aria-label="Medicaid" display="flex" sx={{ height: "24px" }}>
                {navbarLinks.map((link, i) => (
                  <Box
                    key={link.label}
                    sx={{
                      borderLeft: i != 0 ? `1px solid ${CivColors.coreDarkNavy}` : "none",
                      "& .MuiTypography-root": { display: "flex" },
                    }}
                  >
                    <NavLink to={link.route}>
                      {link.label}
                      {link.badgeNumber && <Badge number={link.badgeNumber} />}
                    </NavLink>
                  </Box>
                ))}
              </Box>
            </Hidden>
          ) : (
            <Hidden lgDown>
              {/* DESKTOP SIZE */}
              <nav aria-label="Medicaid">
                {navbarLinks.map((link) => (
                  <StyledText key={link.label}>
                    <Skeleton variant="text" style={{ display: "inline-block" }} width={80} />
                  </StyledText>
                ))}
              </nav>
            </Hidden>
          )
        }
        right={
          <>
            {/* DESKTOP SIZE */}
            <Hidden lgDown>
              <LanguageButton menuType="desktop" />
              {!isUserType(UserType.UserSupportManager) && ready && (
                <LinkStyledAsButton variant="outlined" href={ROUTES.logout} disabled={isImpersonated()}>
                  {t("link.logout", { ns: nsCommonNavigation })}
                </LinkStyledAsButton>
              )}
            </Hidden>

            {/* MOBILE SIZE */}
            <Hidden lgUp>
              <MenuButton
                onClick={() => {
                  setMainMenuVisibility(true);
                }}
              >
                {ready && t("link.menu", { ns: nsCommonNavigation })}
              </MenuButton>

              <FullSizeMenu open={mainMenuIsOpen} closeMenu={() => setMainMenuVisibility(false)}>
                <MenuList aria-label="Medicaid" sx={{ margin: "24px 0px", padding: 0 }}>
                  {navbarLinks.map((item) => (
                    <MenuItem
                      key={item.label}
                      component={NavRouterLink}
                      // @ts-ignore
                      to={item.route}
                      selected={
                        (window.location.pathname.includes("inbox") && item.route.includes("inbox")) ||
                        window.location.pathname === item.route
                      }
                      onClick={() => setMainMenuVisibility(false)}
                    >
                      <ListItemText
                        primary={
                          <>
                            {ready ? item.label : <Skeleton variant="text" />}
                            {item.badgeNumber && <Badge number={item.badgeNumber} />}
                          </>
                        }
                      />
                    </MenuItem>
                  ))}
                </MenuList>
                <Divider />
                {!isUserType(UserType.UserSupportManager) && ready && (
                  <ListItem sx={{ padding: 0, marginTop: "24px" }}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <LinkStyledAsButton variant="outlined" href={ROUTES.logout} disabled={isImpersonated()}>
                        {t("link.logout", { ns: nsCommonNavigation })}
                      </LinkStyledAsButton>
                    </div>
                  </ListItem>
                )}
                <ListItem sx={{ marginTop: "16px", padding: 0, justifyContent: "center" }}>
                  <LanguageButton menuType="mobile" sx={{ paddingLeft: "16px", paddingRight: "8px" }} />
                </ListItem>
              </FullSizeMenu>
            </Hidden>
          </>
        }
      />
    </Header>
  );
};

export default MedicaidNavbar;
