import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobPostListDTO, JobPostTagCategoryEnum, Permission } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidAccountPortal, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { MEDICAID_ACCOUNT_ROUTES } from "src/pages/medicaidAccount/MedicaidAccountRouter";
import { useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import FirstMessageDialog, { FirstMessageContext } from "src/pages/medicaidAccount/sharedComponents/FirstMessageDialog";
import JobCardTranslated from "src/reusable_view_elements/JobCardTranslated";
import { bookmarkJob, getBookmarkedJobs, unBookmarkJob } from "src/pages/medicaidAccount/sharedComponents/Queries";
import { useSupportAdminContext } from "src/pages/supportAdminAccount/SupportAdminContext";
import Constraint from "src/reusable_view_elements/Constraint";
import DashReturnLink from "src/reusable_view_elements/DashReturnLink";
import Footer from "src/reusable_view_elements/Footer";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MessageFollowUpButton from "src/reusable_view_elements/MessageFollowUpButton";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import Section from "src/reusable_view_elements/Section";
import { Body, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";

const SavedJobs = () => {
  const { showSnackbar } = useNotification();
  const { sendMsgDialogProps, setSendMsgDialogProps, setVisitedJobIndex } = useMedicaidContext();
  const { program } = useSupportAdminContext();
  const { userSession, isUserType, hasPermission } = useUserSession();
  const [bookMarkedJobsList, setBookMarkedJobsList] = useState<JobPostListDTO[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const { t, ready } = useTranslation([nsMedicaidAccountPortal, nsCommonFormsBtns, nsCommonToasts]);
  const [selectedJobId, setSelectedJobId] = useState<string>();

  useEffect(() => {
    if (sendMsgDialogProps) {
      setMsgModalOpen(true);
    }
  }, [sendMsgDialogProps]);

  /************************************************* *********************************************************
   The next two functions update the bookMarkedJobsList object without having to
   make a call to the BE. This prevents rerendering the list, which would break the focus sequence of
   any open 'first message success snackbar' or move the user back to the top when using infinite scrolling
   ************************************************* *********************************************************/
  const updateJobListAfterFirstMessage = (threadId?: string) => {
    if (!threadId) return;
    const updatedJobList = bookMarkedJobsList.map((job) => {
      return job.entity.id === selectedJobId ? { ...job, entity: { ...job.entity, threadIds: [threadId] } } : job;
    });
    setBookMarkedJobsList(updatedJobList);
  };
  /************************************************* *********************************************************/

  useEffect(() => {
    (async () => {
      if ((isUserType(UserType.UserSupportManager) && program !== "none") || !isUserType(UserType.UserSupportManager)) {
        const programId = isUserType(UserType.UserSupportManager) ? program : "";
        try {
          setLoading(true);
          const resp = await getBookmarkedJobs(programId);
          setBookMarkedJobsList(resp.data);
        } catch (e) {
          showSnackbar(
            t("saved_jobs.error.issue_retrieving_bookmarked", {
              ns: nsCommonToasts,
            }),
            "error",
          );
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [program]);

  const handleBookmarkClick = async (jobId: string, isBookmarked?: boolean) => {
    if (isBookmarked === undefined) {
      return;
    }
    try {
      if (isBookmarked) {
        await unBookmarkJob(jobId);
      } else {
        await bookmarkJob(jobId);
      }
      const updatedJobList = bookMarkedJobsList?.map((job) => {
        return job.entity.id === jobId ? { ...job, entity: { ...job.entity, bookmarked: !isBookmarked } } : job;
      });
      setBookMarkedJobsList(updatedJobList);
    } catch (e) {
      showSnackbar(t("error.processing_bookmark", { ns: nsCommonToasts }), "error");
    }
  };

  return (
    <>
      <Helmet>
        <title>Carina | Saved Job Posts</title>
      </Helmet>

      <MedicaidNavbar />

      <main id="main-content">
        {!ready ? (
          <Section bgcolor={CivColors.lightGray} minimal>
            <Constraint columns={12}>
              <LoadingCircle />
            </Constraint>
          </Section>
        ) : (
          <>
            <Section bgcolor={CivColors.lightGray} minimal>
              <Constraint columns={12}>
                <DashReturnLink to={MEDICAID_ACCOUNT_ROUTES.homepage}>
                  {t("button.back", { ns: nsCommonFormsBtns })}
                </DashReturnLink>
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray}>
              <Constraint columns={8} textAlign="center">
                <SectionTitle>{t("saved_job_posts.label", { ns: nsMedicaidAccountPortal })}</SectionTitle>
                {!isLoading && (
                  <Body>
                    {t("saved_job_posts.description.job_count", {
                      ns: nsMedicaidAccountPortal,
                      count: bookMarkedJobsList.length,
                    })}
                  </Body>
                )}
              </Constraint>
            </Section>

            <Section bgcolor={CivColors.lightGray} pt={0}>
              <Constraint columns={8}>
                {isLoading && <LoadingCircle />}
                {!isLoading && bookMarkedJobsList.length > 0 && (
                  <Grid item xs="auto">
                    <Grid container spacing={2}>
                      {bookMarkedJobsList.map((jobPost, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ display: "flex" }}
                            key={jobPost.entity.id}
                            id={index.toString()}
                            onClick={() => setVisitedJobIndex(index)}
                          >
                            <JobCardTranslated
                              jobPost={jobPost}
                              first_name={jobPost.entity.firstName || jobPost.entity.postingUserInfo.firstName}
                              schedule={jobPost.entity.scheduleNotes}
                              buttonLink={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: jobPost.entity.id })}
                              daysUp={jobPost.entity.daysOpen}
                              hideBookmark={userSession?.id === jobPost.entity.postingUserInfo.id}
                              bookmarked={jobPost.entity.bookmarked}
                              onBookmark={() => handleBookmarkClick(jobPost.entity.id, jobPost.entity.bookmarked)}
                              buttonSection={
                                <Grid container spacing={2} direction="column" alignItems="center">
                                  <Grid item>
                                    <LinkStyledAsButton
                                      variant="contained"
                                      component={Link}
                                      to={generatePath(MEDICAID_ACCOUNT_ROUTES.jobDetails, { id: jobPost.entity.id })}
                                    >
                                      {t("button.view_job", { ns: nsCommonFormsBtns })}
                                    </LinkStyledAsButton>
                                  </Grid>

                                  {hasPermission(Permission.MESSAGING_ACCESS) &&
                                    userSession &&
                                    jobPost.entity.postingUserInfo.id !== userSession.id &&
                                    jobPost.entity.threadIds && (
                                      <Grid item>
                                        <MessageFollowUpButton
                                          variant="outlined"
                                          onMessageClick={() => {
                                            setSelectedJobId(jobPost.entity.id);
                                            setSendMsgDialogProps({
                                              recipientId: jobPost.entity.postingUserInfo.id,
                                              recipientFirstName: jobPost.entity.postingUserInfo.firstName,
                                              recipientRole: jobPost.entity.postingUserInfo.role as UserType,
                                              contextJobPostId: jobPost.entity.id,
                                              context: FirstMessageContext.JOB_POST,
                                              pronouns:
                                                jobPost.entity.postingUserInfo.role === UserType.Consumer
                                                  ? jobPost.entity.tags
                                                      .filter(
                                                        (tagObj) => tagObj.category === JobPostTagCategoryEnum.PRONOUN,
                                                      )
                                                      .sort((a, b) => a.order - b.order)
                                                      .map((tagObj) =>
                                                        t(tagObj.tag.toLowerCase(), { ns: nsMedicaidTagsEnums }),
                                                      )
                                                  : undefined,
                                            });
                                          }}
                                          threadIds={jobPost.entity.threadIds}
                                          otherUserId={jobPost.entity.postingUserInfo.id}
                                        />
                                      </Grid>
                                    )}
                                </Grid>
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
                {!isLoading && bookMarkedJobsList.length === 0 && (
                  <Box bgcolor={CivColors.lightGray} pb={24}>
                    <Constraint columns={8} textAlign="center">
                      <LinkStyledAsButton variant="contained" component={Link} to={MEDICAID_ACCOUNT_ROUTES.browseJobs}>
                        {t("button.search_for_jobs", { ns: nsCommonFormsBtns })}
                      </LinkStyledAsButton>
                    </Constraint>
                  </Box>
                )}
              </Constraint>
            </Section>
          </>
        )}

        {sendMsgDialogProps && (
          <FirstMessageDialog
            {...sendMsgDialogProps}
            isDialogOpen={isMsgModalOpen}
            onCloseDialog={(threadId) => {
              updateJobListAfterFirstMessage(threadId);
              setMsgModalOpen(false);
              setSendMsgDialogProps(undefined);
            }}
          />
        )}
      </main>

      <Footer />
    </>
  );
};

export default SavedJobs;
