import { StrictMode } from "react";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom";
import App from "src/App";
import "src/index.css";

const options = {
  api_host: "https://us.i.posthog.com",
  capture_pageview: false, // because we are doing it manually in the App -> MainRouter
};

ReactDOM.render(
  <StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_API_KEY} options={options}>
      <App />
    </PostHogProvider>
  </StrictMode>,
  document.getElementById("root"),
);
