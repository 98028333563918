import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { JobPostTagEnum } from "src/generated/api_types";
import { nsCommonAria, nsMedicaidTagsEnums } from "src/i18n/Namespaces";
import { getLocalizedJobPostTag } from "src/i18n/TagHelpers";
import { CheckCircle1Icon, RemoveCircleAlternateIcon } from "src/assets/icons/StreamlineIcons";
import { TagsObjectType } from "src/pages/medicaidAccount/consumerAccount/components/CreateEditRepostJobForm";
import IconListItem from "src/reusable_view_elements/IconListItem";
import LgbtPopover from "src/reusable_view_elements/popovers/LgbtPopover";
import NurseDelegationPopover from "src/reusable_view_elements/popovers/NurseDelegationPopover";
import { Body } from "src/reusable_view_elements/Typography";

interface XOrCheckMarkListItemProps {
  tag: JobPostTagEnum;
  tagsObject: TagsObjectType;
}

const XOrCheckMarkListItem = ({ tag, tagsObject }: XOrCheckMarkListItemProps) => {
  const { t } = useTranslation([nsMedicaidTagsEnums, nsCommonAria]);

  function getTranslatedJobPostTag(): string {
    return t(getLocalizedJobPostTag(tag).key, { ns: nsMedicaidTagsEnums });
  }

  const Wrapper = ({ children }: PropsWithChildren<{}>) => {
    if (tag === JobPostTagEnum.LGBTQIA_HOUSEHOLD) return <LgbtPopover>{children}</LgbtPopover>;
    if (tag === JobPostTagEnum.NURSE_DELEGATION) return <NurseDelegationPopover>{children}</NurseDelegationPopover>;
    return <Body>{children}</Body>;
  };

  return (
    <IconListItem
      icon={() =>
        tagsObject.tagArray.includes(tag) ? (
          <CheckCircle1Icon titleAccess={t("icon.checked.label", { ns: nsCommonAria })} />
        ) : (
          <RemoveCircleAlternateIcon titleAccess={t("icon.unchecked.label", { ns: nsCommonAria })} />
        )
      }
      secondary={<Wrapper>{getTranslatedJobPostTag()}</Wrapper>}
    />
  );
};

export default XOrCheckMarkListItem;
