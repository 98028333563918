import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { MultipleActionsCheck1HalfBodyIcon, QuestionCircleIcon } from "src/assets/icons/StreamlineIcons";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { RoleType } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonTooltips } from "src/i18n/Namespaces";
import { getMedicaidRouteByUserType } from "src/pages/medicaidAccount/SharedHelperFunctions";
import { InternalLink } from "src/reusable_view_elements/Link";
import Popover from "src/reusable_view_elements/Popover";
import { Body } from "src/reusable_view_elements/Typography";

interface MatchSurveyLinkProps {
  threadId: string;
}

const MatchSurveyLink = ({ threadId }: MatchSurveyLinkProps) => {
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsCommonFormsBtns, nsCommonTooltips]);
  const posthog = usePostHog();

  const matchSurveyLink = generatePath(getMedicaidRouteByUserType("matchSurvey", isUserType), { id: threadId });

  useEffect(() => {
    posthog?.capture("match_survey_working_together_view");
  }, []);

  const style = {
    icon: { display: "inline-block", verticalAlign: "middle" },
    link: { display: "inline-block", marginLeft: "8px", marginRight: "12px" },
    tooltip: { display: "inline-block" },
  };

  return ready ? (
    <>
      <MultipleActionsCheck1HalfBodyIcon style={style.icon} />
      <InternalLink
        to={matchSurveyLink}
        onClick={() => {
          posthog?.capture("match_survey_working_together_click");
        }}
        style={style.link}
      >
        <Body>{t("button.working_together", { ns: nsCommonFormsBtns })}</Body>
      </InternalLink>
      <Popover
        contentId="working-together-popover"
        contentTitle={t("popover.working_together.title", { ns: nsCommonTooltips })}
        content={<Body>{t("popover.working_together.content", { ns: nsCommonTooltips })}</Body>}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <QuestionCircleIcon style={style.tooltip} />
      </Popover>
    </>
  ) : (
    <></>
  );
};

export default MatchSurveyLink;

/**
 * Determines who can see the match survey link.
 * This will depend on 1) the current user's role and 2) the other user's role in the thread.
 */
export function showMatchSurveyLink(otherUserRole: RoleType, isUserType: (type: UserType) => boolean): boolean {
  if (isUserType(UserType.Consumer)) {
    return [RoleType.PROVIDER, RoleType.PROXY_PROVIDER].includes(otherUserRole);
  }

  if (isUserType(UserType.Provider)) {
    return [
      RoleType.CONSUMER,
      RoleType.PROXY_PROVIDER,
      RoleType.MEDICAID_REFERRAL_COORDINATOR,
      RoleType.MEDICAID_CASE_MANAGER,
    ].includes(otherUserRole);
  }

  if (isUserType(UserType.ProxyProvider)) {
    return [
      RoleType.CONSUMER,
      RoleType.PROVIDER,
      RoleType.PROXY_PROVIDER,
      RoleType.MEDICAID_REFERRAL_COORDINATOR,
      RoleType.MEDICAID_CASE_MANAGER,
    ].includes(otherUserRole);
  }

  /**
   * Note: Referral Coordinators are left out because they have the new Assistive Role Inbox which does not yet
   * have a place for the match survey link. Meanwhile, Case Managers still have the original inbox UI.
   */
  if (isUserType(UserType.MedicaidCaseManager)) {
    return [RoleType.PROVIDER, RoleType.PROXY_PROVIDER].includes(otherUserRole);
  }

  return false;
}
