import { PropsWithChildren, useState } from "react";
import { createContainer } from "unstated-next";

export enum InboxTabState {
  all_threads,
  unread_threads,
  starred_threads,
  archived_threads,
  client_threads,
}

function inboxContext() {
  const [tab, setTab] = useState(InboxTabState.all_threads);
  const [profileDetailExpanded, setProfileDetailExpanded] = useState<boolean>(true);

  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  // Search field state shared across tabs; resets in Inbox when before tab state changes
  const [pageNumber, setPageNumber] = useState<number>(1); // 1-based
  const [searchedTerm, setSearchedTerm] = useState<string>(""); // The term sent in latest request
  const [searchQuery, setSearchQuery] = useState<string>(""); // Up-to-date value for search field
  const [showSearchResetButton, setShowSearchResetButton] = useState<boolean>(false);
  const [selectedThreadId, setSelectedThreadId] = useState<string>();
  // Next state controls display of right inbox panel for assistive role view. It's also used to fetch the profile info
  const [inboxSidePanelUserId, setInboxSidePanelUserId] = useState<string>();
  // Next state is used to indicate to the three panel view to fetch threads associated with a specific user
  const [contextOtherUserId, setContextOtherUserId] = useState<string>();
  // The next three states are used control showing the 'No Conversations' message in the assistive role view
  const [loadingThreads, setLoadingThreads] = useState<boolean | undefined>(undefined);
  const [totalThreads, setTotalThreads] = useState(0);
  const [wereThreadsSearched, setWereThreadsSearched] = useState<boolean>(false);
  const [wereThreadsFiltered, setWereThreadsFiltered] = useState<boolean>(false);

  // To be used by assistive role's side-by-side profile and job post view
  const [jobPostIdForComparison, setJobPostIdForComparison] = useState<string>();

  // Next state is the cue/flag to indicate that an 'archive' OR 'unarchive' action has been triggered
  const [archiveActionRequested, setArchiveActionRequested] = useState<boolean>(false);

  // Next state is the cue/flag to indicate that an 'unfavorite' action has been triggered
  const [unfavoriteActionRequested, setUnfavoriteActionRequested] = useState<boolean>(false);

  // Next state is the cue/flag to indicate that a job number has been selected in the 'By Client' table
  const [byClientJobNumber, setByClientJobNumber] = useState<string>();

  // Next state is used to set predetermined search query when the user navigates to the inbox 'All Threads' tab
  const [initialSearchQuery, setInitialSearchQuery] = useState<string>();

  // Helper function to help reset search field state and paging state.
  // Useful for the user experience, such as when the tab and location changes or the reset button is clicked
  function resetSearchAndPagination(): void {
    setSearchedTerm("");
    setSearchQuery("");
    setShowSearchResetButton(false);
    setPageNumber(1);
  }

  return {
    tab,
    setTab,
    profileDetailExpanded,
    setProfileDetailExpanded,
    totalUnreadCount,
    setTotalUnreadCount,
    pageNumber,
    setPageNumber,
    searchedTerm,
    setSearchedTerm,
    searchQuery,
    setSearchQuery,
    showSearchResetButton,
    setShowSearchResetButton,
    resetSearchAndPagination,
    selectedThreadId,
    setSelectedThreadId,
    inboxSidePanelUserId,
    setInboxSidePanelUserId,
    jobPostIdForComparison,
    setJobPostIdForComparison,
    contextOtherUserId,
    setContextOtherUserId,
    loadingThreads,
    setLoadingThreads,
    totalThreads,
    setTotalThreads,
    wereThreadsSearched,
    setWereThreadsSearched,
    wereThreadsFiltered,
    setWereThreadsFiltered,
    archiveActionRequested,
    setArchiveActionRequested,
    unfavoriteActionRequested,
    setUnfavoriteActionRequested,
    byClientJobNumber,
    setByClientJobNumber,
    initialSearchQuery,
    setInitialSearchQuery,
  };
}

const InboxContext = createContainer(inboxContext);

export const InboxContextProvider = ({ children }: PropsWithChildren<{}>) => (
  <InboxContext.Provider>{children}</InboxContext.Provider>
);

export const useInboxContext = () => {
  return InboxContext.useContainer();
};
