import { Box, Grid, PaginationItem as MUIPaginationItem, PaginationRenderItemParams } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { useNotification } from "src/context/NotificationContext";
import { UserType, useUserSession } from "src/context/UserSessionContext";
import { JobPostListDTO } from "src/generated/api_types";
import { nsCommonFormsBtns, nsCommonToasts, nsMedicaidJobPost } from "src/i18n/Namespaces";
import { getPageMyJobPosts } from "src/pages/medicaidAccount/consumerAccount/Queries";
import { JobListOrigin, useMedicaidContext } from "src/pages/medicaidAccount/MedicaidContext";
import { BannerUseSiteEnum, SettingsBanner } from "src/pages/medicaidAccount/sharedComponents/SettingsBanner";
import { getMedicaidRouteByUserType, getRoutePrefix } from "src/pages/medicaidAccount/SharedHelperFunctions";
import Constraint from "src/reusable_view_elements/Constraint";
import Footer from "src/reusable_view_elements/Footer";
import JobCardTranslated from "src/reusable_view_elements/JobCardTranslated";
import { LinkStyledAsButton } from "src/reusable_view_elements/Link";
import LoadingCircle from "src/reusable_view_elements/LoadingCircle";
import MedicaidNavbar from "src/reusable_view_elements/navbars/MedicaidNavbar";
import Pagination from "src/reusable_view_elements/Pagination";
import Section from "src/reusable_view_elements/Section";
import { Body, BodyEmphasis, SectionTitle } from "src/reusable_view_elements/Typography";
import CivColors from "src/themes/civilization/CivColors";
import { scrollToId } from "src/utilities/ScrollToId";

const MyJobList = () => {
  const { showSnackbar, closeSnackbar } = useNotification();
  const {
    setJobPostListOrigin,
    setJobIdList,
    displayedPageNumber,
    setDisplayedPageNumber,
    totalPages,
    setTotalPages,
    settingsBannerView,
    setSettingsBannerView,
    showSettingsBanner,
  } = useMedicaidContext();
  const [jobList, setJobList] = useState<JobPostListDTO[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { isUserType } = useUserSession();
  const { t, ready } = useTranslation([nsMedicaidJobPost, nsCommonFormsBtns, nsCommonToasts]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        //TODO: [issue#1113] This page could be updated to use pagination or infinite loading, instead of loading the
        // whole list of jobs for the user, as a way to reduce loading time and for a smoother user experience
        const res = await getPageMyJobPosts(displayedPageNumber - 1);
        setJobList(res.data.content);
        setTotalPages(res.data.totalPages);
        setJobIdList(res.data.content.map((job) => job.entity.id));
        setJobPostListOrigin(JobListOrigin.MY_JOBS);
        if (showSettingsBanner) scrollToId("settings-banner-wrapper");
      } catch (e) {
        showSnackbar(
          t(
            "error.retrieving_your_jobs_list",
            "There was an issue retrieving your job posts. Please try again later.",
            { ns: nsCommonToasts },
          ),
          "error",
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [displayedPageNumber]);

  return (
    <>
      <Helmet>
        <title>Carina | My Job Posts</title>
      </Helmet>

      <div id="settings-banner-wrapper">
        <SettingsBanner
          view={settingsBannerView}
          setView={setSettingsBannerView}
          useSite={BannerUseSiteEnum.JOB_POST_CONFIRMATION}
          bannerEnabled={showSettingsBanner}
        />
      </div>

      <MedicaidNavbar />

      <main id="main-content">
        {ready && (
          <Section bgcolor={CivColors.lightGray} minimizeBottomGutter>
            <Constraint columns={8} textAlign="center">
              <SectionTitle gutterBottom>{t("my_job_posts.label", { ns: nsMedicaidJobPost })}</SectionTitle>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <LinkStyledAsButton component={Link} to={getMedicaidRouteByUserType("myDeletedJobs", isUserType)}>
                    {t("button.view_deleted_jobs", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
                <Grid item>
                  <LinkStyledAsButton
                    variant="contained"
                    component={Link}
                    to={getMedicaidRouteByUserType("jobCreate", isUserType)}
                  >
                    {t("button.post_a_job", { ns: nsCommonFormsBtns })}
                  </LinkStyledAsButton>
                </Grid>
              </Grid>
            </Constraint>
          </Section>
        )}

        {(isLoading || !ready) && (
          <Section bgcolor={CivColors.lightGray} minimizeTopGutter>
            <Constraint columns={8}>
              <LoadingCircle />
            </Constraint>
          </Section>
        )}

        {!isLoading && ready && jobList.length === 0 && (
          <Box bgcolor={CivColors.lightGray} textAlign="center" pb="20vh">
            <Constraint columns={8}>
              <Box p={2} />
              <Body>{t("you_have_no_active_jobs.description", { ns: nsMedicaidJobPost })}</Body>
            </Constraint>
          </Box>
        )}

        {!isLoading && ready && jobList.length > 0 && (
          <Section bgcolor={CivColors.lightGray}>
            <Constraint columns={8}>
              {totalPages > 1 && (
                <Box display="flex" justifyContent="center" paddingY={6}>
                  <Pagination
                    page={displayedPageNumber}
                    count={totalPages}
                    renderItem={(item: PaginationRenderItemParams) => {
                      return <MUIPaginationItem {...item} onClick={() => setDisplayedPageNumber(item.page || 1)} />;
                    }}
                  />
                </Box>
              )}
              <Grid container spacing={2}>
                {jobList.map((jobPost) => {
                  return (
                    <Grid item xs={12} md={6} wrap="nowrap" direction="column" key={jobPost.entity.id} container>
                      {(isUserType(UserType.MedicaidReferralCoordinator) ||
                        isUserType(UserType.MedicaidCaseManager)) && (
                        <Grid item style={{ margin: "12px 14px", wordWrap: "break-word" }}>
                          <BodyEmphasis>Notes: </BodyEmphasis>
                          <Body>{jobPost.entity.userNotes || "-"}</Body>
                        </Grid>
                      )}
                      <Grid item style={{ display: "flex", marginBottom: "24px", flexGrow: 1 }}>
                        <JobCardTranslated
                          jobPost={jobPost}
                          first_name={jobPost.entity.firstName || jobPost.entity.postingUserInfo.firstName}
                          schedule={jobPost.entity.scheduleNotes}
                          buttonLink={generatePath(getRoutePrefix(isUserType).myJobDetails, { id: jobPost.entity.id })}
                          daysUp={jobPost.entity.daysOpen}
                          hideBookmark
                          buttonSection={
                            <Grid container spacing={2} justifyContent="space-evenly" alignItems="center">
                              <Grid item>
                                <LinkStyledAsButton
                                  variant="contained"
                                  component={Link}
                                  to={generatePath(getRoutePrefix(isUserType).jobEdit, { id: jobPost.entity.id })}
                                  onClick={closeSnackbar}
                                >
                                  {t("button.edit", { ns: nsCommonFormsBtns })}
                                </LinkStyledAsButton>
                              </Grid>
                              <Grid item>
                                <LinkStyledAsButton
                                  variant="outlined"
                                  component={Link}
                                  to={generatePath(getRoutePrefix(isUserType).jobDelete, { id: jobPost.entity.id })}
                                >
                                  {t("button.delete", { ns: nsCommonFormsBtns })}
                                </LinkStyledAsButton>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Constraint>
          </Section>
        )}
      </main>

      <Footer />
    </>
  );
};
export default MyJobList;
